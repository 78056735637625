import * as Phaser from 'phaser';

import { Sprite } from '../objects/sprite';
import { Animation } from '../objects/animation';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScreen'
}

export class GameScreen extends Phaser.Scene {

    private score: number = 0;
    private lives: number = 5;
    private isActive: boolean = false;
    private paddleMidpoint: number = 0;
    private nextBall: number = 0;
    private readonly rightWall: number = 780;
    private readonly topWall: number = 2;
    private readonly bottomWall: number = 498;
    private readonly ballsAmount: number = 32;
    private isGameOver: boolean = false;

    // Custom ball sprite
    private balls: Sprite[] = [];

    // Handle time
    private startTime: number;
    private currentTime: number;
    private addBallCurrentTime: number;
    private addBallStartTime: number;

    // Phaser Objects
    private gameboard: Phaser.GameObjects.Sprite;
    private paddle: Phaser.GameObjects.Sprite;
    private mouse: Phaser.Input.InputPlugin;
    private scoreText: Phaser.GameObjects.Text;
    private livesText: Phaser.GameObjects.Text;
    private menuButton: Phaser.GameObjects.Sprite;
    private gameoverImage: Phaser.GameObjects.Image;

    // Varies speed
    private speed: number[] = [0.2, 0.19, 0.18, 0.17, 0.16, 0.15, 0.14, 0.13, 0.12, 0.11, 0.10, 0.09, 0.08, 0.07, 0.06, 0.05,
        -0.2, -0.19, -0.18, -0.17, -0.16, -0.15, -0.14, -0.13, -0.12, -0.11, -0.10, -0.09, -0.08, -0.07, -0.06, -0.05];

    constructor() {

        super(sceneConfig);
    }

    public init(data: any): void {

    }

    public preload(): void {

        // Project asset location (images)
        this.load.setBaseURL('../assets/images/projects/games2D/pong-survivor');

        this.load.image('gameboard', 'game/gameboard.png');
        this.load.image('gameover', 'game/gameover.png');
        this.load.image('paddle', 'game/paddle.png');
        this.load.spritesheet('balls', 'game/balls.png', {frameWidth: 20, frameHeight: 20});
        this.load.spritesheet('menu_button', 'game/menu_buttons.png', {frameWidth: 128, frameHeight: 58});
    }

    public create(): void {

        // Create the gameboard
        this.gameboard = this.add.sprite(0, 0, 'gameboard');
        this.gameboard.setOrigin(0, 0);

        // Create the player paddle
        this.paddle = this.add.sprite(40, 180, 'paddle');
        this.paddle.setOrigin(0, 0);
        this.paddleMidpoint = this.paddle.height / 2;

        // Add a ball to the screen
        this.addBall();

        // Get the mouse input
        this.mouse = this.input;

        this.isActive = true;
        this.isGameOver = false;

        this.score = 0;
        this.lives = 5;
        this.scoreText = this.add.text(129, 8, this.score.toString(), {fontSize: '30px', color: 'rgba(255, 0, 0, 0.4)'});
        this.scoreText.setDepth(1);

        this.livesText = this.add.text(342, 8, this.lives.toString(), {fontSize: '30px', color: 'rgba(255, 0, 0, 0.4)'});
        this.livesText.setDepth(1);

        this.gameoverImage = this.add.image(145, 204, 'gameover').setOrigin(0, 0);
        this.gameoverImage.setVisible(this.isGameOver);
        this.gameoverImage.setDepth(1);

        // Create the menu button
        this.menuButton = this.add.sprite(600, 426, 'menu_button', 0).setOrigin(0);
        this.menuButton.setVisible(this.isGameOver);
        this.menuButton.disableInteractive();
        this.menuButton.setDepth(1);

        this.menuButton.on('pointerdown', () => {

            this.menuButton.setFrame(1);
        });

        this.menuButton.on('pointerup', () => {

            this.menuButton.setFrame(0);
            this.delete();

            // Switch to the MenuScreen to begin playing
            this.scene.start('MenuScreen');
        });

        this.startTime = new Date().getTime();
        this.currentTime = this.startTime;
        this.addBallStartTime = this.startTime;
    }

    public update(): void {

        // The game is active 
        if(this.isGameOver == false) {

            // Calculate the time
            let elapsedTime: number = new Date().getTime() - this.currentTime;
            this.currentTime += elapsedTime;

            // Update animation
            this.addMoreBalls(new Date().getTime());
            this.collisionWithPaddle();
            this.updateBallMovement(elapsedTime);

            this.gameDraw();

            // Move the player paddle base on mouse movement
            this.movePaddle();
        }
    }

    public delete(): void {

        if(this.isActive) {

            this.gameboard.destroy();
            this.paddle.destroy();
            this.scoreText.destroy();
            this.livesText.destroy();
            this.gameoverImage.destroy();
            this.menuButton.destroy();

            // Delete all the balls
            for(let i = this.balls.length - 1; i >= 0; i--) {

                this.balls[i].getImage().destroy();

                // Remove from the list
                this.balls.splice(i, 1);
            }

            this.isActive = false;
        }
    }

    /**
     * Move the player paddle base on the mouse movement
     */
    private movePaddle(): void {

        // Do not move the paddle past the top wall
        if(this.mouse.y < this.topWall + this.paddleMidpoint) {

            this.paddle.y = this.topWall;
        }

        // Do not move the paddle past the bottom Wall
        else if(this.mouse.y > this.bottomWall - this.paddleMidpoint) {

            this.paddle.y = this.bottomWall - this.paddle.height;
        }

        // Move the paddle base on the mouse position
        else {

            // Center the paddle base on the mouse
            this.paddle.y = this.mouse.y - this.paddleMidpoint;
        }
    }

    private addMoreBalls(currentTime: number): void {

        this.addBallCurrentTime = currentTime - this.addBallStartTime;

        // Continue to add new balls if less than 16
        if(this.balls.length < this.ballsAmount) {

            // If the current time equals 1.2 seconds then add 
            // another ball to the game 
            if(this.addBallCurrentTime >= 1200) {

                this.addBall();

                // Reset the time
                this.addBallCurrentTime = 0;
                this.addBallStartTime = new Date().getTime();
            }
        }
    }

    private gameDraw(): void {

        // Draw all the balls in the list
        for(let i = 0; i < this.balls.length; i++) {

            // Get the current ball
            let ball = this.balls[i].getImage();
            ball.setX(Math.round(this.balls[i].getX()));
            ball.setY(Math.round(this.balls[i].getY()));
        }
    }

    /**
     * Test the collision between the balls and the player paddle
     */
    private collisionWithPaddle(): void {

        // Collision with the paddle
        // Loop through all the balls and test for collision
        for(let i = 0; i < this.balls.length; i++) {

            if(this.balls[i].getX() <= this.paddle.x + this.paddle.width &&
               this.balls[i].getY() >= this.paddle.y &&
               this.balls[i].getY() <= this.paddle.y + this.paddle.height) {

                /**
                 * The ball hot the player paddle. This allows a ball to be counted
                 * only once when it hit the player paddle. Otherwise the player could
                 * receive an increase in score because of one hit.
                 */
                if(this.balls[i].hitRightWall) {

                    // Send the ball in the opposite direction
                    this.balls[i].setVelocityX(Math.abs(this.balls[i].getVelocityX()));

                    // Update the player score
                    this.score += 10;

                    // Display the new score
                    this.scoreText.text = this.score.toString();

                    // Go back towards the right wall
                    this.balls[i].hitRightWall = false;
                }
            }
        }
    }

    private updateBallMovement(elapsedTime: number): void {

        for(let i = 0; i < this.balls.length; i++) {

            /******************************************************************
             * 
             * Ball move past the player paddle, so reduce the amount of lives
             * 
             ******************************************************************/
            if(this.balls[i].getX() < 10) {

                // reduce the lives the players has by one
                this.lives -= 1;

                // Update the lives display
                this.livesText.text = this.lives.toString();

                /*******************************************************
                 * 
                 * THE GAME IS OVER
                 * 
                 *******************************************************/
                if(this.lives <= 0) {

                    this.isGameOver = true;

                    // Display game over image
                    this.gameoverImage.setVisible(this.isGameOver); 

                    // Display the menu button
                    this.menuButton.setVisible(this.isGameOver);
                    this.menuButton.setInteractive();

                    // exit loop
                    break;
                }
                
                // Delete the image
                this.balls[i].getImage().destroy();

                // Remove from the list
                this.balls.splice(i, 1);

                // GO TO THE NEXT BALL
                continue;
            }

            // Right Wall
            else if (this.balls[i].getX() + this.balls[i].getWidth() >= this.rightWall) {

                this.balls[i].hitRightWall = true;
                this.balls[i].setVelocityX(-Math.abs(this.balls[i].getVelocityX()));
            }

            // Top Wall
            if(this.balls[i].getY() < this.topWall) {

                this.balls[i].setVelocityY(Math.abs(this.balls[i].getVelocityY()));
            }

            // Bottom wall
            else if(this.balls[i].getY() + this.balls[i].getHeight() >= this.bottomWall) {

                this.balls[i].setVelocityY(-Math.abs(this.balls[i].getVelocityY()));
            }

            // Update the sprite
            this.balls[i].update(elapsedTime);
        }
    }

    /**
     * Add a ball to the screen
     */
     private addBall(): void {

        // Starting position for ball in the game
        let x: number = 400;
        let y: number = 250;

        // Select a random speed for the balls
        let dx: number = this.speed[this.randomInt(1, this.speed.length) - 1];
        let dy: number = this.speed[this.randomInt(1, this.speed.length) - 1];

        // Get the next ball in the spritesheet
        let ball = this.add.image(x, y, 'balls', this.nextBall);
        ball.setOrigin(0, 0);
        ball.setVisible(false);

        // Create the animation
        let anim: Animation = new Animation();
        anim.addFrame(ball, 0);

        // Create a sprite for the current ball
        // Set position and velocity
        let sprite = new Sprite(anim);
        sprite.setX(x);
        sprite.setY(y);
        sprite.setVelocityX(dx);
        sprite.setVelocityY(dy);

        // Add the sprite to the balls list
        this.balls.push(sprite);

        // Loop to the next ball in the list
        this.nextBall += 1;

        // Reset to position 0
        if(this.nextBall >= 16) {

            this.nextBall = 0;
        }
    }

    /**
     * Return a random number between two numbers
     */
     private randomInt(min, max): number {

        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }
}
import * as Phaser from 'phaser';

export class Clock {

    // Start with 60 seconds
    private time: number = 60000;
    private timeDisplay: Phaser.GameObjects.Text;
    private lineGraphics: Phaser.GameObjects.Graphics;

    private hours: number = 0;
    private minutes: number = 0;
    private seconds: number = 0;

    private startTime: number = 0;
    private currentTimeDisplay: string;

    private gameOver: boolean = false;

    private screenWidth: number = 0;
    private screemHeight: number = 0;

    constructor(screenWidth: number, screenHeight: number) {

        this.screenWidth = screenWidth;
        this.screemHeight = screenHeight;
    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.calculateTime();

        // Show the current time
        this.timeDisplay = add.text(this.screenWidth / 2 + 110, 10, this.currentTimeDisplay, {fontFamily: 'Arial', fontSize: '45px', color: 'rgb(255, 255, 255)'});
        this.timeDisplay.setOrigin(0, 0);
        this.timeDisplay.setVisible(false);

        // Draw a line underneath the countdown clock
        let x: number = this.screenWidth / 2 + 110;
        let y: number = 65;

        let line = new Phaser.Geom.Line(x, y, x + 270, 65);
        this.lineGraphics = add.graphics({lineStyle: {width: 4, color: 0xFF0000}});
        this.lineGraphics.strokeLineShape(line);
        this.lineGraphics.setVisible(false);
    }

    public update(): void {

        // The start time is zero
        if(this.startTime === 0) {

            // Get a new start time
            this.startTime = new Date().getTime();
        }

        else {

            // Set the current time
            let currentTime = new Date().getTime() - this.startTime;

            // If 1 second has passed then reduce the clock by 1 seconds
            if(currentTime >= 1000) {

                // Reduce by 1 second
                this.time = this.time - 1000;

                // Calculate the new time
                this.calculateTime();

                // Display the new time
                this.timeDisplay.text = this.currentTimeDisplay;

                // Reset the time
                this.startTime = 0;

                // If the current time is less than zero then
                // game is over
                if(this.time <=  0) {

                    this.gameOver = true;
                }
            }
        }
    }

    public turnOnClock(): void {

        this.timeDisplay.setVisible(true);
        this.lineGraphics.setVisible(true);
    }

    public turnOffClock(): void {

        this.timeDisplay.setVisible(false);
        this.lineGraphics.setVisible(false);
    }

    public destroy(): void {

        this.timeDisplay.destroy();
        this.lineGraphics.destroy();
        this.gameOver = false;
        this.time = 60000;
    }

    /**
     * Determine if the game is over base on the clock
     */
     public gameIsOver(): boolean {

        return this.gameOver;
    }


    private calculateTime(): void {

        // Calculate the hours, minutes, and seconds
        this.hours = Math.floor((this.time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((this.time % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((this.time % (1000 * 60)) / 1000);

        this.currentTimeDisplay = this.hours.toString() + "h : " + this.minutes.toString() + "m : " + this.seconds.toString() + "s";
    }

    /**
     * Add more time to the game clock
     */
     public addMoreTime(): void {

        // Add 15 seconds to the game clock
        this.time += 10000;

        this.calculateTime();
    }
}
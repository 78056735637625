import * as Phaser from 'phaser';

import { GameScreen } from '../screens/gameScreen';
import { Maze } from './maze';

export class Player {

    private mazeWalls = [];
    private gameOver: boolean = false;
    private SPEED: number = 2.2;
    private playerSprite: Phaser.GameObjects.Sprite;

    private totalGems: number = 0;
    private gameGemsDisplay: Phaser.GameObjects.Text;
    private gameGemsNumberDisplay: Phaser.GameObjects.Text;

    private score: number = 0;
    private scoreDisplay: Phaser.GameObjects.Text;
    private scoreNumberDisplay: Phaser.GameObjects.Text;

    private leftArrow: Phaser.Input.Keyboard.Key;
    private rightArrow: Phaser.Input.Keyboard.Key;
    private upArrow: Phaser.Input.Keyboard.Key;
    private downArrow: Phaser.Input.Keyboard.Key;
    private D: Phaser.Input.Keyboard.Key;
    private W: Phaser.Input.Keyboard.Key;
    private A: Phaser.Input.Keyboard.Key;
    private S: Phaser.Input.Keyboard.Key;

    constructor(private screenWidth: number, private screenHeight: number, private maze: Maze) {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory, scene: GameScreen): void {

        this.playerSprite = add.sprite(0, 0, 'player');
        this.playerSprite.setOrigin(0, 0);
        this.playerSprite.setVisible(false);

        // Show the coins title
        this.gameGemsDisplay = add.text(this.screenWidth / 2 + 125, 200, 'Coins', {fontFamily: 'Arial', fontSize: '40px', color: '#FF9934'});
        this.gameGemsDisplay.setOrigin(0, 0);
        this.gameGemsDisplay.setVisible(false);
    
        // Show the total number of coins collected
        this.gameGemsNumberDisplay = add.text(this.screenWidth / 2 + 250, 200, this.totalGems.toString(), {fontFamily: 'Arial', fontSize: '40px', color: '#FFFFFF'});
        this.gameGemsNumberDisplay.setOrigin(0, 0);
        this.gameGemsNumberDisplay.setVisible(false);

        // Show the score title
        this.scoreDisplay = add.text(this.screenWidth / 2 + 125, 300, 'Score: ', {fontFamily: 'Arial', fontSize: '40px', color: '#FF9934'});
        this.scoreDisplay.setOrigin(0, 0);
        this.scoreDisplay.setVisible(false);

        // SHow the score number display
        this.scoreNumberDisplay = add.text(this.screenWidth / 2 + 250, 300, this.score.toString(), {fontFamily: 'Arial', fontSize: '40px', color: '#FFFFFF'});
        this.scoreNumberDisplay.setOrigin(0, 0);
        this.scoreNumberDisplay.setVisible(false);

        // Create character controls for the player
        this.leftArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
        this.rightArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
        this.upArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP);
        this.downArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN);
        this.D = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
        this.W = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
        this.A = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
        this.S = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);

        // Allow the player to play the game
        this.gameOver = false;
    }

    /**
     * Update the player movement
     */
    public update(): void {

        // Move the player to the right
        if(this.rightArrow.isDown || this.D.isDown) {

            // Update the new position
            this.playerSprite.x += this.SPEED;

            // Loop through the walls
            for(let i = 0; i < this.mazeWalls.length; i++) {
  
                // If the player collide with this wall then
                // move the player back to its previous position
                if(this.wallCollision(this.playerSprite, this.mazeWalls[i])){
                    
                    this.playerSprite.x -= this.SPEED;

                    // Exit the loop
                    break;
                }
            }
        }

        // Move the player to the left
        else if(this.leftArrow.isDown || this.A.isDown) {

            // Update the new position
            this.playerSprite.x -= this.SPEED;

            // Loop through the walls
            for(let i = 0; i < this.mazeWalls.length; i++) {

                // If the player collide with this wall then
                // move the player back to its previous position
                if(this.wallCollision(this.playerSprite, this.mazeWalls[i])){
                    
                    this.playerSprite.x += this.SPEED;

                    // Exit the loop
                    break;
                }
            }
        }

        // Move the player up
        if(this.upArrow.isDown || this.W.isDown) {

            // Update the new position
            this.playerSprite.y -= this.SPEED;

            // Loop through the walls
            for(let i = 0; i < this.mazeWalls.length; i++) {

                // If the player collide with this wall then
                // move the player back to its previous position
                if(this.wallCollision(this.playerSprite, this.mazeWalls[i])){
                    
                    this.playerSprite.y += this.SPEED;

                    // Exit the loop
                    break;
                }
            }
        }

        // Move the player down
        else if(this.downArrow.isDown || this.S.isDown) {

            // Update the new position
            this.playerSprite.y += this.SPEED;

            // Loop through the walls
            for(let i = 0; i < this.mazeWalls.length; i++) {

                // If the player collide with this wall then
                // move the player back to its previous position
                if(this.wallCollision(this.playerSprite, this.mazeWalls[i])){
                    
                    this.playerSprite.y -= this.SPEED;

                    // Exit the loop
                    break;
                }
            }
        }
    }

    public destroy(): void {

        this.playerSprite.destroy();
        this.gameGemsDisplay.destroy();
        this.gameGemsNumberDisplay.destroy();
        this.scoreDisplay.destroy();
        this.scoreNumberDisplay.destroy();

        this.leftArrow.destroy();
        this.rightArrow.destroy();
        this.upArrow.destroy();
        this.downArrow.destroy();
        this.A.destroy();
        this.W.destroy();
        this.S.destroy();
        this.D.destroy();
    }

    /**
     * Set the wall of the maze
     * 
     * @param mazeWalls 
     */
     public setMazeWalls(mazeWalls: any[]) {

        this.mazeWalls = mazeWalls;
    }

    private wallCollision(player: Phaser.GameObjects.Sprite, wall: any): boolean {

        // Test for collision with the wall
        if(player.x < wall.x + wall.width &&
           player.x + player.displayWidth > wall.x &&
           player.y < wall.y + wall.height &&
           player.y + player.displayHeight > wall.y) {

            return true;
        }

        else {

            return false;
        }
    }

    /**
     * 
     * 
     * @param size 
     */
     public setSize(squareSize: number): void {

        this.playerSprite.setVisible(true);

        // Set the speed for the player
        this.SPEED -= 0.1;

        // Starting position of the players
        this.playerSprite.x = 7;
        this.playerSprite.y = 7;

        // Size of the player
        this.playerSprite.displayWidth = this.maze.MAZE_WIDTH / squareSize - 5;       // original value: 3
        this.playerSprite.displayHeight = this.maze.MAZE_HEIGHT / squareSize - 5;     // original value: 3

        console.log(squareSize);
    }

    /**
     * This player has been destroyed
     * Stop them from player
     */
     public gameIsOver(): boolean {

        return this.gameOver;
    }

    /**
     * Return the player
     */
     public getPlayer(): Phaser.GameObjects.Sprite {

        return this.playerSprite;
    }

    /**
     * Turn the player and their information on
     */
     public turnPlayerOn(): void {

        this.playerSprite.setVisible(true);

        // Turn on the game gems display
        this.gameGemsDisplay.setVisible(true);
        this.gameGemsNumberDisplay.setVisible(true);

        // Turn on player score display
        this.scoreDisplay.setVisible(true);
        this.scoreNumberDisplay.setVisible(true);
    }

    /**
     * Turn the player and their information off
     */
     public turnPlayerOff(): void {

        this.playerSprite.setVisible(false);

        // Turn off the game gems display
        this.gameGemsDisplay.setVisible(false);
        this.gameGemsNumberDisplay.setVisible(false);

        // Turn off the player score display
        this.scoreDisplay.setVisible(false);
        this.scoreNumberDisplay.setVisible(false);
    }

    /**
     * The player has collected a coin
     * so add that to the player coin collection
     */
     public addGems(): void {

        // Total gems collected for the whole game
        this.totalGems += 1;

        // Update the visual display of the total amount of gems for the player
        this.gameGemsNumberDisplay.text = this.totalGems.toString();

        // Calculate the player score
        this.score += 10;

        // Show the new score
        this.scoreNumberDisplay.text = this.score.toString();
    }
}
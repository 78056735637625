import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameoverScreen'
}

export class GameoverScreen extends Phaser.Scene {

    private isActive: boolean = false;

    private background: Phaser.GameObjects.Sprite;
    //private gameoverButton: Phaser.GameObjects.Sprite;

    constructor() {

        super(sceneConfig);

        this.isActive = false;
    }

    public init(data: any): void {

    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/maze-escape');

        this.load.image('gameover_background', 'gameover/gameover_background.png');
        //this.load.spritesheet('gameover_button', 'gameover/gameover_button.png', {frameWidth: 219, frameHeight: 78});
    }

    public create(): void {

        this.background = this.add.sprite(0, 0, 'gameover_background');
        this.background.setVisible(true);
        this.background.setOrigin(0, 0);

        /*
        // Create the menu button
        this.gameoverButton = this.add.sprite(20, 397, 'gameover_button', 0);
        this.gameoverButton.setOrigin(0, 0);
        this.gameoverButton.setVisible(true);
        this.gameoverButton.setInteractive();

        // The player has lifted their finger
        this.gameoverButton.on('pointerup', () => {

            this.gameoverButton.setFrame(0);
            this.delete();

            // Switch to the Game Screen to begin playing
            this.scene.start('MenuScreen');
        });

        // The player has pressed the button
        this.gameoverButton.on('pointerdown', () => {

            this.gameoverButton.setFrame(1);
        });
        */

        this.isActive = true;
    }

    public update(): void {

        
    }

    public delete(): void {

        if(this.isActive) {

            // Remove all reference for the game object
            this.background.destroy();
            //this.gameoverButton.destroy();

            this.isActive = false;
        }
    }
}
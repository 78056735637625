import * as Phaser from 'phaser';

export class Level {

    private currentLevel: number;
    private levelDisplay: Phaser.GameObjects.Text;
    private levelNumberDisplay: Phaser.GameObjects.Text;
    private nextLevelDisplay: Phaser.GameObjects.Text;

    constructor(private screenWidth: number, private screenHeight: number) {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.currentLevel = 0;

        // Show the current level on the page
        this.levelDisplay = add.text(this.screenWidth / 2 + 125, 100, "Level: ", {fontFamily: 'Arial', fontSize: '40px', color: '#FF9934'});
        this.levelDisplay.setOrigin(0, 0);
        this.levelDisplay.setVisible(false);

        // Show the level number display on the page
        this.levelNumberDisplay = add.text(this.screenWidth / 2 + 250, 100, this.currentLevel.toString(), {fontFamily: 'Arial', fontSize: '40px', color: '#FFFFFF'});
        this.levelNumberDisplay.setOrigin(0, 0);
        this.levelNumberDisplay.setVisible(false);

        // Show the current level on the page
        this.nextLevelDisplay = add.text(0, 0, 'Level ' + this.currentLevel.toString(), {fontFamily: 'Arial', fontSize: '100px', color: '#FF9934'});
        this.nextLevelDisplay.x = this.screenWidth / 2 - this.nextLevelDisplay.width / 2;
        this.nextLevelDisplay.y = this.screenHeight / 2 - this.nextLevelDisplay.height / 2;
        this.nextLevelDisplay.setVisible(false);
    }

    /**
     * Get the current level
     */
     public getLevel(): number {

        return this.currentLevel;
    }

    /**
     * Switch to the next level
     */
     public nextLevel(): void {

        this.currentLevel += 1;
    
        // Game Over level display
        if(this.currentLevel === 11){
            let done = 10;
            this.levelNumberDisplay.text = done.toString();
        }

        // Display the next level
        else {
            
            this.levelNumberDisplay.text = this.currentLevel.toString();
        }

        this.nextLevelDisplay.text = "Level " + this.currentLevel.toString();
    }

    public turnOnNextLevel(): void {

        this.levelDisplay.visible = false;
        this.levelNumberDisplay.visible = false;
        this.nextLevelDisplay.visible = true;
    }

    public turnOffNextLevel(): void {

        this.levelDisplay.visible = true;
        this.levelNumberDisplay.visible = true;
        this.nextLevelDisplay.visible = false;
    }

    public destroy(): void {

        this.levelDisplay.destroy();
        this.levelNumberDisplay.destroy();
        this.nextLevelDisplay.destroy();
    }
}
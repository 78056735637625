<div class="w3-container">
    <div id="abc-fight-screen" style="margin-top: 20px;"></div>

    <!-- Credit in congratulation photo -->
    <div class="w3-container" style="margin-bottom: 20px;">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Game Controls:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Left Arrow - Move Left</label>
        </div>
        <div>
            <label style="font-size: 16px;">Right Arrow - Move Right</label>
        </div>
        <div>
            <label style="font-size: 16px;">Up Arrow - Move Up</label>
        </div>
        <div>
            <label style="font-size: 16px;">Down Arrow - Move Down</label>
        </div>
        <div>
            <label style="font-size: 16px;">Space Bar - Shoot</label>
        </div>
    </div>

    <div class="w3-container">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Credit:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Game Design by Madden</label>
        </div>
        <div>
            <span>Congratulation Fireworks Photo by <a href="https://unsplash.com/@shima_giraffe?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Nagatoshi Shimamura</a> on <a href="https://unsplash.com/s/photos/celebration?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
        </div>
    </div>
</div>
import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'MenuScreen'
}

export class MenuScreen extends Phaser.Scene {

    private isActive: boolean = false;

    private background: Phaser.GameObjects.Sprite;
    private playButton: Phaser.GameObjects.Sprite;

    constructor() {
        
        super(sceneConfig);

        this.isActive = false;
    }

    public init(data: any): void {

    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/maze-escape');

        // Load the images and spritesheet
        this.load.image('menu_background', 'menu/menu_background.png');
        this.load.spritesheet('menu_buttons', 'menu/menu_buttons.png', {frameWidth: 194, frameHeight: 72});
    }

    public create(): void {

        this.background = this.add.sprite(0, 0, 'menu_background').setOrigin(0, 0);

        // Create the play button
        this.playButton = this.add.sprite(40, 380, 'menu_buttons', 0).setOrigin(0, 0);
        this.playButton.setVisible(true);
        this.playButton.setInteractive();

        // The player has lifted their finger
        this.playButton.on('pointerup', () => {

            this.playButton.setFrame(0);
            this.delete();

            // Switch to the Game Screen to begin playing
            this.scene.start('GameScreen');
        });

        // The player has pressed the button
        this.playButton.on('pointerdown', () => {

            this.playButton.setFrame(1);
        });

        this.isActive = true;
    }

    public update(): void {

    }

    public delete(): void {

        if(this.isActive) {

            // Remove all reference for the game object
            this.background.destroy();
            this.playButton.destroy();

            this.isActive = false;

            console.log('Menu Screen objects have been removed');
        }
    }
}
import * as Phaser from 'phaser';

import { MenuScreen } from './screens/menuScreen';
import { GameScreen } from './screens/gameScreen';
import { GameoverScreen } from './screens/gameoverScreen';
import { CongratulationScreen } from './screens/congratulationScreen';

export class Game {

    private readonly gameWidth: number = 800;
    private readonly gameHeight: number = 500;
    private readonly backgroundColor: string = 'rgb(0, 0, 0)';

    private game: Phaser.Game;
    private gameConfig: Phaser.Types.Core.GameConfig;

    // Scenes within the game
    private menuScreen: MenuScreen;
    private gameScreen: GameScreen;
    private gameoverScreen: GameoverScreen;
    private congratulationScreen: CongratulationScreen;

    constructor() {

    }

    public init(): void {

        this.menuScreen = new MenuScreen();
        this.gameScreen = new GameScreen(this.gameWidth, this.gameHeight);
        this.gameoverScreen = new GameoverScreen();
        this.congratulationScreen = new CongratulationScreen();

        this.gameConfig = {

            type: Phaser.AUTO,

            // Screen size
            width: this.gameWidth,
            height: this.gameHeight,

            // Set the frames per second
            fps: {

                target: 60,
                forceSetTimeOut: true
            },

            // DIV element
            parent: 'maze-escape-screen',

            // Set background color
            backgroundColor: this.backgroundColor,

            physics: {
                default: 'arcade',
                arcade: {
                    gravity: {y: 300}
                }
            },

            scene: [this.menuScreen, this.gameScreen, this.gameoverScreen, this.congratulationScreen]
        }

        // Start the game
        this.game = new Phaser.Game(this.gameConfig);
    }

    /**
     * Remove all resources from the game
     */
     public delete(): void {

        this.menuScreen.delete();
        //this.selectCharacterScreen.delete();
        //this.gameScreen.delete();

        this.game.destroy(false, false);
    }
}
import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameOverScene'
};

export class GameOverScene extends Phaser.Scene {

    private isActive: boolean = false;

    private score: number = 0;
    private level: number = 0;

    private scoreText: Phaser.GameObjects.Text;
    private levelText: Phaser.GameObjects.Text;

    private gameOverBackground: Phaser.GameObjects.Sprite;
    private gameOverButton: Phaser.GameObjects.Sprite;

    constructor() {

        super(sceneConfig);
    }

    /**
     * Initialize game variables
     */
    public init(data): void {
        
        this.score = data.score;
        this.level = data.level;
    }

    /**
     * This will load all the images, text files, etc for the game
     */
    public preload(): void {

        // Specify the image folder
        this.load.setBaseURL('../assets/images/projects/games2D/abc-fight');

        // Load the images and spritesheet
        this.load.image('gameover_background', 'gameover/game_over_background.png');
        this.load.image('beat_game', 'gameover/congratulation_background.png');
        this.load.spritesheet('gameover_button', 'gameover/game_over_background_button.png', {frameWidth: 100, frameHeight: 50});
        this.load.spritesheet('beat_game_button', 'gameover/congratulation_background_button.png', {frameWidth: 120, frameHeight: 51});
    }

    /**
     * 
     */
    public create(): void {

        // Beat the game
        if(this.level > 5) {

            this.gameOverBackground = this.add.sprite(0, 0, 'beat_game').setOrigin(0, 0);
            this.gameOverBackground.setVisible(true);

            this.scoreText = this.add.text(680, 15, this.score.toString(), {fontFamily: 'Arial', fontSize: '40px', color: 'rgb(255, 255, 255)'});
            this.scoreText.setOrigin(0, 0);
            this.scoreText.setVisible(true);

            /*************************
             * Game Over Button      *
             *************************/
            this.gameOverButton = this.add.sprite(15, 90, 'beat_game_button', 0).setOrigin(0, 0);
            this.gameOverButton.setVisible(true);
            this.gameOverButton.setInteractive();

            // The player has lifted their finger
            this.gameOverButton.on('pointerup', () => {

                this.gameOverButton.setFrame(0);
                this.delete();

                // Switch to the select character scene
                this.scene.start('MenuScene');
            });

            // The player has pressed the button
            this.gameOverButton.on('pointerdown', () => {

                this.gameOverButton.setFrame(1);
            });

            this.isActive = true;
        }

        // Died on the following level
        else {

            this.gameOverBackground = this.add.sprite(0, 0, 'gameover_background').setOrigin(0, 0);
            this.gameOverBackground.setVisible(true);

            this.scoreText = this.add.text(115, 16, this.score.toString(), {fontFamily: 'Arial', fontSize: '30px', color: 'rgb(0, 0, 0)'});
            this.scoreText.setOrigin(0, 0);
            this.scoreText.setVisible(true);


            let levelCompleted = this.level;
            this.levelText = this.add.text(180, 453, levelCompleted.toString(), {fontFamily: 'Arial', fontSize: '30px', color: 'rgb(0, 0, 0)'});
            this.levelText.setOrigin(0, 0);
            this.levelText.setVisible(true);

            /*************************
             * Game Over Button      *
             *************************/
            this.gameOverButton = this.add.sprite(673, 440, 'gameover_button', 0).setOrigin(0, 0);
            this.gameOverButton.setVisible(true);
            this.gameOverButton.setInteractive();

            // The player has lifted their finger
            this.gameOverButton.on('pointerup', () => {

                this.gameOverButton.setFrame(0);
                this.delete();

                // Switch to the select character scene
                this.scene.start('MenuScene');
            });

            // The player has pressed the button
            this.gameOverButton.on('pointerdown', () => {

                this.gameOverButton.setFrame(1);
            });

            this.isActive = true;
        }
        
    }

    public update(): void {

    }

    /**
     * Remove all references from the game
     */
    public delete(): void {

        if(this.isActive) {

            this.gameOverBackground.destroy();
            this.gameOverButton.destroy();
            this.scoreText.destroy();

            if(this.levelText != null && this.levelText != undefined) {

                this.levelText.destroy();
            }
        }
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amesmi-jeopardy-rules',
  templateUrl: './amesmi-jeopardy-rules.component.html',
  styleUrls: ['./amesmi-jeopardy-rules.component.scss']
})
export class AmesmiJeopardyRulesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

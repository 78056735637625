import * as Phaser from 'phaser';

import { MenuScreen } from './screens/menuScreen';
import { SelectCharacterScreen } from './screens/selectCharacterScreen';
import { GameScreen } from './screens/gameScreen';

export class Game {

    private readonly gameWidth: number = 700;
    private readonly gameHeight: number = 400;
    private readonly backgroundColor: string = 'rgb(0, 0, 0)';

    private game: Phaser.Game;
    private gameConfig: Phaser.Types.Core.GameConfig;

    // Scenes within the game
    private menuScreen: MenuScreen;
    private selectCharacterScreen: SelectCharacterScreen;
    private gameScreen: GameScreen;

    constructor() {

    }

    public init(): void {

        this.menuScreen = new MenuScreen();
        this.selectCharacterScreen = new SelectCharacterScreen();
        this.gameScreen = new GameScreen(this.gameWidth, this.gameHeight);

        this.gameConfig = {

            type: Phaser.AUTO,

            // Screen size
            width: this.gameWidth,
            height: this.gameHeight,

            // Set the frames per second
            fps: {

                target: 60,
                forceSetTimeOut: true
            },

            // DIV element
            parent: 'color-fight-screen',

            // Set background color
            backgroundColor: this.backgroundColor,

            physics: {
                default: 'arcade',
                arcade: {
                    gravity: {y: 300}
                }
            },

            scene: [this.menuScreen, this.selectCharacterScreen, this.gameScreen]
        }

        // Start the game
        this.game = new Phaser.Game(this.gameConfig);
    }

    /**
     * Remove all resources from the game
     */
    public delete(): void {

        this.menuScreen.delete();
        this.selectCharacterScreen.delete();
        this.gameScreen.delete();

        this.game.destroy(false, false);
    }
}
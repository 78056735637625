import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'CongratulationScreen'
}

export class CongratulationScreen extends Phaser.Scene {

    private isActive: boolean = false;

    private background: Phaser.GameObjects.Sprite;

    constructor() {

        super(sceneConfig);

        this.isActive = false;
    }

    public init(data: any): void {

    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/maze-escape');

        this.load.image('congratulation_background', 'congratulation/congratulation_background.png');
    }

    public create(): void {

        this.background = this.add.sprite(0, 0, 'congratulation_background');
        this.background.setVisible(true);
        this.background.setOrigin(0, 0);

        this.isActive = true;
    }

    public update(): void {

    }

    public delete(): void {
        
        this.background.destroy();
        this.isActive = false;
    }
}
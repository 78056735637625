import { PeerEvent, PeerEventDispatcher } from './peerEventDispatcher';
import { Peer } from './peer';

export class GuestPeer extends PeerEventDispatcher {

    private peer: Peer;
    private websocket: WebSocket;
    private readonly SOCKET_URL: string = 'wss://amesmi.openode.dev'; /*'wss://amesmi.openode.io';*/ //'ws://localhost:3000';

    private readonly pcConfig = {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]};

    constructor(private myID: string, private lobbyName: string) {

        super();

        this.websocket = new WebSocket(this.SOCKET_URL, ['protocolOne', 'protocolTwo']);

        // The socket is open and can start receiving messages from the server
        this.websocket.onopen = () => {

            //console.log('Successfully connected to the server');

            let object = {
                application: 'how-many',
                action: 'add-socket-to-lobby',
                playerID: this.myID,
                lobbyName: lobbyName
            }

            this.sendMessageToServer(JSON.stringify(object));

            this.incomingMessages();
        }
    }

    /**
     * The Player is sending a message to the server
     * 
     * @param message 
     */
    public sendMessageToServer(message: string): void {

        //console.log('Sending message to server');
        //console.log(message);

        switch(this.websocket.readyState) {

            case WebSocket.CLOSED:
                //console.log('Socket is closed');
                break;

            case WebSocket.CLOSING:
                //console.log('Socket is closing');
                break;

            case WebSocket.CONNECTING:
                //console.log('Socket is connecting');
                break;

            case WebSocket.OPEN:

                // Sending message to server
                this.websocket.send(message)

                break;
        }
    }

    /**
     * Send a message to the host
     * 
     * @param message 
     * @param position 
     */
    public sendMessage(message: string): void {

        this.peer.dataChannel.send(message);
    }

    /**
     * Close the socket to the server
     */
    public closeSocket(): void {

        if(this.websocket != null && this.websocket != undefined) {

            this.websocket.close();
        }
    }

    /**
     * Connect to the other peer (HOST)
     * 
     * @param hostID 
     * @param hostName 
     */
    public async connect(hostID: string, hostName: string) {

        this.peer = new Peer();
        this.peer.otherPeerID = hostID;
        this.peer.otherPeerName = hostName;

        // Initialize RTCPeerConnection for the user
        await this.createPeer();

        let offer = await this.peer.peerConnection.createOffer();
        await this.peer.peerConnection.setLocalDescription(offer);

        // Send the offer to the HOST
        let object = {
            
            application: 'how-many',
            type: 'offer',
            offer: offer,
            otherPeerID: this.peer.otherPeerID,
            guestID: this.myID,
            lobbyName: this.lobbyName
        }

        this.sendMessageToServer(JSON.stringify(object));
    }

    /**
     * Close all connections to remote peer
     */
    public close(): void {

        if(this.peer.dataChannel != null && this.peer.dataChannel != undefined) {

            this.peer.dataChannel.close();
        }

        if(this.peer.peerConnection != null && this.peer.peerConnection != undefined) {

            this.peer.peerConnection.close();
            this.peer.peerConnection = null;
        }
    }

    /**
     * Process all incoming messages from the server
     */
    private incomingMessages(): void {

        this.websocket.onmessage = (event) => {

            // Get the information from the server
            let message = JSON.parse(event.data);

            // The GUEST has been allowed into the lobby
            if(message.action === 'guest-can-enter-lobby') {
                
                this.dispatchEvent(new PeerEvent('enter-lobby', ''));
            }

            // The GUEST cannot enter lobby
            else if(message.action === 'guest-cannot-enter-lobby') {

                this.dispatchEvent(new PeerEvent('cannot-enter-lobby', ''));
            }

            else if(message.type === 'new-ice-candidate') {

                let candidate = new RTCIceCandidate({

                    sdpMLineIndex: message.label,
                    sdpMid: message.id,
                    candidate: message.candidate
                });

                this.addIceCandidate(candidate);
            }

            else if(message.type === 'answer') {

                this.setRemoteDescription(new RTCSessionDescription(message.answer));
            }
        }
    }

    private async createPeer() {

        // Create a peer connection
        this.peer.peerConnection = new RTCPeerConnection(this.pcConfig);

        // Create a new data dataChannel
        this.peer.dataChannel = await this.peer.peerConnection.createDataChannel(this.peer.otherPeerName, { ordered: true });

        // Handle all incoming message from connecting peer
        this.peer.peerConnection.ondatachannel = e => {

            e.channel.onopen = (event) => this.dataChannelOpen(event);
            e.channel.onclose = (event) => this.dataChannelClose(event);
            e.channel.onmessage = (event) => this.dataChannelMessage(event);
            e.channel.onerror = (event) => this.dataChannelError(event);
        }

        // Not supported in FireFox
        this.peer.peerConnection.onconnectionstatechange = (event) => {

            switch(this.peer.peerConnection.connectionState) {

                case 'closed': /*console.log('peer closed');*/ break;
                case 'connected': /*console.log('peer connected');*/ break;         // Once peers are connected, then start the application
                case 'connecting': /*console.log('peer connecting');*/ break;
                case 'disconnected': /*console.log('peer disconnected');*/ break;
                case 'failed': /*console.log('peer failed');*/ break;
                case 'new': /*console.log('peer new');*/ break;
            }
        }

        this.peer.peerConnection.onsignalingstatechange = (event) => {

            switch(this.peer.peerConnection.signalingState) {

                case 'closed': /*console.log('signaling state closed');*/ break;
                case 'have-local-offer': /*console.log('signaling state have-local-offer');*/ break;
                case 'have-local-pranswer': /*console.log('signaling state have-local-pranswer');*/ break;
                case 'have-remote-offer': /*console.log('signaling state have-remote-offer');*/ break;
                case 'have-remote-pranswer': /*console.log('signaling state have-remote-pranswer');*/ break;
                case 'stable': /*console.log('signaling state stable');*/ break;
            }
        }

        await this.createIceCandidate();
    }

    /*****************************************************************
     * 
     * Beginning of DataChannel methods
     * 
     *****************************************************************/

     private dataChannelOpen(event: Event): void {

        //console.log('Peer data channel is open');           // The can start exchanging messages

        this.dispatchEvent(new PeerEvent('datachannel-open', ''));
    }

    private dataChannelClose(event: Event): void {

        //console.log('Peer data channel is close');

        // Close all connections to the remote server
        this.close();
    }

    private dataChannelMessage(event: MessageEvent): void {

        //console.log('Incoming message');

        // event.data returns the message from the remote peer
        this.dispatchEvent(new PeerEvent('datachannel-message', event.data));
    }

    private dataChannelError(event: Event): void {

        //console.log('Peer data channel error');

        // Close all connections to the remote server
        this.close();
    }

    /****************** End of DataChannel methods *****************/

    private createIceCandidate(): void {

        this.peer.peerConnection.oniceconnectionstatechange = (event) => {

            switch(this.peer.peerConnection.iceConnectionState) {

                case 'new': /*console.log('new ice candidate');*/ break;
                case 'checking': /*console.log('checking ice candidate');*/ break;
                case 'connected': /*console.log('connected ice candidate');*/ break;
                case 'completed': /*console.log('completed ice candidate');*/ break;
                case 'failed': /*console.log('failed ice candidate');*/ break;
                case 'disconnected': /*console.log('disconnected ice candidate');*/ break;
                case 'closed': /*console.log('closed ice candidate');*/ break;
            }
        }

        this.peer.peerConnection.onicegatheringstatechange = (event) => {

            switch(this.peer.peerConnection.iceGatheringState) {

                case 'complete': /*console.log('complete ice gathering');*/ break;
                case 'gathering': /*console.log('gathering ice');*/ break;
                case 'new': /*console.log('new ice gathering');*/ break;
            }
        }

        //this.peerConnection.addEventListener('icecandidate', (event) => {
        this.peer.peerConnection.onicecandidate = (event) => {

            /*
            //console.log('icecandidate event: ' + event);
            console.log('Ice Candidate Info');
            console.log(event.candidate);
            console.log(" ");
            */

            if(event.candidate) {

                let object = {

                    application: 'how-many',
                    type: 'new-ice-candidate',
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate,
                    otherPeerID: this.peer.otherPeerID,
                    guestID: this.myID,
                    lobbyName: this.lobbyName
                }

                /*
                console.log('Sending ice candidate:');
                console.log(event.candidate);
                console.log(' ');
                */

                this.sendMessageToServer(JSON.stringify(object));
            }

            else {

                //console.log('All ICE candidates have been sent');
            }
        };
    }

    private async addIceCandidate(candidate: RTCIceCandidate) {

        try {

            /*
            console.log('Adding Ice Candidate:');
            console.log(candidate);
            console.log(' ');
            */

            await this.peer.peerConnection.addIceCandidate(candidate);
        }

        catch (e) {

            //console.error('Error adding received ice candidate');
        }
    }

    private async setRemoteDescription(message: any) {

        let sessionDescription = new RTCSessionDescription(message);
        await this.peer.peerConnection.setRemoteDescription(sessionDescription);
    }
}
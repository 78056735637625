export class Peer {

    public peerConnection: RTCPeerConnection = null;
    public dataChannel: RTCDataChannel = null;
    public otherPeerID: string = '';
    public otherPeerName: string = '';

    constructor() {

    }
}
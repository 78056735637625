<div class="w3-container">
    <div id="maze-escape-screen" style="margin-top: 20px;"></div>

    <!-- Credit in congratulation photo -->
    <div class="w3-container" style="margin-bottom: 20px;">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Game Controls:</label>
        </div>
        <div>
            <label style="font-size: 16px;">A or Left Arrow - Move Left</label>
        </div>
        <div>
            <label style="font-size: 16px;">D or Right Arrow - Move Right</label>
        </div>
        <div>
            <label style="font-size: 16px;">W or Up Arrow - Move Up</label>
        </div>
        <div>
            <label style="font-size: 16px;">S or Down Arrow - Move Down</label>
        </div>
    </div>

    <!-- Coins -->
    <div class="w3-container" style="margin-bottom: 20px;">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Game Coins:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Gold coin - Worth 10 points</label>
        </div>
        <div>
            <label style="font-size: 16px;">Gold coin - Add 15 seconds to clock</label>
        </div>
        <div>
            <label style="font-size: 16px;">Brown coin - Move to next level</label>
        </div>
    </div>

    <!-- Game credit -->
    <div class="w3-container">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Credit:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Game Design by James</label>
        </div>
        <div>
            <span>Congratulation Fireworks Photo by <a href="https://unsplash.com/@shima_giraffe?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Nagatoshi Shimamura</a> on <a href="https://unsplash.com/s/photos/celebration?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import axios from 'axios';

@Component({
  selector: 'app-word-blitz-rankings',
  templateUrl: './word-blitz-rankings.component.html',
  styleUrls: ['./word-blitz-rankings.component.scss']
})
export class WordBlitzRankingsComponent implements OnInit {

  private readonly URL: string = 'https://amesmi.openode.dev'; //'https://amesmi.openode.io'; //'http://localhost:3000';

  leaderboard: any[];
  levelLeaderboard: any[];

  // Icons for the top 25 scores
  icons: string[] = ['icofont icofont-crown-king', 'icofont icofont-crown-queen', 'icofont icofont-crown', 'icofont icofont-award', 'icofont icofont-medal',
                     'icofont icofont-diamond', 'icofont icofont-star', 'icofont icofont-badge', 'icofont icofont-badge', 'icofont icofont-thumbs-up',
                     'icofont icofont-thumbs-up', 'icofont icofont-thumbs-up', 'icofont icofont-learn', 'icofont icofont-learn', 'icofont icofont-learn',
                     'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove',
                     'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove', 'icofont icofont-ui-remove'];

  constructor() { }

  ngOnInit(): void {

    axios.post(this.URL + '/getLeaderboard').then( (response) => {

      // Get the data from the server
      this.leaderboard = response.data;
     
    }).catch((error) => {

    });
    
    axios.post(this.URL + '/getLevelLeaderboard').then( (response) => {

      // Get the data from the server
      this.levelLeaderboard = response.data;
     
    }).catch((error) => {

    }); 
  }

  /**
   * Open the current leaderboard
   * 
   * @param event 
   * @param leaderboard 
   */
  public openLeaderboard(playMode: string, leaderboard: string): void {

    let x = document.getElementsByClassName('playMode') as HTMLCollectionOf<HTMLDivElement>;

    for(let i = 0; i < x.length; i++) {

      x[i].style.display = "none";
    }

    let tablink = document.getElementsByClassName('tablink') as HTMLCollectionOf<HTMLDivElement>;

    for(let i = 0; i < x.length; i++) {

      tablink[i].className = tablink[i].className.replace(" w3-blue-gray", "");
    }

    document.getElementById(leaderboard).style.display = "block";
    document.getElementById(playMode).className += " w3-blue-gray";
  }
}

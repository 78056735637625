import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ColorFightHomeComponent } from './color-fight-home/color-fight-home.component';

const routes: Routes = [

  {path: 'color-fight-home', component: ColorFightHomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-blitz-rules',
  templateUrl: './word-blitz-rules.component.html',
  styleUrls: ['./word-blitz-rules.component.scss']
})
export class WordBlitzRulesComponent implements OnInit {


  levelDescription = [
    {title: 'Level 1', description: 'Spell ten 2-letter words'},
    {title: 'Level 2', description: 'Spell eight 3-letter words'},
    {title: 'Level 3', description: 'Spell seven 4-letter words'},
    {title: 'Level 4', description: 'Spell six 5-letter words'},
    {title: 'Level 5', description: 'Spell five 6-letter words'},
    {title: 'Level 6', description: 'Spell four 7-letter words'},
    {title: 'Level 7', description: 'Spell three 8-letter words'},
    {title: 'Level 8', description: 'Spell two 9-letter words'},
    {title: 'Level 9', description: 'Spell one 10-letter words'}
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

<!-- 2D Game: Color Fight -->
<div class="w3-container" style="margin-top: 20px; margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/color-fight/logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">Color Fight</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">Dallas</label></h4>
            <p>Pick a color and try to survive</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer Only</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1</label>
            </div>
            <button routerLink="/color-fight-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: ABC Fight -->
<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/abc-fight/logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">ABC Fight</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">Madden</label></h4>
            <p>Try to survive before being destroyed by flying letters</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer Only</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1</label>
            </div>
            <button routerLink="/abc-fight-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: Maze Escape -->
<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/maze-escape/logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">Maze Escape</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>Escape the maze by collecting coins</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer Only</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1</label>
            </div>
            <button routerLink="/maze-escape-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: Pong Survivor -->
<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/pong-survivor/logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">Pong Survivor</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>Classic game of pong with a twist</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer Only</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1</label>
            </div>
            <button routerLink="/pong-survivor-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: Word Blitz -->
<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/word-blitz/logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">Word Blitz</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>How many words can you spell?</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer, Tablet, and Phone</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1 or 2</label>
            </div>
            <button routerLink="/word-blitz-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: How Many -->
<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <div class="icofont icofont-question-circle w3-text-green" style="max-width: 245px; font-size: 200px;"></div>
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">How Many</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>How many unique words can you really spell?</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer, Tablet, and Phone</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">2 - 6</label>
            </div>
            <button routerLink="/how-many-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>

<!-- 2D Game: US Cities -->
<!--<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <div class="icofont icofont-building-alt w3-text-gray w3-opacity-max" style="max-width: 245px; font-size: 200px;"></div>
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">United States Cities</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>Match cities with the appropriate state</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer, Tablet, and Phone</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">1</label>
            </div>
            <button routerLink="/us-cities-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>-->

<!-- 2D Game: Amesmi-Jeopardy -->
<!--<div class="w3-container" style="margin-bottom: 20px;">
    <div class="w3-cell-row w3-card-2">
        <div class="w3-cell w3-cell-middle w3-hide-small w3-light-gray w3-center w3-xxlarge" style="padding: 0px; width: 250px">
            <img class="w3-image" src="assets/images/projects/games2D/amesmi-jeopardy/jeopardy_logo.png" style="max-width: 245px;">
        </div>
        <div class="w3-cell w3-container w3-light-gray w3-border-left w3-cell-top" style="padding:0px 16px 8px 16px;">
            <h2>2D Game: <label class="w3-text-red">Amesmi-Jeopardy</label></h2>
            <h4>Designed by: <label class="w3-text-blue-gray">James</label></h4>
            <p>A tribute to the popular game show.</p>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Platform:</label><label class="w3-text-black">Computer, Tablet, and Phone</label>
            </div>
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="margin-right: 5px;">Players:</label><label class="w3-text-black">2 - 9</label>
            </div>
            <button routerLink="/amesmi-jeopardy-home" class="w3-button w3-blue-gray w3-border w3-border-blue-gray w3-round-medium">Home</button>
        </div>
    </div>
</div>-->
<!-- Display the Create Lobby, Join Lobby, and Rules Button -->
<!-- Meant to be a fun game for the entire family -->
<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray">
        <p class="w3-serif" style="font-size: 22px;">How Many</p>
        <p>How many unique words can you really spell?</p>
    </div>

    <ul class="w3-ul">

        <!-- Create a lobby -->
        <li class="w3-bar">
            <div class="icofont icofont-architecture-alt w3-bar-item w3-button w3-text-green w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/how-many-create-lobby"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Host Lobby</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Create a lobby for players to join</div>
            </div>
        </li>

        <!-- Join a lobby -->
        <li class="w3-bar">
            <div class="icofont icofont-users-social w3-bar-item w3-button w3-text-green w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/how-many-join-lobby"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Join Lobby</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Join an existing lobby</div>
            </div>
        </li>

        <!-- Rules -->
        <li class="w3-bar">
            <div class="icofont icofont-ui-note w3-bar-item w3-button w3-text-gray w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/how-many-rules"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Rules</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Learn how to play</div>
            </div>
        </li>
    </ul>
</div>
import { GameScreen } from "../../screens/gameScreen";
import { PlayerData } from "./player-data";

export class PlayerControls extends PlayerData {

    protected myGame: GameScreen;

    // Player controls
    private leftArrow: Phaser.Input.Keyboard.Key;
    private rightArrow: Phaser.Input.Keyboard.Key;
    private Q: Phaser.Input.Keyboard.Key;
    private W: Phaser.Input.Keyboard.Key;
    private A: Phaser.Input.Keyboard.Key;
    private S: Phaser.Input.Keyboard.Key;
    private spacebar: Phaser.Input.Keyboard.Key;

    private walkLeft: boolean = false;
    private walkRight: boolean = false;
    private standing: boolean = false;

    private punchLeft: boolean = false;
    private punchRight: boolean = false;
    private kickLeft: boolean = false;
    private kickRight: boolean = false;

    private readonly fistRadius: number = 9;

    constructor() {

        super();
    }

    protected createControls(scene: GameScreen): void {

        this.myGame = scene;

        // Create character controls for the player
        this.leftArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
        this.rightArrow = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
        this.Q = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q);
        this.W = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
        this.A = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
        this.S = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
        this.spacebar = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    }

    /**
     * The player is moving around the screen
     */
    protected handlePlayerMovement(): void {

        // The player is moving to the left
        if(this.leftArrow.isDown) {

            // First time the player indicated to walk left
            if(this.walkLeft == false) {

                // Start the walking left animation
                this.character.play('walkLeft');
                
                this.walkLeft = true;
                this.walkRight = false;
                this.standing = false;
            }

            // Move the player character to the right
            this.character.setVelocityX(-100);
        }

        // The player is moving to the right
        else if(this.rightArrow.isDown) {

            // First time the player indicated to walk right
            if(this.walkRight == false) {

                // Start the walking right animation
                this.character.play('walkRight');

                this.walkRight = true;
                this.standing = false;
                this.walkLeft = false;
            }

            // Move the player character to the right
            this.character.setVelocityX(100);
        }

        // The player is just standing there (not moving)
        else {

            // First time the indicated to just stand
            if(this.standing == false) {

                this.character.play('standing');

                this.standing = true;
                this.walkLeft = false;
                this.walkRight = false;
            }

            // Do not move the player
            this.character.setVelocityX(0);
        }

        // The player is jumping
        if(Phaser.Input.Keyboard.JustDown(this.spacebar)) {

            this.character.setVelocityY(-330);
        }
    }

    /**
     * The player is fighting the enemies
     */
    protected handlePlayerFighting(): void {

        this.punchX = 0;
        this.punchY = 0;
        this.kickX = 0;
        this.kickY = 0;

        // The player is not walking
        if(this.walkLeft == false && this.walkRight == false) {

            // Punching Left
            if(this.Q.isDown) {

                this.character.setFrame(this.position + 2);

                // Calculate character punch location
                // 48 is the difference in distance from the player head to their fist
                if(this.punchLeft == false) {

                    this.punchX = this.character.x;
                    this.punchY = this.character.y + 48 + this.fistRadius;

                    //this.enemy.testCollision(punchX, punchY);

                    this.punchLeft = true;
                }

                this.standing = false;
            }

            else if(this.Q.isUp) {

                this.punchLeft = false;
            }

            // Punching Right
            if(this.W.isDown) {

                this.character.setFrame(this.position + 1);

                // Calculate character punch location
                // 48 is the difference in distance from the player head to their fist
                if(this.punchRight == false) {

                    this.punchX = this.character.x + this.character.displayWidth;
                    this.punchY = this.character.y + 48 + this.fistRadius;

                    //this.enemy.testCollision(punchX, punchY);

                    this.punchRight = true;
                }
                
                this.standing = false;
            }

            else if(this.W.isUp) {

                this.punchRight = false;
            }

            // Kicking Left
            if(this.A.isDown) {

                this.character.setFrame(this.position + 4);

                if(this.kickLeft == false) {

                    // 84 is the difference bewteen the character head and their RIGHT foot
                    this.kickX = this.character.x;
                    this.kickY = this.character.y + 84 + this.fistRadius;

                    //this.enemy.testCollision(kickX, kickY);

                    this.kickLeft = true;
                }
                
                this.standing = false;
            }

            else if(this.A.isUp) {

                this.kickLeft = false;
            }

            // Kicking Right
            if(this.S.isDown) {

                this.character.setFrame(this.position + 3);

                if(this.kickRight == false) {

                    // 84 is the difference bewteen the character head and their RIGHT foot
                    this.kickX = this.character.x + this.character.displayWidth;
                    this.kickY = this.character.y + 84 + this.fistRadius;

                    //this.enemy.testCollision(kickX, kickY);

                    this.kickRight = true;
                }
                
                this.standing = false;
            }

            else if(this.S.isUp) {

                this.kickRight = false;
            }
        }
    }

    protected deleteControls(): void {

        this.leftArrow.destroy();
        this.rightArrow.destroy();
        this.Q.destroy();
        this.A.destroy();
        this.S.destroy();
        this.W.destroy();
    }
}
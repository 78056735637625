<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray w3-round-large">
        <p class="w3-xlarge w3-serif">Word Blitz Challenge Stats</p>
    </div>

    <ul class="w3-ul">
        <!-- Display your word list -->
        <li class="w3-bar">
            <div class="w3-bar-item">
                <div class="w3-text-blue-gray" style="font-size: 20px;">My Word List</div>
                <div class="w3-text-black">{{ myWordList }}</div>
            </div>
        </li>

        <!-- Display opponent word list -->
        <li class="w3-bar">
            <div class="w3-bar-item">
                <div class="w3-text-blue-gray" style="font-size: 20px;">Opponent Word List</div>
                <div class="w3-text-black">{{ opponentWordList }}</div>
            </div>
        </li>

        <!-- Buttons -->
        <li class="w3-bar" style="width: 450px;">

            <!-- Return the player to the main game page in order to select another game -->
            <div class="w3-bar-item w3-button w3-hover-white icofont icofont-home w3-text-blue-gray" title="Word Blitz Home" style="font-size: 40px;" routerLink="/word-blitz-home"></div>
            
            <!-- Return the player to the Word Blitz home page -->
            <!--<div class="w3-bar-item w3-button w3-hover-white icofont icofont-play-alt-3 w3-text-blue-gray" title="Play Again" style="font-size: 40px;" routerLink="/word-blitz-play"></div>-->
            
            <!-- The player is rank so allow the player to save their score -->
            <!--<div *ngIf="rank !== 0" class="w3-bar-item w3-button w3-hover-white icofont icofont-save w3-text-red" title="Save" style="font-size: 35px;" (click)="saveButton()"></div>-->
        </li>
    </ul>
</div>
export class AnimationFrame {

    public image: Phaser.GameObjects.Image;
    public endTime: number;

    constructor(image: Phaser.GameObjects.Image, endTime: number) {

        this.image = image;
        this.endTime = endTime;
    }
}
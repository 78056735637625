import * as Phaser from 'phaser';

import { Player } from '../objects/player/player';
import { Enemy } from '../objects/enemy/enemy';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScreen'
}

export class GameScreen extends Phaser.Scene {

    private isActive: boolean = false;
    public isGameOver: boolean = false;

    // Screen width and height
    public screenWidth: number = 0;
    public screenHeight: number = 0;

    private background: Phaser.GameObjects.Sprite;
    private nextLevel: Phaser.GameObjects.Sprite;
    private gameOverBackground: Phaser.GameObjects.Sprite;
    private beatGameBackground: Phaser.GameObjects.Sprite;
    private beatGameButton: Phaser.GameObjects.Sprite;
    private gameOverButton: Phaser.GameObjects.Sprite;
    private ground: Phaser.Physics.Arcade.StaticGroup;

    private player: Player;
    private enemy: Enemy[] = [];

    private startTime: number = 0;
    private timerSet: boolean = false;

    private level: number = 1;
    private levelText: Phaser.GameObjects.Text;
    private gameOverLevelText: Phaser.GameObjects.Text;
    private readonly completeLevel: number = 6;

    constructor(width: number, height: number) {

        super(sceneConfig);

        this.isActive = false;
        this.screenWidth = width;
        this.screenHeight = height;
    }

    public init(data: any): void {

        // Set the player character
        let playerCharacterPosition = data.playerCharacter;

        // Assign the player selection to the player object
        this.timerSet = false;
        this.player = new Player(playerCharacterPosition);
        this.level = 1;
    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/color-fight');

        // Load images and spritesheet
        this.load.image('game_background', 'screens/game/background.png');
        this.load.image('ground', 'screens/game/ground.png');
        this.load.image('next_level_image', 'screens/game/level_complete_background.png');
        this.load.image('beat_game', 'screens/game/congratulation_background.png');
        this.load.image('game_over', 'screens/game/game_over_background.png');
        this.load.spritesheet('fighters', 'screens/game/fighters.png', {frameWidth: 90, frameHeight: 127});
        this.load.spritesheet('energybar', 'screens/game/energybar.png', {frameWidth: 75, frameHeight: 7});
        this.load.spritesheet('explosion', 'screens/game/explosion.png', {frameWidth: 15, frameHeight: 15});
        this.load.spritesheet('beat_game_button', 'screens/game/congratulation_background_button.png', {frameWidth: 120, frameHeight: 51});
        this.load.spritesheet('game_over_button', 'screens/game/game_over_background_button.png', {frameWidth: 100, frameHeight: 50});
    }

    public create(): void {

        // Draw the background
        this.background = this.add.sprite(0, 0, 'game_background').setOrigin(0, 0);

        // Create a group grounds
        this.ground = this.physics.add.staticGroup();
        this.ground.create(0, 340, 'ground').setOrigin(0, 0).refreshBody();

        // Create the player
        this.player.create(this);
        this.physics.add.collider(this.player.getPlayer(), this.ground);

        // Start with level 1
        this.level = 1;
        this.startNewLevel(this.level);

        // Create the next level
        this.nextLevel = this.add.sprite(0, 0, 'next_level_image').setOrigin(0, 0);
        this.nextLevel.setVisible(false);

        this.levelText = this.add.text(248, 83, this.level.toString(), {fontFamily: 'Arial', fontSize: '70px', color: 'rgb(0, 0, 0)'});
        this.levelText.setVisible(false);

        this.gameOverBackground = this.add.sprite(0, 0, 'game_over').setOrigin(0, 0);
        this.gameOverBackground.setVisible(false);

        this.gameOverLevelText = this.add.text(184, 358, this.level.toString(), {fontFamily: 'Arial', fontSize: '30px', color: 'rgb(0, 0, 0)'});
        this.gameOverLevelText.setVisible(false);

        this.beatGameBackground = this.add.sprite(0, 0, 'beat_game').setOrigin(0, 0);
        this.beatGameBackground.setVisible(false);

        /*************************
         * Beat Game Button      *
         *************************/
        this.beatGameButton = this.add.sprite(11, 104, 'beat_game_button', 0).setOrigin(0, 0);
        this.beatGameButton.setVisible(false);
        this.beatGameButton.disableInteractive();

        // The player has lifted their finger
        this.beatGameButton.on('pointerup', () => {

            this.beatGameButton.setFrame(0);
            this.delete();

            // Switch to the select character scene
            this.scene.start('MenuScreen', {completedLevel: this.level});
        });

        // The player has pressed the button
        this.beatGameButton.on('pointerdown', () => {

            this.beatGameButton.setFrame(1);
        });

        /*************************
         * Game Over Button      *
         *************************/
        this.gameOverButton = this.add.sprite(586, 340, 'game_over_button', 0).setOrigin(0, 0);
        this.gameOverButton.setVisible(false);
        this.gameOverButton.disableInteractive();

        // The player has lifted their finger
        this.gameOverButton.on('pointerup', () => {

            this.gameOverButton.setFrame(0);
            this.delete();

            // Switch to the select character scene
            this.scene.start('MenuScreen', {completedLevel: this.level});
        });

        // The player has pressed the button
        this.gameOverButton.on('pointerdown', () => {

            this.gameOverButton.setFrame(1);
        });

        this.isActive = true;
    }

    public update(): void {

        // The game is not over, keep looping until the game is over
        // (player dies or player completed all the levels)
        if(this.isGameOver == false) {

            this.player.update();

            // Update the enemies of the game
            for(let i = 0; i < this.enemy.length; i++) {

                this.enemy[i].update(this.player.getPlayer().x);

                // See if the enemy is punching/kicking the player
                this.player.testCollision(this.enemy[i].punchX, this.enemy[i].punchY);
                this.player.testCollision(this.enemy[i].kickX, this.enemy[i].kickY);

                // See if the player is punching/kicking the enemy
                this.enemy[i].testCollision(this.player.punchX, this.player.punchY);
                this.enemy[i].testCollision(this.player.kickX, this.player.kickY);

                // The enemy has been destroyed
                if(this.enemy[i].isDead) {

                    // Remove all resources
                    this.enemy[i].destroy();

                    // Remove the enemy from the list
                    this.enemy.splice(i, 1);
                }

                // The player has been destroyed
                if(this.player.isDead) {

                    // Remove the player resources
                    this.player.destroy();

                    // Exit the loop
                    break;
                }
            }

            // All the enemies for this level has been defeated
            // Switch to a new level
            if(this.enemy.length === 0) {

                // Start the timer
                if(this.timerSet == false) {

                    this.startTimer();
                }

                // Set the current time
                let currentTime = new Date().getTime() - this.startTime;

                // See if the player completed the game
                if(this.level === this.completeLevel) {

                    this.isGameOver = true;

                    // Unlock the next character
                    //this.colorActive[this.level] = true;
                }

                // The game is not over so loop to the next level
                if(this.isGameOver == false) {

                    // Display the next level data
                    this.nextLevel.setVisible(true);
                    this.levelText.text = this.level.toString();
                    this.levelText.setVisible(true);

                    // Wait 5 seconds the start the next level
                    if(currentTime >= 5000) {

                        // Unlock the next color character

                        // Switch to the next level
                        this.level += 1;

                        // Switch the level
                        this.startNewLevel(this.level);

                        // Reset the start time
                        this.startTime = new Date().getTime();

                        // Stop displaying the following text
                        this.timerSet = false;
                        this.nextLevel.setVisible(false);
                        this.levelText.setVisible(false);
                    }
                }
            }
        }

        // The game is over. Give the player the option to
        // return to the menu screen
        else {

            // Are there enemies on the screen
            if(this.enemy.length > 0) {

                // Remove all remaining enemies from the game
                // before displaying the game over
                for(let i = this.enemy.length-1; i >= 0; i--) {

                    this.enemy[i].destroy();

                    // Remove the enemy from the array list
                    this.enemy.splice(i, 1);
                }
            }

            // Remove the player from the screen
            if(this.player != null && this.player != undefined) {

                this.player.destroy();

                this.player = null;
            }

            // Display the game over information
            // The player beat the game
            if(this.level === this.completeLevel) {

                this.beatGameBackground.setVisible(true);

                this.beatGameButton.setVisible(true);
                this.beatGameButton.setInteractive();
            }

            // The player lost the game
            else {

                this.gameOverBackground.setVisible(true);

                let myCompletedLevel: number = this.level - 1;
                this.gameOverLevelText.text = myCompletedLevel.toString();
                this.gameOverLevelText.setVisible(true);

                this.gameOverButton.setVisible(true);
                this.gameOverButton.setInteractive();
            }

            // Display the menu button
        }
    }

    public delete(): void {

        if(this.isActive) {

            this.isActive = false;
            this.isGameOver = false;

            // Remove all game resources
            this.background.destroy();
            this.ground.destroy();
            this.nextLevel.destroy();
            this.levelText.destroy();
            this.gameOverBackground.destroy();
            this.beatGameBackground.destroy();
            this.beatGameButton.destroy();
            this.gameOverButton.destroy();
            this.gameOverLevelText.destroy();

            if(this.player != null && this.player != undefined) {

                this.player.destroy();
            }

            for(let i = 0; i < this.enemy.length; i++) {

                if(this.enemy[i] != null && this.enemy[i] != undefined) {

                    this.enemy[i].destroy();
                }
            }

            console.log('Game Screen objects have been removed');
        }
    }

    /**
     * Start the timer in order to switch to another screen
     */
    private startTimer(): void {

        this.startTime = new Date().getTime();
        this.timerSet = true;
    }

    /**
     * Start a new level for the player
     */
    private startNewLevel(level: number): void {

        if(level === 1) {

            let numberOfEnemies: number = 2;
            this.createEnemies(numberOfEnemies);
        }

        else if(level === 2) {

            let numberOfEnemies: number = 3;
            this.createEnemies(numberOfEnemies);
        }

        else if(level === 3) {

            let numberOfEnemies: number = 4;
            this.createEnemies(numberOfEnemies);
        }

        else if(level === 4) {

            let numberOfEnemies: number = 5;
            this.createEnemies(numberOfEnemies);
        }

        else if(level === 5) {

            let numberOfEnemies: number = 6;
            this.createEnemies(numberOfEnemies);
        }
    }

    /**
     * Create the enemies for the game
     */
    private createEnemies(numberOfEnemies: number): void {

        /*
        for(let i = 0; i < numberOfEnemies; i++) {

            //this.enemy.push(new Enemy(1, 0));
            this.enemy[i] = new Enemy(1, 0);
            this.enemy[i].create(this);
            this.physics.add.collider(this.enemy[i].getEnemy(), this.ground);
        }
        */

        let count: number = 0;

        while(count < numberOfEnemies) {

            // Get the enemy color based on the current level
            let enemyColor = this.getRandomNumber(0, this.level);

            // The enemy color does not equal the player selected
            // color, so add this enemy to the list
            if(enemyColor != this.player.colorPosition) {

                this.enemy[count] = new Enemy(enemyColor, count);
                this.enemy[count].create(this);
                this.physics.add.collider(this.enemy[count].getEnemy(), this.ground);

                // Loop to the next position
                count++;
            }
        }
    }

    // Get a random number between two integers
    private getRandomNumber(min: number, max: number): number {
        
        min = Math.ceil(min);
        max = Math.floor(max);
        
        //The maximum is inclusive and the minimum is inclusive 
        return Math.floor(Math.random() * (max - min + 1) + min);   
    }
}
export class PlayerData {

    protected position: number = 0;
    public colorPosition: number = 0;

    // Player character and energy bars
    protected character: Phaser.Physics.Arcade.Sprite;
    protected energyContainer: Phaser.GameObjects.Sprite;
    protected energyBar: Phaser.GameObjects.Sprite;
    protected characterExplosion: Phaser.GameObjects.Particles.ParticleEmitterManager;
    protected characterHasExploded: boolean = false;
    protected readonly totalLives: number = 16;
    protected lifeDeduction: number = 0;

    //private myGame: GameScreen;
    public isDead: boolean = false;
    public removePlayer: boolean = false;

    public punchX: number = 0;
    public punchY: number = 0;
    public kickX: number = 0;
    public kickY: number = 0;

    constructor() {

    }
}
<!-- Display the name of the application -->
<div class="w3-container" style="margin-top: 10px;">
    <label style="font-size: 25px; color: rgba(125, 125, 125, 0.4);">Word Blitz Challenge</label>
</div>

<!-- Page description -->
<div class="w3-container" style="margin-top: 10px;">
    <label style="font-size: 16px; color: rgba(200, 60, 60, 0.6);">Create a new lobby or join an existing lobby.</label>
</div>

<div class="w3-display-middle">
    <div class="w3-container">

        <div class="w3-bar">
            <!-- Allow the user to create a new lobby -->
            <div routerLink="/word-blitz-create-lobby" class="w3-button w3-bar-item w3-blue w3-hover-light-blue w3-xxxlarge w3-round-large" style="margin-right: 40px; width: 200px; margin-bottom: 30px;">Create</div>

            <!-- Allow the user to join a new lobby -->
            <div routerLink="/word-blitz-join-lobby" class="w3-button w3-bar-item w3-green w3-hover-lime w3-xxxlarge w3-round-large" style="width: 200px;">Join</div>
        </div>
    </div>
</div>
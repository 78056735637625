import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';

// 2D Games
import { AbcFightSharedModule } from 'projects/games2d-abc-fight/src/app/app.module';
import { ColorFightSharedModule } from 'projects/games2d-color-fight/src/app/app.module';
import { MazeEscapeSharedModule } from 'projects/games2d-maze-escape/src/app/app.module';
import { PongSurvivorSharedModule } from 'projects/games2d-pong-survivor/src/app/app.module';
import { WordBlitzSharedModule } from 'projects/games2d-word-blitz/src/app/app.module';
import { HowManySharedModule } from 'projects/games2d-how-many/src/app/app.module';
import { AmesmiJeopardySharedModule } from 'projects/games2d-amesmi-jeopardy/src/app/app.module';
import { UsCitiesSharedModule } from 'projects/games2d-us-cities/src/app/app.module';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    ProjectsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AbcFightSharedModule.forRoot(),
    ColorFightSharedModule.forRoot(),
    MazeEscapeSharedModule.forRoot(),
    PongSurvivorSharedModule.forRoot(),
    WordBlitzSharedModule.forRoot(),
    HowManySharedModule.forRoot(),
    AmesmiJeopardySharedModule.forRoot(),
    UsCitiesSharedModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

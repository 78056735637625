import { Component, OnInit } from '@angular/core';
import { JeopardyService } from '../amesmi-jeopardy-home/jeopardy.service';
import { HostVideo } from './helper-code/host-video';

@Component({
  selector: 'app-amesmi-jeopardy-host-lobby',
  templateUrl: './amesmi-jeopardy-host-lobby.component.html',
  styleUrls: ['./amesmi-jeopardy-host-lobby.component.scss']
})
export class AmesmiJeopardyHostLobbyComponent implements OnInit {

  private hostVideo: HostVideo

  /**
   * List (object) of players in the lobby
   * {name: '', score: 0, isActive: false, attempted: false, isHost: false, id: ''}
   */ 
  activePlayers = [];

  /**
   * Hold the status of the lobby setup
   *
   * yes - setup properly
   * no - did not setup properly
   */ 
  lobbyActive: string = 'yes';

  // Error modal information
  //
  // errorMessage - describe the current error
  // errorModal - displays a modal containing the error message
  errorMessage: string = '';
  errorModal: HTMLDivElement;

  constructor(private service: JeopardyService) { 

    this.hostVideo = new HostVideo();
  }

  ngOnInit(): void {

    // The lobby was setup successfully 
    if(this.service.getLobbyStatus() == true) {

      this.lobbyActive = 'yes';

      // Store the player information
      this.activePlayers.push({
        name: this.service.getHostName(),
        score: 100000,
        isActive: false,
        attempted: false,
        isHost: this.service.getIsHost(),
        id: this.service.getHostID()
      });
    }

    // What not setup properly
    else {

      this.lobbyActive = 'no';
    }
  }

  ngAfterViewInit(): void {

    this.hostVideo.getCamera(this.activePlayers[0].id);
  }

  ngOnDestroy(): void {

  }

  public closeModal(): void {

  }
}

<!-- Create a new row -->
<div class="w3-panel">
    <div class="w3-row">

        <!-- Create a column -->
        <div class="w3-col m6 l6">

            <!-- Create an owner card -->
            <div class="w3-card-4 below-space" style="width:92%; max-width:300px;">
                <img src="assets/images/about/MDS.png" alt="Madden" style="width:100%; opacity:0.85">

                <div class="w3-container">
                    <h4><b>Madden</b></h4>
                    <p>Owner/Game Designer</p>
                </div>
            </div>
        </div>

        <!-- Create a column -->
        <div class="w3-col m6 l6">

            <!-- Create an owner card -->
            <div class="w3-card-4 test below-space" style="width:92%; max-width:300px;">
                <img src="assets/images/about/DIS.png" alt="Dallas" style="width:100%; opacity:0.85">

                <div class="w3-container">
                    <h4><b>Dallas</b></h4>
                    <p>Owner/Game Designer</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Large Screen Display -->
<!--<div class="w3-hide-small w3-hide-medium">
    <div class="w3-container w3-light-gray w3-border-orange w3-border-bottom w3-topbar w3-bar" style="padding: 5px;">

        <label class="w3-bar-item w3-left" style="font-size: 16px;">Public Lobbies Below</label>
    
        <div class="w3-button w3-bar-item w3-green w3-border w3-border-green w3-hover-light-green w3-hover-border-light-green w3-right w3-round-large" (click)="lobbyCodeJoin()">Join</div>
        <input id="lobby-code" class="w3-input w3-bar-item w3-white w3-right w3-border w3-border-blue w3-round-large" type="text" placeholder="Enter private code" style="width: 250px; margin-right: 10px;">
        <input id="user-name" class="w3-input w3-bar-item w3-white w3-right w3-border w3-border-blue w3-round-large" type="text" placeholder="Enter Your Name" style="width: 250px; margin-right: 10px;">
        <i class="w3-bar-item w3-right icofont icofont-login w3-text-red" title="Private Lobby" style="font-size: 25px;"></i>
    </div>
</div>-->

<!-- Small and Medium Screen Display -->
<!--<div class="w3-hide-large">-->
    <div class="w3-container w3-light-gray w3-border-orange w3-border-bottom w3-topbar" style="padding: 20px;">

        <div style="margin-bottom: 10px;">
            <label style="font-size: 16px;">Enter Private Lobby Information</label>
        </div>
        
        <!-- Allow the user to join a private lobby -->
        <div style="margin-bottom: 20px;">
            <input id="user-name" class="w3-input w3-white w3-border w3-border-blue w3-round-large" type="text" placeholder="Enter Your Name" style="width: 300px; margin-right: 10px;">
        </div>

        <div style="margin-bottom: 20px;">
            <input id="lobby-code" class="w3-input w3-white w3-border w3-border-blue w3-round-large" type="text" placeholder="Enter private code" style="width: 300px; margin-right: 10px;">
        </div>
        
        <div class="w3-button w3-green w3-border w3-border-green w3-hover-light-green w3-hover-border-light-green w3-round-large" (click)="lobbyCodeJoin()" style="margin-bottom: 30px;">Join</div>
        <!--<i class="icofont icofont-login w3-text-red" title="Private Lobby" style="font-size: 25px;"></i>-->

        <div>
            <label class="w3-text-blue-gray" style="font-size: 22px;">Public Lobbies Below</label>
        </div>
    </div>
<!--</div>-->

<!-- Display all the lobbies a user can join -->
<div class="w3-container">

    <!-- Display all the lobbies on the server -->
    <div class="w3-panel">
        <ul class="w3-ul">
            <li *ngFor="let lobby of lobbies" style="margin-bottom: 20px;">
                <div>
                    <label class="w3-text-gray" style="font-size: 18px;">Lobby Name: <label class="w3-text-brown">{{ lobby.lobbyName }}</label></label>
                </div>

                <div>
                    <label class="w3-text-gray" style="font-size: 15px;">Host Name: <label class="w3-text-brown">{{ lobby.hostName }}</label></label>
                </div>

                <div>
                    <label class="w3-text-orange" style="font-size: 15px; margin-right: 10px;">Date:</label>
                    <label class="w3-text-black" style="font-size: 15px; margin-right: 10px;">{{ lobby.date }}</label>
                    <label class="w3-text-black" style="font-size: 15px;">{{ lobby.time }}</label>
                </div>

                <div class="w3-bar">
                    <input id="{{ lobby.lobbyName }}" class="w3-input w3-bar-item w3-light-gray" type="text" placeholder="Enter Your Name" style="width: 250px" required>
                    <div class="w3-button w3-bar-item w3-blue w3-border-blue w3-hover-light-blue w3-hover-border-light-blue" (click)="joinLobby(lobby.lobbyID, lobby.lobbyName, lobby.hostID, lobby.hostName)">Join</div>
                </div>
            </li>
        </ul>
    </div>
</div>


<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>

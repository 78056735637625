<div class="w3-container">
    <h4 class="w3-text-gray" style="font-size: 30px; margin-top: 10px;">Create Lobby</h4>

    <!-- Instructions on how to create a lobby -->
    <div style="margin-bottom: 15px;">
        <label class="w3-text-black">Press <label class="w3-text-deep-orange">Create</label> to generate a code to send to the person you want to join the lobby.</label>
    </div>
    <div style="margin-bottom: 15px;">
        <label class="w3-text-black">Only <label class="w3-text-deep-orange">public</label> lobbies will appear at the bottom of the JOIN page.</label>
    </div>

    <hr />

    <!-------------------------------------------------------------------------------------------------------------------------------------------------------------->
    
    <div style="margin-bottom: 15px;">
        <label class="w3-text-gray" style="font-size: 18px;">Player (Host) Information</label>
    </div>

    <!-- Allow the user (HOST) to enter their name -->
    <div style="margin-bottom: 40px;">
        <input id="host-name" class="w3-input w3-border w3-border-green" type="text" placeholder="Enter your name" style="max-width: 350px;" required>
    </div>

    <div style="margin-bottom: 15px;">
        <label class="w3-text-gray" style="font-size: 18px;">Minimum Amount of Players</label>
    </div>

    <!-- Allow the user (HOST) to enter the total amount of people in the lobby -->
    <div style="margin-bottom: 40px;">
        <input id="number-of-players" class="w3-input w3-border w3-border-green" type="text" placeholder="Minimum Players" style="max-width: 350px;" required>
    </div>

    <!-- Allow the user to make the lobby public or private -->
    <label class="w3-text-gray">Lobby Status</label>
    <div class="w3-bar" style="margin-bottom: 5px;">
        <div class="w3-bar-item">
            <input class="w3-radio" type="radio" name="display-status" value="public" checked>
            <label class="w3-text-blue-gray" style="margin-left: 5px;">Public</label>
        </div>

        <div class="w3-bar-item">
            <input class="w3-radio" type="radio" name="display-status" value="private">
            <label class="w3-text-blue-gray" style="margin-left: 5px;">Private</label>
        </div>
    </div>

    <hr />

    <!------------------------------------------------------------------------------------------------------------------------------------------------------------>

    <h3 class="w3-text-blue-gray">Select Jeopardy Categories</h3>

    <!-- Allow the user to make the lobby public or private -->
    <div class="w3-bar w3-border w3-round-medium w3-border-blue-gray" style="margin-bottom: 30px; max-width: 350px;">
        <label class="w3-bar-item w3-text-deep-orange" style="font-size: 20px;">Play:</label>
        <div class="w3-bar-item">
            <input class="w3-radio" type="radio" name="type-of-play" value="teens" (click)="getCategories()">
            <label class="w3-text-blue-gray" style="margin-left: 5px;">Teens</label>
        </div>

        <div class="w3-bar-item">
            <input class="w3-radio" type="radio" name="type-of-play" value="regular" checked (click)="getCategories()">
            <label class="w3-text-blue-gray" style="margin-left: 5px;">Regular</label>
        </div>
    </div>
    
    <!-- First Round Selections -->
    <div style="margin-bottom: 15px;">
        <label class="w3-text-gray" style="font-size: 18px;">First Round:</label>

        <!-- Selection for the first round -->
        <div class="w3-bar">
            <div *ngFor="let category of firstRound" id="{{ category }}" class="w3-bar-item w3-button w3-teal w3-hover-light-gray w3-round-medium" style="margin-right: 5px; margin-bottom: 5px;" (click)="removeCategory(category, 'one')">
                {{ category }}
            </div>
        </div>
    </div>

    <!-- Second Round Selections -->
    <div style="margin-bottom: 15px;">
        <label class="w3-text-gray" style="font-size: 18px;">Second Round:</label>

        <!-- Selection for the second round -->
        <div class="w3-bar">
            <div *ngFor="let category of secondRound" id="{{ category }}" class="w3-bar-item w3-button w3-indigo w3-hover-light-gray w3-round-medium" style="margin-right: 5px; margin-bottom: 5px;" (click)="removeCategory(category, 'two')">
                {{ category }}
            </div>
        </div>
    </div>

    <!-- Final Round Selection -->
    <div style="margin-bottom: 40px;">
        <label class="w3-text-gray" style="font-size: 18px;">Final Round:</label>

        <!-- Selection for the final round -->
        <div class="w3-bar">
            <div *ngFor="let category of finalRound" id="{{ category }}" class="w3-bar-item w3-button w3-purple w3-hover-light-gray w3-round-medium" style="margin-right: 5px; margin-bottom: 5px;" (click)="removeCategory(category, 'final')">
                {{ category }}
            </div>
        </div>
    </div>

    <!-- Show the list of available categories for the HOST to select from -->
    <div class="w3-text-blue-gray" style="font-size: 20px;">Available Categories <label class="w3-text-gray" style="font-size: 14px;">(select)</label></div>
    <select class="w3-select w3-border w3-round-medium" name="category" size="6" style="max-width: 350px;">
        <option *ngFor="let category of categories" value="{{ category }}" (click)="addCategory(category)">{{ category }}</option>
    </select>

    <hr />

    <!------------------------------------------------------------------------------------------------------------------------------------------------------------>

    <!-- Create a new lobby by pressing the button -->
    <div style="margin-bottom: 15px;">
        <button class="w3-button w3-round w3-blue w3-border-blue w3-hover-light-blue w3-hover-border-light-blue" (click)="createLobby()">Create</button>
    </div>
</div>

<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>
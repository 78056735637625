import * as Phaser from 'phaser';
import { GameScreen } from '../../screens/gameScreen';
import { EnemyControls } from './enemy-controls';

export class Enemy extends EnemyControls {

    constructor(characterPosition: number, enemyPosition: number) {

        super();

        // Starting position for each color
        this.position = characterPosition * 7;
        this.colorPosition = characterPosition;

        this.enemyWalkingLeft = 'enemyWalkingLeft' + enemyPosition.toString();
        this.enemyWalkingRight = 'enemyWalkingRight' + enemyPosition.toString();
        this.enemyStanding = 'enemyStanding' + enemyPosition.toString();
    }

    /**
     * Create an enemy that is controlled by the game
     * 
     * @param scene 
     */
    public create(scene: GameScreen): void {

        this.createControls(scene);

        let ground: number = 215;

        // Create animation from a spritesheet
        scene.anims.create({key: this.enemyWalkingLeft, frameRate: 5, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position, this.position + 6] }), repeat: -1});
        scene.anims.create({key: this.enemyWalkingRight, frameRate: 5, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position, this.position + 5] }), repeat: -1});
        scene.anims.create({key: this.enemyStanding, frameRate: 0, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position] }), repeat: -1});
        
        // Generate a random x position for the enemy character
        let x = this.getRandomNumber(250, this.myGame.screenWidth - 50);
        this.character = scene.physics.add.sprite(x, ground, 'fighters', this.position).setOrigin(0, 0);
        this.character.setVisible(true);

        // Do not allow the player to move past the game walls
        this.character.setGravityY(300);
        this.character.setBounce(0.2);
        this.character.setCollideWorldBounds(true);

        // Create the energy bar for the player
        this.energyBar = scene.add.sprite(0, 0, 'energybar', this.colorPosition);
        this.energyBar.setOrigin(0, 0);
        this.energyBar.setVisible(true);

        this.energyContainer = scene.add.sprite(0, 0, 'energybar', 6);
        this.energyContainer.setOrigin(0, 0);
        this.energyContainer.setVisible(true);

        // Create a particle system
        this.characterHasExploded = false;
        this.characterExplosion = scene.add.particles('explosion');
        this.characterExplosion.createEmitter({
            frame: this.colorPosition,
            angle: {min: 240, max: 300},
            speed: {min: 200, max: 400},
            quantity: 30,
            lifespan: 2000,
            alpha: {start: 1, end: 0},
            scale: {min: 0.1, max: 0.5},
            gravityY: 800,
            on: false,
            name: 'james'
        });
        
        // Calculate the amount of life to deduct each time the player is hit
        this.lifeDeduction = this.energyBar.displayWidth / this.totalLives;
    }

    public update(playerX: number): void {

        // The game is not over so allow the player to continue to move
        // their character
        if(this.myGame.isGameOver == false && this.isDead == false) {

            // Calculate the distance between the player and this enemy
            //let distance: number = this.player.getPlayer().x - this.character.x;
            let distance: number = playerX - this.character.x;

            // Move the enemy base on the player position
            let isFighting = this.movement(distance);

            // Start fighting the player
            if(isFighting) {

                this.startFighting();
            }

            // Move the energy bar base on the player position (should be above the player)
            let y = this.character.y - 15;

            this.energyBar.x = this.character.x;
            this.energyBar.y = y;
            this.energyContainer.x = this.character.x;
            this.energyContainer.y = y;
        }

        // The game is over
        else {

            // Remove the enemy from the game
            if(this.removeEnemy == false) {

                // Before removing, make sure there are no more explosion
                // particles alive on the board
                if(this.characterExplosion.emitters.getByName('james').getAliveParticleCount() <= 0) {

                    this.removeEnemy = true;

                    // Destroy this particle system for this enemy
                    this.characterExplosion.destroy();
                }
            } 
        }
    }

    /**
     * Remove the Enemy character from the game
     */
    public destroy(): void {

        this.character.destroy();
        this.energyBar.destroy();
        this.energyContainer.destroy();
        this.myGame.anims.remove(this.enemyWalkingLeft);
        this.myGame.anims.remove(this.enemyWalkingRight);
        this.myGame.anims.remove(this.enemyStanding);
    }

    /**
     * Test the collision between the player throwing a fighting move
     * and the enemy
     * 
     * @param playerX 
     * @param playerY 
     */
    public testCollision(playerX: number, playerY: number): void {

        // Collision box
        let x: number = this.character.x + 20;
        let y: number = this.character.y + 33;
        let w: number = 39;
        let h: number = 82;

        // within the
        let collisionX: boolean = ((playerX >= x) && (playerX <= (x + w)));
        let collisionY: boolean = ((playerY >= y) && (playerY <= (y + h)));

        // The enemy is hitting the player
        if(collisionX == true && collisionY == true) {

            // Reduce the player life reduction
            this.enemyLifeReduction();

            // The player has no more energy so the game is over
            if(this.energyBar.displayWidth === 0) {

                // The character is exploding and it's
                // visibility is turned off
                this.enemyExploding();

                // The enemy is dead
                this.isDead = true;
            }
        }
    }

    /**
     * The enemy energy bar is reduce each time they get hit by a game object
     */
    private enemyLifeReduction(): void {

        // Reduce the player life each time they are hit
        this.energyBar.displayWidth -= this.lifeDeduction;
    }

    /**
     * Display the enemy exploding after losing all of their energy
     */
    private enemyExploding(): void {

        // Allow the enemy to explode only 1 time
        if(this.characterHasExploded == false) {

            this.characterExplosion.emitParticleAt(this.character.x + this.character.width / 2, this.character.y + this.character.height / 2);
            
            this.characterHasExploded = true;
        }
    }

    /**
     * Return the player character
     */
    public getEnemy(): Phaser.Physics.Arcade.Sprite {

        return this.character;
    }
}
import * as Phaser from 'phaser';

import { SubmitButton } from '../buttons/submit-button';
import { ClearButton } from '../buttons/clear-button';
import { WordsButton } from '../buttons/words-button';
import { RemoveLetterButtons } from '../buttons/remove-letter-buttons';
import { GameGrid } from '../object/game-grid';
import { GameLetters } from '../object/game-letters';
import { GamePlayerControls } from '../player/game-player-controls';
import { Player } from '../player/player';
import { Router } from '@angular/router';
import { Peer } from '../../peer/peer';
import { WordBlitzLobbyService } from '../../word-blitz-lobby.service';
import { PeerEvent } from '../../peer/peerEventDispatcher';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScreen'
}

export class GameScreen extends Phaser.Scene {

    private gameboard: Phaser.GameObjects.Sprite;
    private gameboardGrid: Phaser.GameObjects.Sprite;
    private gameoverDisplay: Phaser.GameObjects.Sprite;
    private youWonGameOver: Phaser.GameObjects.Sprite;
    private youLostGameOver: Phaser.GameObjects.Sprite;
    private opponentBoard: Phaser.GameObjects.Sprite;

    private statButton: Phaser.GameObjects.Sprite;
    private submitButton: SubmitButton;
    private clearButton: ClearButton;
    private wordsButton: WordsButton;
    private removeLetterButtons: RemoveLetterButtons;
    private gameGrid: GameGrid;
    private gameLetters: GameLetters;
    private gamePlayerControls: GamePlayerControls;
    private player: Player;

    private isGameOver: boolean = false;

    private fallingLetters = [];

    private readonly VISIBLE_ROW_BEGINNING: number  = 7;
    private readonly NUMBER_OF_ROWS: number = 20;
    private readonly NUMBER_OF_COLS: number = 9;

    private checkColumnCurrentTime: number = 0;
    private checkColumnStartTime: number = 0;

    constructor(private route: Router, private peer: Peer, private service: WordBlitzLobbyService) {

        super(sceneConfig);

        this.submitButton = new SubmitButton();
        this.clearButton = new ClearButton();
        this.wordsButton = new WordsButton();
        this.removeLetterButtons = new RemoveLetterButtons();
        this.gameGrid = new GameGrid();
        this.gameLetters = new GameLetters(this.fallingLetters, this.gameGrid);
        this.gamePlayerControls = new GamePlayerControls(this.fallingLetters, this.gameGrid);
        this.player = new Player(this.fallingLetters, this.gameGrid);

        this.eventListeners();
    }

    public init(data: any): void {

    }

    public preload(): void {

        this.load.setBaseURL('assets/images/projects/games2D/word-blitz');

        // Load the Word List
        this.load.text('dictionary', 'files/words_alpha.txt');

        // Load all images
        this.load.image('gameboard', 'single-player-game/gameboard.png');
        this.load.image('grid', 'single-player-game/grid.png');
        this.load.image('word_list', 'online-challenge/challenge_word_list.png');
        //this.load.image('game_over', 'single-player-game/game_over.png');
        this.load.image('you_won', 'online-challenge/you_won_game_over.png');
        this.load.image('you_lost', 'online-challenge/you_lost_game_over.png');

        // Load all spritesheets
        this.load.spritesheet('letters', 'single-player-game/letters.png', {frameWidth: 80, frameHeight: 80});
        this.load.spritesheet('buttons', 'single-player-game/gameboardButtons.png', {frameWidth: 162, frameHeight: 59});
        this.load.spritesheet('remove_buttons', 'single-player-game/remove_letters_buttons_update.png', {frameWidth: 60, frameHeight: 60});
        this.load.spritesheet('opponent_column_size', 'online-challenge/opponent_column.png', {frameWidth: 444, frameHeight: 60});
    }

    public create(): void {

        // Load the game board
        this.gameboard = this.add.sprite(0, 0, 'gameboard');
        this.gameboard.setOrigin(0, 0);

        this.gameboardGrid = this.add.sprite(0, 80, 'grid');
        this.gameboardGrid.setOrigin(0, 0);
        this.gameboardGrid.setDepth(1);

        this.opponentBoard = this.add.sprite(10, 10, 'opponent_column_size', 0);
        this.opponentBoard.setOrigin(0, 0);
        this.opponentBoard.setDepth(1);

        // Create a new grid for the game
        this.gameGrid.create();

        // Add the initial letters to the gameboard
        this.gameLetters.addInitialLetters(this.add);

        // Create the controls that allows the player to select a letter
        // and drag across the screen
        this.gamePlayerControls.create(this.input);

        // Create the gameboard buttons
        this.submitButton.create(this.add);
        this.clearButton.create(this.add);
        this.wordsButton.create(this.add);
        this.removeLetterButtons.create(this.add);

        // Create the player for the game
        this.player.create(this.add, this.cache);

        this.checkColumnStartTime = new Date().getTime();
    }

    public update(): void {

        // Allow the player to play the game
        if(this.isGameOver == false) {

            // Make sure everything is consistent with time
            // across the game
            let currentTime = new Date().getTime();

            this.gameLetters.update(currentTime);

            // Check the maximum height of letters on the gameboarc
            this.determineMaximumHeight(currentTime);
        }
    }

    public delete(): void {

        this.gameboard.destroy();
        this.gameboardGrid.destroy();
        //this.gameoverDisplay.destroy();

        if(this.youWonGameOver != null && this.youWonGameOver != undefined) {

            this.youWonGameOver.destroy();
        }
        
        if(this.youLostGameOver != null && this.youLostGameOver != undefined) {

            this.youLostGameOver.destroy();
        }
        
        this.submitButton.destroy();
        this.clearButton.destroy();
        this.wordsButton.destroy();
        this.removeLetterButtons.destroy();
        this.statButton.destroy();

        this.gameGrid.delete();

        this.player.delete();
    }

    /**
     * Since the game is over. All buttons are inactive
     * that are related to the game
     */
     private deactivatePlayerControls(): void {

        // Do NOT allow the player to click a letter
        // and drag a letter
        //this.input.enabled = false;
        this.input.off('pointerdown');
        this.input.off('pointerup');
        this.input.off('pointerout');
        this.input.off('pointermove');

        // Make all buttons inactive
        this.clearButton.disable();
        this.removeLetterButtons.disable();
        this.submitButton.disable();
        this.wordsButton.disable();

        // If the word list is on then turn it off
        this.player.turnOffWordList();
    }

    /**
     * Display gameover information
     */
     private displayGameoverInformation(win_lost_status: string): void {

        this.gameoverDisplay = this.add.sprite(0, 80, win_lost_status);
        this.gameoverDisplay.setOrigin(0, 0);
        this.gameoverDisplay.setDepth(1);

        this.statButton = this.add.sprite(279, 1206, 'buttons', 7);
        this.statButton.setOrigin(0, 0);
        this.statButton.setDepth(1);
        this.statButton.setInteractive();

        // Get the player information (stats) and store
        // the information for later use
        //this.service.setPlayerScore(this.player.getScore());
        //this.service.setPlayerWords(this.player.getWords());
        //this.service.setTotalWords(this.player.getTotalWords());

        this.service.setMyWordList(this.player.getMyWords());
        this.service.setOpponentWordList(this.player.getOpponentWords());

        this.statButton.on('pointerdown', () => {

            // Change the button color
            this.statButton.setFrame(15);
        });

        this.statButton.on('pointerup', () => {

            // Change the button back to its original color
            this.statButton.setFrame(7);
            
            // Send the player to the single player stats page
            //this.route.navigateByUrl('/word-blitz-challenge-stats');
            this.peer.dispatchEvent(new PeerEvent('display-stats', ''));
        });
    }

    // Deter
    private determineMaximumHeight(currentTime: number): void {

        this.checkColumnCurrentTime = currentTime - this.checkColumnStartTime;

        if(this.checkColumnCurrentTime >= 1000) {

            let maximumHeight: number = 0;
        
            for(let col = 0; col < this.NUMBER_OF_COLS; col++) {

                let total: number = 0;

                for(let row = this.VISIBLE_ROW_BEGINNING; row < this.NUMBER_OF_ROWS; row++) {

                    // If the column is occupied
                    if(this.gameGrid.grid[row][col].occupied === 1) {

                        total += 1;
                    }
                }

                if(total > maximumHeight) {

                    maximumHeight = total;
                }
            }

            // Reset the time
            this.checkColumnCurrentTime = 0;
            this.checkColumnStartTime = new Date().getTime();

            // Send the update to the opponent
            let object = {
                action: 'opponent-board',
                columnHeight: maximumHeight
            }

            this.peer.sendMessageToOpponent(JSON.stringify(object));
        }
    }

    /**
     * All the event listner for the current game
     */
    private eventListeners(): void {
        
        // Submit the spelled word to the dictionary
        this.submitButton.addEventListener('submit-button-pressed', (event) => {

            this.player.submitWord();
        });

        // Remove the spelled word letters from the gameboard
        // and place them back on the player gameboard
        this.clearButton.addEventListener('clear-button-pressed', (event) => {

            this.player.clearWord();
        });

        // Display all the words the player has spelled correctly
        this.wordsButton.addEventListener('words-button-pressed', (event) => {

            this.player.wordsDisplay();
        });

        // Remove the percentage amount of letters from the game
        this.removeLetterButtons.addEventListener('remove-letters', (event) => {

            // Percentage amount of letters to be remove from the gameboard
            let percentage: number = Number.parseFloat(event.message);

            // Get the total number of letters on the board
            let count: number = this.fallingLetters.length;

            // Calculate the total amount of letters to remove
            let total: number = Math.round(count * percentage);

            // Remove the letters from the gameboard
            for(let i = total - 1; i >= 0; i--) {

                // Inform the grid that the space is available
                let row = this.fallingLetters[i].row;
                let col = this.fallingLetters[i].col;

                // Remove the letter from the list
                this.fallingLetters[i].sprite.setVisible(false);
                this.fallingLetters[i].sprite.destroy();
                this.fallingLetters.splice(i, 1);

                this.gameGrid.grid[row][col].occupied = 0;
            }
        });

        // A column is full so the game is over
        this.gameGrid.addEventListener('is-game-over', (event) => {

            // The game is over
            if(event.message === 'true') {

                this.isGameOver = true;

                this.deactivatePlayerControls();
                this.displayGameoverInformation('you_lost');

                
                //Inform opponent that they won the game
                let object = {
                    action: 'you_won'
                }

                this.peer.sendMessageToOpponent(JSON.stringify(object));
            }
        });

        // The player has selected a letter in order to spell a word
        this.gamePlayerControls.addEventListener('add-letter-to-word', (event) => {

            let data = JSON.parse(event.message);

            // Add the letter to the player word
            this.player.addLetter(data.letter);
            this.player.addPotentialPoints(data.pointValue);
            this.player.storeLetterPosition(data.letterPosition);
        });

        // Check the opponent word list
        this.player.addEventListener('check-opponent-word-list', (event) => {

            let data = JSON.parse(event.message);

            // Check the opponent word list
            let object = {
                action: 'check-word-list',
                word: data.word,
                letterPosition: data.letterPosition
            }

            this.peer.sendMessageToOpponent(JSON.stringify(object));
        });

        // Incoming messages from the other opponent
        this.peer.addEventListener('datachannel-message', (event) => {

            // Incoming message from the opponent
            let data = JSON.parse(event.message);

            // See if the word is not in the opponent word list
            if(data.action === 'check-word-list') {

                // The word is not in the word list
                if(this.player.isInWordList(data.word) == true) {

                    // Store the word in the game word list
                    this.player.storeWord(data.word);

                    // Store the word for the opponent word list
                    this.player.addWordToOpponentList(data.word);

                    // 6 - purple letter
                    // 5 - white letter
                    this.player.storeOpponentLettersOnBoard(6, 5, data.letterPosition);

                    // Inform the opponent to store the word in their list
                    let object = {
                        action: 'save-word',
                        word: data.word
                    }

                    this.peer.sendMessageToOpponent(JSON.stringify(object));
                }

                // The word is in the word list
                else {

                    let object = {
                        action: 'place-letter-back-on-gameboard',
                        letterPosition: data.letterPosition
                    }

                    this.peer.sendMessageToOpponent(JSON.stringify(object));
                }
            }

            else if(data.action === 'save-word') {

                // Store the word in the game word list
                this.player.storeWord(data.word);

                // Store the word in my word list
                this.player.addWordToMyList(data.word);
            }

            else if(data.action === 'place-letter-back-on-gameboard') {

                // 3 - light brown letters
                // 5 - white letters
                this.player.storeOpponentLettersOnBoard(3, 5, data.letterPosition);
            }

            else if(data.action === 'you_won') {

                this.isGameOver = true;

                this.opponentBoard.setFrame(12);
                this.deactivatePlayerControls();
                this.displayGameoverInformation('you_won');

                // Close WebRTC connection
                this.peer.close();
            }

            else if(data.action === 'opponent-board') {

                let columnHeight = data.columnHeight - 1;

                this.opponentBoard.setFrame(columnHeight);
            }
        });
    }
}
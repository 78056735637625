import { Sprite } from '../objects/sprite';
import { Animation } from '../objects/animation';
import { Bullet } from '../bullet';

export class Enemy {

    /**
     * How many hits it take to destroy
     * the enemy is the amount of points
     * the player receive
     */
    private pointValue: number = 0;
    private lives: number = 0;
    private sprite: Sprite;

    /**
     * Represent the bullets of the enemy
     */
    private myBullet: Bullet;

    private readonly LeftWall: number = 400;
    private readonly RightWall: number = 800;
    private readonly TopWall: number = 37;
    private readonly BottomWall: number = 465;
    private readonly BULLETSPEED: number = -0.3;

    private bulletStartTime: number = 0;
    private bulletFireTime: number = 0;

    private isDead: boolean = false;

    constructor() {

    }

    /**
     * Create a new enemy
     * 
     * @param add 
     */
    public create(add: Phaser.GameObjects.GameObjectFactory, letterPosition: number): void {

        // Determine the amount of lives for the following enemy
        this.lives = letterPosition + 1;
        this.pointValue = this.lives;

        // Create a random position along the x-coordinate
        let x = this.randomInt(500, 750);
        let y = this.randomInt(40, 450);

        // Create a random speed
        let dx = this.getRandomNumber(-0.15, 0.15); //this.randomInt(0, 1) - 0.5;
        let dy = this.getRandomNumber(-0.15, 0.15); //this.randomInt(0, 1) - 0.5;

        // Enemy letter
        let enemyLetter = add.image(x, y, 'game_letters', letterPosition);
        enemyLetter.setOrigin(0, 0);
        enemyLetter.setVisible(false);

        // Create the animation
        let anim = new Animation();
        anim.addFrame(enemyLetter, 0);

        // Create a sprite for the current letter
        this.sprite = new Sprite(anim);
        this.sprite.setX(x);
        this.sprite.setY(y);
        this.sprite.setVelocityX(dx);
        this.sprite.setVelocityY(dy);

        // Create the bullet for this letter
        this.myBullet = new Bullet(letterPosition);
        this.myBullet.create(add);

        // Set the start time
        // Used to determine when to fire a bullet
        this.bulletStartTime = new Date().getTime();

        // Create a random fire time
        this.bulletFireTime = this.getRandomNumber(1500, 4000);
    }

    /**
     * Update the enemy movement
     * 
     * @param elapsedTime 
     */
    public update(elapsedTime: number): void {

        let currentTime = new Date().getTime() - this.bulletStartTime;

        // Allow the enemy to fire a bullet between the give times
        if(currentTime >= this.bulletFireTime) {

            // Fire bullet at player
            this.myBullet.fire(this.sprite.getX(), this.sprite.getY() + this.sprite.getHeight() / 2, this.BULLETSPEED);

            // Reset the bullet start time in order to fire another bullet
            this.bulletStartTime = new Date().getTime();
        }

        // Draw the player bullet
        this.myBullet.update(elapsedTime);

        // Move the enemy to the next position
        this.moveEnemy(elapsedTime);

        // Draw the enemy to the screen
        this.drawEnemy();
    }

    /**
     * 
     * @param elapsedTime 
     */
    private moveEnemy(elapsedTime: number): void {

        if(this.sprite.getX() < this.LeftWall) {

            // Move the enemy in the opposite direction
            this.sprite.setVelocityX(Math.abs(this.sprite.getVelocityX()));
        }

        else if(this.sprite.getX() + this.sprite.getWidth() >= this.RightWall) {

            // Move the enemy in the opposite direction
            this.sprite.setVelocityX(-Math.abs(this.sprite.getVelocityX()));
        }

        if(this.sprite.getY() <= this.TopWall) {

            this.sprite.setVelocityY(Math.abs(this.sprite.getVelocityY()));
        }

        else if(this.sprite.getY() + this.sprite.getHeight() >= this.BottomWall) {

            this.sprite.setVelocityY(-Math.abs(this.sprite.getVelocityY()));
        }

        // Update the sprite position
        this.sprite.update(elapsedTime);
    }

    /**
     * Draw the enemy to the screen
     */
    private drawEnemy(): void {

        let letter = this.sprite.getImage();
        letter.setX(Math.round(this.sprite.getX()));
        letter.setY(Math.round(this.sprite.getY()));
    }

    /**
     * Return a random number between two numbers
     */
    private randomInt(min, max): number {

        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**
     * Return a random number between 2 numbers
     * 
     * @param min 
     * @param max 
     */
    private getRandomNumber(min: number, max: number): number {

        return Math.random() * (max - min) + min;
    }

    /**
     * Return a list of bullets
     */
    public getBullets(): Sprite[] {

        return this.myBullet.getBullets();
    }

    /**
     * Return this enemy
     */
    public getSprite(): Sprite {

        return this.sprite;
    }

    /**
     * Destroy resources
     */
    public destroy(): void {

        this.sprite.getImage().destroy();
        this.myBullet.destroy();
    }

    /**
     * The enemy has been hit
     */
    public isHit(): void {

        // Reduce by one
        this.lives -= 1;

        // Enemy is dead. End the 
        if(this.lives === 0) {

            this.isDead = true;
        }
    }

    /**
     * The enemy is dead
     */
    public enemyIsDead(): boolean {

        return this.isDead;
    }

    /**
     * Return the enemy point 
     */
    public getPoints(): number {

        return this.pointValue;
    }
}
import { Animation } from './animation';

export class Sprite {

    private animation: Animation;
    
    // Position in pixels
    private x: number;
    private y: number;

    // Velocity (pixels per milliseconds)
    private dx: number;
    private dy: number;

    /**
     * If the ball hit the right wall then
     * it is coming back at the player.
     * 
     * Allow the player to update their score
     */
    public hitRightWall: boolean = true;
    
    constructor(animation: Animation) {

        this.animation = animation;
    }

    /**
     * Update the sprite's animation and its position based
     * on the velocity
     */
    public update(elapsedTime: number): void {

        this.x += this.dx * elapsedTime;
        this.y += this.dy * elapsedTime;

        this.animation.update(elapsedTime);
    }

    /**
     * Get this sprite's current x position
     */
    public getX(): number {

        return this.x;
    }

    /**
     * Get this sprite's current y position
     */
    public getY(): number {

        return this.y;
    }

    /**
     * Sets this sprite's current x position
     */
    public setX(x: number): void {

        this.x = x;
    }

    /**
     * Set this sprite's current y position
     */
    public setY(y: number): void {

        this.y = y;
    }

    /**
     * Gets this sprite's width, based on the size of the
     * current image
     */
    public getWidth(): number {

        return this.animation.getImage().width;
    }

    /**
     * Gets this sprite's height, based on the size of the
     * current image
     */
    public getHeight(): number {

        return this.animation.getImage().height;
    }

    /**
     * Gets the horizontal velocity of this Sprite in pixels
     * per millisecond.
     */
    public getVelocityX(): number {

        return this.dx;
    }

    /**
     * Gets this vertical velocity of this Sprite in pixels
     * per milliseconds.
     */
    public getVelocityY(): number {

        return this.dy;
    }

    /**
     * Set the horizontal velocity of this Sprite in pixels
     * per milliseconds
     */
    public setVelocityX(dx: number): void {

        this.dx = dx;
    }

    /**
     * Sets the vertical velocity of this Sprite in pixels
     * per milliseconds
     */
    public setVelocityY(dy: number): void {

        this.dy = dy;
    }

    /**
     * Get this Sprite's current image
     */
    public getImage(): Phaser.GameObjects.Image {

        return this.animation.getImage();
    }
}
import { Router } from '@angular/router';
import * as Phaser from 'phaser';
import { LevelsService } from '../levels.service';
import { GameScreen } from './screens/game-screen';

export class Game {

    private game: Phaser.Game;
    private gameConfig: Phaser.Types.Core.GameConfig;
    private readonly backgroundColor: string = 'rgb(0, 0, 0)';

    private gameScreen: GameScreen;

    constructor(private route: Router, private service: LevelsService) {

    }

    public init(element: string, GAME_WIDTH: number, GAME_HEIGHT: number): void {

        this.gameScreen = new GameScreen(this.route, this.service);

        this.gameConfig = {

            type: Phaser.AUTO,

            // Screen size
            width: GAME_WIDTH,
            height: GAME_HEIGHT,

            // Set the farme per second
            fps: {
                target: 30,
                forceSetTimeOut: true
            },

            // DIV element
            parent: element,

            // Set background color
            backgroundColor: this.backgroundColor,

            scene: [this.gameScreen],

            scale: {
                mode: Phaser.Scale.ScaleModes.FIT
            }
        }

        this.game = new Phaser.Game(this.gameConfig);
    }

    public delete(): void {

        this.gameScreen.delete();
        this.game.destroy(false, false);
    }
}
import { Enemy } from './enemy';
import { Player } from '../player';

export class Enemies {

    /**
     * Represent the letter the player has selected
     */
    private playerLetter: number;

    private enemy: Enemy[] = [];
    private add: Phaser.GameObjects.GameObjectFactory;

    constructor() {

    }

    /**
     * Inform the enemy of the letter that the player has selected.
     * Give the enemy access to the letters and letter colors of the game.
     * 
     * @param playerLetter 
     * @param letters 
     * @param letterColors 
     */
    public init(playerLetter: number): void {

        this.playerLetter = playerLetter;
    }

    /**
     * Create a bunch of enemies for the game
     * 
     * @param add 
     */
    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.add = add;
    }

    /**
     * Move the enemies around the screen
     * 
     * @param elapsedTime 
     */
    public update(elapsedTime: number): void {

        // Update each enemy position
        for(let i = 0; i < this.enemy.length; i++) {

            this.enemy[i].update(elapsedTime);
        }
    }

    /**
     * Remove all reference from the game
     */
    public destroy(): void {

        for(let i = 0; i < this.enemy.length; i++) {

            this.enemy[i].destroy();
        }
    }

    /**
     * Determine how many enemies to draw for the current level
     * 
     * @param currentLevel 
     */
    public createEnemiesForLevel(currentLevel: number): void {

        let totalLetters: number = 0;
        let range = {min: 0, max: 0};
        
        if(currentLevel === 1) {

            totalLetters = 8;
            range.min = 0;
            range.max = 5;
        }

        else if(currentLevel === 2) {

            totalLetters = 15;
            range.min = 0;
            range.max = 10;
        }

        else if(currentLevel === 3) {

            totalLetters = 20;
            range.min = 0;
            range.max = 15;
        }

        else if(currentLevel === 4) {

            totalLetters = 25;
            range.min = 0;
            range.max = 20;
        }

        else if(currentLevel === 5) {

            totalLetters = 30;
            range.min = 0;
            range.max = 25;
        }

        this.createEnemy(totalLetters, range);
    }

    /**
     * Create the enemies for the current level
     * 
     * @param totalLetters 
     * @param range 
     */
    private createEnemy(totalLetters: number, range: any): void {

        // Loop until all the enemies has been created
        while(this.enemy.length < totalLetters) {

            // Get the letter position in the list
            let position = this.randomInt(range.min, range.max);

            // Player letter does not equal the random letter generated
            if(this.playerLetter !== position) {

                // Create a new enemy for this level
                let currentEnemy = new Enemy();
                currentEnemy.create(this.add, position);
            
                // Add the new enemy to the list
                this.enemy.push(currentEnemy);
            }
        }
    }

    /**
     * Get the enemies for this level
     */
    public getEnemies(): Enemy[] {

        return this.enemy;
    }

    /**
     * Return a random number between two numbers
     */
    private randomInt(min, max): number {

        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
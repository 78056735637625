import { PeerEvent, PeerEventDispatcher } from "./peerEventDispatcher";

export class Peer extends PeerEventDispatcher {

    private websocket: WebSocket;
    private readonly SOCKET_URL: string = 'wss://amesmi.openode.dev'; //'wss://amesmi.openode.io';  //'ws://localhost:3000';

    private peerConnection: RTCPeerConnection;
    private dataChannel: RTCDataChannel;
    private otherPeerID: string = '';

    private readonly pcConfig = {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]};

    constructor(myID: string, private lobbyName: string) {
        
        super();

        this.websocket = new WebSocket(this.SOCKET_URL, ['protocolOne', 'protocolTwo']);

        // The socket is open and can start receiving messages from the server
        this.websocket.onopen = () => {

            //console.log('Successfully connected to the server');

            let object = {
                application: 'word-blitz',
                action: 'add-socket-to-lobby',
                playerID: myID,
                lobbyName: lobbyName
            }

            this.sendMessageToServer(JSON.stringify(object));

            this.incomingMessages();
        }
    }

    /**
     * The Player is sending a message to the server
     * 
     * @param message 
     */
    private sendMessageToServer(message: string): void {

        //console.log('Sending message to server');
        //console.log(message);

        switch(this.websocket.readyState) {

            case WebSocket.CLOSED:
                //console.log('Socket is closed');
                break;

            case WebSocket.CLOSING:
                //console.log('Socket is closing');
                break;

            case WebSocket.CONNECTING:
                //console.log('Socket is connecting');
                break;

            case WebSocket.OPEN:

                // Sending message to server
                this.websocket.send(message)

                break;
        }
    }

    /**
     * Process all incoming messages from the server
     */
    private incomingMessages(): void {

        this.websocket.onmessage = (event) => {

            // Get the information from the server
            let message = JSON.parse(event.data);

            if(message.action === 'host-successfully-added') {

                //console.log('Successfully added HOST Socket information');
            }

            else if(message.action === 'add-guest-id-to-host') {

                let guestID = message.guestID;
                let guestName = message.guestName;

                let object = {
                    guestID: guestID,
                    guestName: guestName
                }

                // Add the guest information to the host
                this.dispatchEvent(new PeerEvent('add-guest-id', JSON.stringify(object)));

                // Store the Guest ID as the other peer ID
                this.otherPeerID = guestID;

                // Create the peer for the host
                this.createPeer();
            }

            else if(message.action === 'guest-successfully-added') {

                this.dispatchEvent(new PeerEvent('start-connection-to-host', ''));
            }


            else if(message.type === 'offer') {

                this.setRemoteDescription(new RTCSessionDescription(message.offer));

                this.createAnswer();
            }

            else if(message.type === 'answer') {

                this.setRemoteDescription(new RTCSessionDescription(message.answer));
            }

            else if(message.type === 'new-ice-candidate') {

                let candidate = new RTCIceCandidate({

                    sdpMLineIndex: message.label,
                    sdpMid: message.id,
                    candidate: message.candidate
                });

                this.addIceCandidate(candidate);
            }
        }
    }

    /**
     * Connect to the other peer
     * 
     * @param otherPeerID 
     */
    public async connect(otherPeerID: string) {

        // Store the other peer ID
        this.otherPeerID = otherPeerID;

        // Initialize RTCPeerConnection for the user
        await this.createPeer();

        let offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);

        // Send the offer to the HOST
        let object = {
            
            application: 'word-blitz',
            type: 'offer',
            offer: offer,
            otherPeerID: this.otherPeerID,
            lobbyName: this.lobbyName
        }

        /*
        console.log('Sending offer');
        console.log(offer);
        console.log(' ');
        */

        this.sendMessageToServer(JSON.stringify(object));
    }

    /**
     * Close all connections to remote peer
     */
    public close(): void {

        if(this.dataChannel != null && this.dataChannel != undefined) {

            this.dataChannel.close();
        }

        if(this.peerConnection != null && this.peerConnection != undefined) {

            this.peerConnection.close();
            this.peerConnection = null;
        }
    }

    /**
     * Close the socket to the server
     */
    public closeSocket(): void {

        if(this.websocket != null && this.websocket != undefined) {

            this.websocket.close();
        }
    }

    /**
     * Send a message (string) to the other peer
     * 
     * Can also send a Blob, ArrayBuffer, or an ArrayBufferView
     * 
     * @param message 
     */
    public sendMessageToOpponent(message: string): void {

        this.dataChannel.send(message);
    }

    private async createPeer() {

        // Create a peer connection
        this.peerConnection = new RTCPeerConnection(this.pcConfig);

        // Create a new data dataChannel
        this.dataChannel = await this.peerConnection.createDataChannel('amesmiWordBlitz', { ordered: true });

        // Handle all incoming message from connecting peer
        this.peerConnection.ondatachannel = e => {

            e.channel.onopen = (event) => this.dataChannelOpen(event);
            e.channel.onclose = (event) => this.dataChannelClose(event);
            e.channel.onmessage = (event) => this.dataChannelMessage(event);
            e.channel.onerror = (event) => this.dataChannelError(event);
        }

        // Not supported in FireFox
        this.peerConnection.onconnectionstatechange = (event) => {

            switch(this.peerConnection.connectionState) {

                case 'closed': /*console.log('peer closed');*/ break;
                case 'connected': /*console.log('peer connected');*/ break;         // Once peers are connected, then start the application
                case 'connecting': /*console.log('peer connecting');*/ break;
                case 'disconnected': /*console.log('peer disconnected');*/ break;
                case 'failed': /*console.log('peer failed');*/ break;
                case 'new': /*console.log('peer new');*/ break;
            }
        }

        this.peerConnection.onsignalingstatechange = (event) => {

            switch(this.peerConnection.signalingState) {

                case 'closed': /*console.log('signaling state closed');*/ break;
                case 'have-local-offer': /*console.log('signaling state have-local-offer');*/ break;
                case 'have-local-pranswer': /*console.log('signaling state have-local-pranswer');*/ break;
                case 'have-remote-offer': /*console.log('signaling state have-remote-offer');*/ break;
                case 'have-remote-pranswer': /*console.log('signaling state have-remote-pranswer');*/ break;
                case 'stable': /*console.log('signaling state stable');*/ break;
            }
        }

        await this.createIceCandidate();
    }

    /*****************************************************************
     * 
     * Beginning of DataChannel methods
     * 
     *****************************************************************/

    private dataChannelOpen(event: Event): void {

        //console.log('Peer data channel is open');           // The can start exchanging messages

        this.dispatchEvent(new PeerEvent('datachannel-open', ''));
    }

    private dataChannelClose(event: Event): void {

        //console.log('Peer data channel is close');

        // Close all connections to the remote server
        this.close();
    }

    private dataChannelMessage(event: MessageEvent): void {

        //console.log('Incoming message');

        // event.data returns the message from the remote peer
        this.dispatchEvent(new PeerEvent('datachannel-message', event.data));
    }

    private dataChannelError(event: Event): void {

        //console.log('Peer data channel error');

        // Close all connections to the remote server
        this.close();
    }

    /****************** End of DataChannel methods *****************/

    private async setRemoteDescription(message: any) {

        let sessionDescription = new RTCSessionDescription(message);
        await this.peerConnection.setRemoteDescription(sessionDescription);
    }

    private async createAnswer() {

        let answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);

        // Send the answer to the guest peer
        let object = {

            application: 'word-blitz',
            type: 'answer',
            answer: answer,
            otherPeerID: this.otherPeerID,
            lobbyName: this.lobbyName
        }

        /*
        console.log('Sending answer:');
        console.log(answer);
        console.log(' ');
        */

        this.sendMessageToServer(JSON.stringify(object));
    }

    private async addIceCandidate(candidate: RTCIceCandidate) {

        try {

            /*
            console.log('Adding Ice Candidate:');
            console.log(candidate);
            console.log(' ');
            */

            await this.peerConnection.addIceCandidate(candidate);
        }

        catch (e) {

            //console.error('Error adding received ice candidate');
        }
    }

    private createIceCandidate(): void {

        this.peerConnection.oniceconnectionstatechange = (event) => {

            switch(this.peerConnection.iceConnectionState) {

                case 'new': /*console.log('new ice candidate');*/ break;
                case 'checking': /*console.log('checking ice candidate');*/ break;
                case 'connected': /*console.log('connected ice candidate');*/ break;
                case 'completed': /*console.log('completed ice candidate');*/ break;
                case 'failed': /*console.log('failed ice candidate');*/ break;
                case 'disconnected': /*console.log('disconnected ice candidate');*/ break;
                case 'closed': /*console.log('closed ice candidate');*/ break;
            }
        }

        this.peerConnection.onicegatheringstatechange = (event) => {

            switch(this.peerConnection.iceGatheringState) {

                case 'complete': /*console.log('complete ice gathering');*/ break;
                case 'gathering': /*console.log('gathering ice');*/ break;
                case 'new': /*console.log('new ice gathering');*/ break;
            }
        }

        //this.peerConnection.addEventListener('icecandidate', (event) => {
        this.peerConnection.onicecandidate = (event) => {

            /*
            //console.log('icecandidate event: ' + event);
            console.log('Ice Candidate Info');
            console.log(event.candidate);
            console.log(" ");
            */

            if(event.candidate) {

                let object = {

                    application: 'word-blitz',
                    type: 'new-ice-candidate',
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate,
                    otherPeerID: this.otherPeerID,
                    lobbyName: this.lobbyName
                }

                /*
                console.log('Sending ice candidate:');
                console.log(event.candidate);
                console.log(' ');
                */

                this.sendMessageToServer(JSON.stringify(object));
            }

            else {

                //console.log('All ICE candidates have been sent');
            }
        };
    }
}
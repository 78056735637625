import * as Phaser from 'phaser';

export class GameLevels {

    private currentLevel: number = 0;

    private levelInfoDisplay: Phaser.GameObjects.Sprite;
    private levelBackgroundDisplay: Phaser.GameObjects.Sprite;

    private levelCurrentTime: number = 0;
    private levelStartTime: number = 0;

    private isPlayingLevel: boolean = false;

    /**
     * Number of levels in the game
     */
     public readonly TOTAL_LEVELS: number = 9;

     private readonly levelData = [
 
         {totalWords: 10, wordSize: 2},
         {totalWords: 8, wordSize: 3},
         {totalWords: 7, wordSize: 4},
         {totalWords: 6, wordSize: 5},
         {totalWords: 5, wordSize: 6},
         {totalWords: 4, wordSize: 7},
         {totalWords: 3, wordSize: 8},
         {totalWords: 2, wordSize: 9},
         {totalWords: 1, wordSize: 10}
     ];

    constructor() {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        // Handle level background display
        this.levelBackgroundDisplay = add.sprite(0, 80, 'level_background');
        this.levelBackgroundDisplay.setOrigin(0, 0);
        this.levelBackgroundDisplay.setVisible(true);
        this.levelBackgroundDisplay.setDepth(1);

        // Handle level information display
        this.levelInfoDisplay = add.sprite(172, 515, 'level_info', this.currentLevel);
        this.levelInfoDisplay.setOrigin(0, 0);
        this.levelInfoDisplay.setVisible(true);
        this.levelInfoDisplay.setDepth(1);

        // Initialize the start time for displaying the current level information
        this.levelStartTime = new Date().getTime();
    }

    public update(currentTime: number): void {

        this.levelCurrentTime = currentTime - this.levelStartTime;

        // If the display time is more than 5 seconds.
        // Turn the level display information off and 
        // allow the player to play the current level
        if(this.levelCurrentTime >= 5000) {

            this.isVisible(false);

            // Reset the time
            this.levelCurrentTime = 0;
            this.levelStartTime = new Date().getTime();
        }
    }

    /**
     * Inform the player that they can begin playing the current level
     */
     public playLevel(): boolean {

        return this.isPlayingLevel;
    }

    /**
     * Return the number of words the player has to spell in order
     * to complete this level
     * 
     * @returns 
     */
    public getTotalWordsForCurrentLevel(): number {

        return this.levelData[this.currentLevel].totalWords;
    }

    /**
     * Return the number of characters in word that must be spelled
     * 
     * @returns 
     */
    public getWordSize(): number {

        return this.levelData[this.currentLevel].wordSize;
    }

    /**
     * Turn the level information display on and off
     * 
     * @param value 
     */
    public isVisible(value: boolean): void {

        this.isPlayingLevel = !value;

        this.levelBackgroundDisplay.setVisible(value);
        this.levelInfoDisplay.setVisible(value);
    }

    /**
     * Switch to the next level
     */
    public changeLevel(): void {

        this.currentLevel += 1;

        // Set the next level information
        this.levelInfoDisplay.setFrame(this.currentLevel);
    }

    /**
     * Reset the timer for the level
     */
    public timerReset(): void {

        // Reset the time
        this.levelCurrentTime = 0;
        this.levelStartTime = new Date().getTime();
    }

    /**
     * Get the current level
     * 
     * @returns 
     */
    public getCurrentLevel(): number {

        return (this.currentLevel + 1);
    }
}
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WordBlitzLobbyService } from './word-blitz-lobby.service';
import { Peer } from './peer/peer';
import { Game } from './challenge-game/game';
import axios from 'axios';

@Component({
  selector: 'app-word-blitz-lobby',
  templateUrl: './word-blitz-lobby.component.html',
  styleUrls: ['./word-blitz-lobby.component.scss']
})
export class WordBlitzLobbyComponent implements OnInit {

  private readonly PORT: string = 'https://amesmi.openode.dev'; //'https://amesmi.openode.io'; //'http://localhost:3000';

  // Whether this is the host, guest, or it was not
  // setup properly
  lobbyType: string = 'not-available';
  lobbyName: string = '';

  private peer: Peer;
  private game: Game;

  // Actual game width and height
  private readonly GAME_WIDTH: number = 720;
  private readonly GAME_HEIGHT: number = 1280;
  private hostInformation: HTMLDivElement;

  constructor(private service: WordBlitzLobbyService, private route: Router, private ngZone: NgZone) { 

    this.game = new Game(this.route);
  }

  ngOnInit(): void {

    // The user successfully setup the lobby
    if(this.service.getLobbyStatus() == true) {

      if(this.service.getIsHost() == true) {

        this.lobbyType = 'host-lobby';
        this.lobbyName = this.service.getLobbyName();

        // Create a peer for the host
        this.peer = new Peer(this.service.getHostID(), this.service.getLobbyName());
      }
  
      else if(this.service.getIsHost() == false) {
  
        this.lobbyType = 'guest-lobby';

        // Create a peer for the guest
        this.peer = new Peer(this.service.getGuestID(), this.service.getLobbyName());
      }

      // Initialize the event listener for the Peer
      this.initEventListener();
    }
    
    // The lobby was not setup properly
    else {

      // The service for the lobby was not setup properly
      this.lobbyType = 'not-available';
    }
  }

  ngAfterViewInit(): void {

    if(this.service.getLobbyStatus() == true) {

      // This player is the host
      if(this.service.getIsHost() == true) {

        this.hostInformation = document.getElementById('host-information') as HTMLDivElement;
      }

      // This player is the guest
      else if(this.service.getIsHost() == false) {

      }
    }
  }

  ngOnDestroy(): void {

    this.game.delete();
  }

  private initEventListener(): void {

    this.peer.addEventListener('add-guest-id', (event) => {

      let data = JSON.parse(event.message);

      // Set the guest information
      this.service.setGuestID(data.guestID);
      this.service.setGuestName(data.guestName);
    });

    this.peer.addEventListener('start-connection-to-host', (event) => {

      // Begin the connection to the HOST opponent
      this.peer.connect(this.service.getHostID());
    });

    this.peer.addEventListener('datachannel-open', (event) => {

      let gameboard: string = '';

      if(this.service.getIsHost() == true) {

        this.peer.closeSocket();
        gameboard = 'host-gameboard';
        this.hostInformation.style.display = 'none';

        // Delete lobby
        axios.post(this.PORT + '/deleteLobby', {lobbyName: this.service.getLobbyName()}).then((response) => {

        }).catch((error) => {

        });
      }

      else if(this.service.getIsHost() == false) {

        this.peer.closeSocket();
        gameboard = "guest-gameboard";
      }

      // Calculate the display width and height for the game
      let height: number = window.innerHeight - 54;
      let width: number = this.GAME_WIDTH * height / this.GAME_HEIGHT;

      // Assign the calculated width and height to the display canvas
      let element = document.getElementById(gameboard) as HTMLDivElement;
      element.style.width = width + 'px';
      element.style.height = height + 'px';

      // Start the game
      this.game.init(gameboard, this.GAME_WIDTH, this.GAME_HEIGHT, this.peer, this.service);
    });

    this.peer.addEventListener('display-stats', (event) => {

      this.ngZone.run(() => {

        this.route.navigateByUrl('/word-blitz-challenge-stats');
      });
    });
  }
}

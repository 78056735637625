import { Component, OnInit } from '@angular/core';

import { Game } from './code/game';

@Component({
  selector: 'app-abc-fight-home',
  templateUrl: './abc-fight-home.component.html',
  styleUrls: ['./abc-fight-home.component.scss']
})
export class AbcFightHomeComponent implements OnInit {

  private game: Game;

  constructor() { 

    this.game = new Game();
  }

  ngOnInit(): void {

    this.game.init();
  }

  ngOnDestroy(): void {

    // Delete the game
    this.game.delete();

    console.log('Deleted all resources in the game');
  }
}

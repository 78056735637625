<!-- Display the Play, Levels, Challenge Buttons -->
<!-- Display the Rankings and Rules button -->
<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-grey">
        <p class="w3-serif" style="font-size: 22px;"><i>Word Blitz. Do you have the skills to play?</i></p>
        <p>Amesmi Entertainment</p>
    </div>

    <ul class="w3-ul">

        <!-- Single Player Game -->
        <li class="w3-bar">
            <div class="icofont icofont-play-alt-2 w3-bar-item w3-button w3-text-green w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/word-blitz-play"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Play</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Spell lots of words</div>
            </div>
        </li>

        <!-- Level Game -->
        <li class="w3-bar">
            <div class="icofont icofont-layers w3-bar-item w3-button w3-text-green w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/word-blitz-levels"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Level Play</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Remember what’s required</div>
            </div>
        </li>

        <!-- Online Challenge Game -->
        <li class="w3-bar">
            <div class="icofont icofont-world w3-bar-item w3-button w3-text-green w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/word-blitz-challenge"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Online Challenge</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Challenge another player</div>
            </div>
        </li>

        <!-- Tournament Play -->
        <!--<li class="w3-bar">
            <img src="assets/images/projects/games2D/word-blitz/tournament/tournament.png" class="w3-bar-item w3-button w3-white w3-hover-white w3-hover-opacity" style="width: 90px;" routerLink="/word-blitz-tournament">

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Tournament</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Weekly tournament</div>
            </div>
        </li>-->

        <!-- Rankings -->
        <li class="w3-bar">
            <div class="icofont icofont-result-sport w3-bar-item w3-button w3-text-gray w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/word-blitz-rankings"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Rankings</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Top 25 Scores</div>
            </div>
        </li>

        <!-- Rules -->
        <li class="w3-bar">
            <div class="icofont icofont-ui-note w3-bar-item w3-button w3-text-gray w3-hover-text-light-gray w3-hover-white" style="width: 85px; font-size: 60px;" routerLink="/word-blitz-rules"></div>

            <div class="w3-bar-item" style="margin-left: 20px;">
                <div class="w3-text-gray" style="font-size: 20px;">Rules</div>
                <div class="w3-text-blue-gray" style="font-size: 15px;">Learn how to play</div>
            </div>
        </li>
    </ul>
</div>
import { Component } from '@angular/core';
//import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'development';

  constructor() {

    //let deviceInfo = this.deviceService.getDeviceInfo();

    //console.log(deviceInfo);
  }

  /**
   * Display the drop down menu
   */
  public dropDownMenuFunction(): void {

    let x = document.getElementById("drop-down-menu") as HTMLDivElement;

    if(x.className.indexOf("w3-show") == -1) {

      x.className += " w3-show";
    }

    else {

      x.className = x.className.replace(" w3-show", "");
    }
  }
}

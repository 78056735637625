export class PlayerEvent {

    public type: string;
    public message: string;

    constructor(type: string, message: string) {

        this.type = type;
        this.message = message;
    }
}

export class PlayerEventDispatcher {

    private myListener: any[] = [];

    constructor() {

    }

    private hasEventListener(type: string, listener: Function): boolean {

        let exists: boolean = false;

        for(let i = 0; i < this.myListener.length; i++) {

            if(this.myListener[i].type === type && this.myListener[i].listener === listener) {

                exists = true;
            }
        }

        return exists;
    }

    public addEventListener(type: string, listener: Function): void {

        // Listener already exists
        if(this.hasEventListener(type, listener)) {

            return;
        }

        // Add a new listener to the list
        this.myListener.push({type: type, listener: listener});
    }

    public removeListener(type: string, listener: Function): void {

        for(let i = 0; i < this.myListener.length; i++) {

            if(this.myListener[i].type === type && this.myListener[i].listener === listener) {

                // Remove listener from the list
                this.myListener.splice(i, 1);
            }
        }
    }

    public dispatchEvent(event: PlayerEvent): void {

        for(let i = 0; i < this.myListener.length; i++) {

            if(this.myListener[i].type === event.type) {

                this.myListener[i].listener.call(this, event);
            }
        }
    }
}
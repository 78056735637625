import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { PlayService } from '../word-blitz-play/play.service';

@Component({
  selector: 'app-word-blitz-play-stats',
  templateUrl: './word-blitz-play-stats.component.html',
  styleUrls: ['./word-blitz-play-stats.component.scss']
})
export class WordBlitzPlayStatsComponent implements OnInit {
  
  playerScore: number = 0;
  totalWords: number = 0;
  finalScore: number = 0;
  rank: number = 0;
  wordList: string = "";
  warningMessage: string = "";

  private modal: HTMLDivElement;
  private readonly URL: string = 'https://amesmi.openode.dev'; //'https://amesmi.openode.io'; //'http://localhost:3000';
  private readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(private service: PlayService, private route: Router, private http: HttpClient) { }

  ngOnInit(): void {

    // Always reset rank to 0
    this.rank = 0;

    // Store th results
    this.playerScore = this.service.getPlayerScore();
    this.totalWords = this.service.getTotalWords();
    this.wordList = this.service.getPlayerWords();

    // Calculate the player final score
    this.finalScore = this.playerScore + this.totalWords;

    axios.post(this.URL + '/getLeaderboard').then( (response) => {

      // Get the data from the server
      let leaderboard = response.data;

      // Determine the player rank
      for(let i = 0; i < leaderboard.length; i++) {

        if(this.finalScore > leaderboard[i].score) {

          this.rank = i + 1;

          // Exit the ranking system
          break;
        }
      }
     
    }).catch((error) => {
      
      alert('Cannot reach the server. Try Again.')
    });
  }

  ngAfterViewInit() {

    this.modal = document.getElementById("enter-name-modal") as HTMLDivElement;
    this.modal.style.display = 'none';
  }

  /**
   * The player is saving their rank in the top 25
   */
  public saveButton(): void {

    this.modal.style.display = 'block';
  }

  /**
   * The player does not want to enter their name
   */
  public cancelButton(): void {

    this.modal.style.display = 'none';
  }

  public okButton(): void {

    // Store the date
    let date = new Date();
    let month = this.months[date.getMonth()];
    let d = date.getDate().toString() + " " + month + " " + date.getFullYear().toString();

    // Get the player name
    let playerName = document.getElementById('player-name') as HTMLInputElement;
    let name: string = '';

    // The player name is more than 6 characters.
    // Trim the name to the appropriate size
    if(playerName.value.length > 6) {

      // Loop through the characters of the player submitted name
      for(let i = 0; i < 6; i++) {

        name += playerName.value.charAt(i);
      }
    }

    // Just store the player name
    else {

      name = playerName.value;
    }

    // Clear the value
    playerName.value = " ";


    /******************************************************
     * 
     * See if the player used an appropriate name
     * 
     ******************************************************/
    let myName: string = name.toLowerCase().trim();
    let badname: boolean = false;

    // Get the file
    this.http.get('assets/file/bad-words.txt', {
      responseType: 'text'
    }).subscribe((data) => {

      // Split base on line
      let mydata: string[] = data.split('\n');

      for(let i = 0; i < mydata.length; i++) {

        // An inappropriate name has been found
        if(myName === mydata[i]) {
          
          badname = true;
          break;
        }
      }

      // An inappropriate name was not found
      if(badname == false) {

        let object = {
          name: name,
          score: this.finalScore,
          date: d
        }
    
        // Save the player score in the leaderboard
        axios.post(this.URL + '/savePlayerScore', object).then((response) => {
    
          let status = response.data;
    
          // Successfully stored the player information
          if(status == true) {
    
            // Close the modal
            this.modal.style.display = 'none';
    
            // Send the player to the leaderboard
            this.route.navigateByUrl('/word-blitz-rankings');
          }
    
          else {
            
            this.warningMessage = 'Unable to save score. Server is down.'
          }
    
        }).catch((error) => {
          
          this.warningMessage = 'Cannot reach the server. Try Again.'
        });
      }

      // Inform the player to use a more appropriate word or just their initials
      else {

        this.warningMessage = 'Please use an appropriate name. Thank You!';
      }
    });
  }
}

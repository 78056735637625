import { GameLevels } from "../levels/game-levels";

export class WordList {

    private list: string[] = [];
    private words: string[] = [];
    private isVisible: boolean = false;
    private SCREEN_WIDTH: number = 720;

    private listText: Phaser.GameObjects.Text[] = [];
    private scoreDisplay: Phaser.GameObjects.Text;
    private wordDisplayBackground: Phaser.GameObjects.Sprite;
    private levelDisplay: Phaser.GameObjects.Text;

    private currentLevel: number = 0;
    
    constructor(private level: GameLevels) {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory) {

        this.wordDisplayBackground = add.sprite(0, 80, 'word_list');
        this.wordDisplayBackground.setOrigin(0, 0);
        this.wordDisplayBackground.setDepth(2);
        this.wordDisplayBackground.setVisible(this.isVisible);

        let myscore: number = 0;
        this.scoreDisplay = add.text(13, 90, "Score: " + myscore.toString(), {fontSize: "35px", color: "rgb(0, 0, 0)"});
        this.scoreDisplay.setOrigin(0, 0);
        this.scoreDisplay.setDepth(3);
        this.scoreDisplay.setVisible(this.isVisible);

        this.levelDisplay = add.text(500, 90, "Level " + this.level.getCurrentLevel().toString(), {fontSize: "35px", color: "rgb(165, 42, 42)"});
        this.levelDisplay.setOrigin(0, 0);
        this.levelDisplay.setDepth(3);
        this.levelDisplay.setVisible(this.isVisible);

        let y: number = 235;

        for(let i = 0; i < this.level.TOTAL_LEVELS; i++) {

            // Make sure the list is empty
            this.list[i] = '';

            this.listText[i] = add.text(13, y, "", {fontSize: "30px", color: "rgb(0, 0, 0)"});
            this.listText[i].setWordWrapWidth(this.SCREEN_WIDTH);
            this.listText[i].setLineSpacing(10);
            this.listText[i].setDepth(4);
            this.listText[i].setVisible(this.isVisible);

            y += 100;
        }
    }

    /**
     * Clear all resources
     */
    public delete(): void {

        for(let i = 0; i < this.level.TOTAL_LEVELS; i++) {

            this.listText[i].destroy();
        }
        
        this.scoreDisplay.destroy();
        this.levelDisplay.destroy();
        this.wordDisplayBackground.destroy();
    }

    /**
     * Add a word to the word list
     * 
     * @param word 
     */
    public add(word: string, score: number): void {

        this.words.push(word);

        // Determine the total number of words stored
        // and convert to a string
        //let total: number = this.words.length;
        this.scoreDisplay.text = "Score: " + score.toString();

        // add word to list
        this.list[this.currentLevel] += word + " ";

        // Store the words in the list
        this.listText[this.currentLevel].setText(this.list[this.currentLevel]);
    }

    /**
     * Determine if the word has already been stored in the list
     * 
     * @param word 
     * @returns 
     */
    public isUnique(word: string): boolean {

        let unique: boolean = true;

        for(let i = 0; i < this.words.length; i++) {
            
            // The word is not unique
            if(word.toLowerCase() === this.words[i].toLowerCase()) {

                unique = false;

                break;
            }
        }

        return unique;
    }  

    /**
     * Toggle bewteen turning the background on/off
     */
    public show(): void {

        this.isVisible = !this.isVisible;
        
        this.wordDisplayBackground.setVisible(this.isVisible);
        this.scoreDisplay.setVisible(this.isVisible);
        this.levelDisplay.setVisible(this.isVisible);

        for(let i = 0; i < this.level.TOTAL_LEVELS; i++) {
         
            this.listText[i].setVisible(this.isVisible);
        }
    }

    /**
     * Turn the word list off because the game is over
     */
    public turnOff(): void {

        this.wordDisplayBackground.setVisible(false);
        this.scoreDisplay.setVisible(false);
        this.levelDisplay.setVisible(false);
        
        for(let i = 0; i < this.level.TOTAL_LEVELS; i++) {
         
            this.listText[i].setVisible(false);
        }
    }

    /**
     * Get the player list of words
     * 
     * @returns 
     */
    public getWords(): string[] {

        let object = [];

        let title: string[] = ["2-Letter Words", "3-Letter Words", "4-Letter Words", 
                               "5-Letter Words", "6-Letter Words", "7-Letter Words",
                               "8-Letter Words", "9-Letter Words", "10-Letter Word"];

        // Create an object
        for(let i = 0; i < this.list.length; i++) {

            // Only send the list with words in it
            if(this.list[i] !== "") {
                
                object.push({
                    title: title[i],
                    words: this.list[i]
                });
            }
        }

        return object;
    }

    /**
     * Return the total number of words
     * 
     * @returns 
     */
    public getTotalWords(): number {

        return this.words.length;
    }

    /**
     * Update the level for the word list
     */
    public setCurrentLevel(): void {

        // Used in arrays
        this.currentLevel = this.level.getCurrentLevel() - 1;

        // Update the level information
        this.levelDisplay.text = 'Level '+ this.level.getCurrentLevel().toString();
    }
}
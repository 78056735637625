<!-- Using W3.css to handle all the styling -->
<div class="w3-container remove-container-padding-margin">
  
  <!-- Will be displayed on all the web pages for this site -->
  <!-- Keep at the top of the page (Fixed) -->
  <div class="w3-bar w3-white w3-wide w3-padding w3-card w3-top">
    
    <!-- Return the user to the home screen when click -->
    <div routerLink="" title="Amesmi Home Page" class="w3-bar-item w3-button w3-hover-light-gray">Amesmi</div>

    <!-- Float links to the right. Hide them on the small screen -->
    <div class="w3-right w3-hide-small">
      <div routerLink="/about" title="About Amesmi Entertainment" class="w3-bar-item w3-button w3-hover-light-gray">About</div>
      <div routerLink="/projects" title="Projects Created by Amesmi Entertainment" class="w3-bar-item w3-button w3-hover-light-gray">Projects</div>
      <div routerLink="/contact" title="Contact Information" class="w3-bar-item w3-button w3-hover-light-gray">Contact</div>
    </div>

    <!-- Display the indicator for a drop down menu -->
    <div class="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" (click)="dropDownMenuFunction()">&#9776;</div>
  </div>

  <div id="drop-down-menu" class="w3-bar-block w3-sand w3-hide w3-hide-large w3-hide-medium">
    <div routerLink="/about" title="About Amesmi Entertainment" class="w3-bar-item w3-border-bottom w3-button w3-hover-khaki">About</div>
    <div routerLink="/projects" title="Projects Created by Amesmi Entertainment" class="w3-bar-item w3-border-bottom w3-button w3-hover-khaki">Projects</div>
    <div routerLink="/contact" title="Contact Information" class="w3-bar-item w3-border-bottom w3-button w3-hover-khaki">Contact</div>
  </div>

  <!-- The pages will be displayed here -->
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
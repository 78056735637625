import * as Phaser from 'phaser';

import { Dictionary } from '../dictionary/dictionary';
import { WordList } from '../dictionary/word-list';
import { GameGrid } from '../object/game-grid';

/**
 * The player cannot use more than 23 letters to spell a word
 * 
 */
export class Player {

    private word: string = '';
    private wordPoints: number = 0;
    private score: number = 0;
    private letterPosition: number[] = [];
    private wordList: WordList;

    private scoreDisplay: Phaser.GameObjects.Text;
    private wordDisplay: Phaser.GameObjects.Text;
    private potentialPointsDisplay: Phaser.GameObjects.Text;

    private dictionary: Dictionary;
    private readonly TOTAL_LETTERS_IN_THE_ALPHABET: number = 26;
    public readonly letterValue: number[] = [1, 3, 3, 2, 1, 4, 2, 4, 1, 8, 5, 1, 3, 1, 1, 3, 10, 1, 1, 1, 1, 4, 4, 8, 4, 10];
    public readonly letterCharacter: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    private add: Phaser.GameObjects.GameObjectFactory;

    constructor(private fallingLetters: any[], private gameGrid: GameGrid) {

        this.dictionary = new Dictionary();
        this.wordList = new WordList();
    }

    /**
     * Create a new player object
     * 
     * @param add 
     */
    public create(add: Phaser.GameObjects.GameObjectFactory, cache: Phaser.Cache.CacheManager): void {

        this.add = add;

        // Setup the dictionary ability to read the text file
        this.dictionary.create(cache);

        // Represent the player score
        this.scoreDisplay = add.text(20, 8, this.score.toString(), {fontSize: '60px', color: 'rgb(119, 221, 119)'});
        this.scoreDisplay.setOrigin(0, 0);

        // Represent the current word being spelled
        this.wordDisplay = add.text(0, 1124, '', {fontSize: '50px', color: 'rgb(255, 255, 255)'});
        this.wordDisplay.setOrigin(0, 0);

        // Represent the potential points that the player can earn/loss with the current word
        this.potentialPointsDisplay = add.text(0, 40, '', {fontSize: '30px', color: 'rgb(190, 190, 0)'});
        this.potentialPointsDisplay.setOrigin(0, 0);

        // Center the word in the middle of the screen
        this.wordDisplay.x = 720 / 2 - this.wordDisplay.width / 2;
        
        // Initialize word list images
        this.wordList.create(add);
    }

    /**
     * The player is adding a letter to their word
     * 
     * @param letter 
     */
    public addLetter(letter: string): void {

        if(this.word.length > 0) {
            
            // Add a lowercase letter to the word
            this.word += letter.toLowerCase();
        }

        else {

            this.word += letter;
        }
        
        this.wordDisplay.text = this.word;

        // Center the word in the middle of the screen
        this.wordDisplay.x = 720 / 2 - this.wordDisplay.width / 2;
    }

    /**
     * Display the potential points for the current word
     * 
     * @param points 
     */
    public addPotentialPoints(points: number): void {

        this.wordPoints += points;

        this.potentialPointsDisplay.text = '+/- ' + this.wordPoints.toString();

        // Calculate the potential points position which is based on the
        // player score position
        this.potentialPointsDisplay.x = this.scoreDisplay.x + this.scoreDisplay.width + 10;
    }

    /**
     * Submit the word to the dictionary for verification
     */
    public submitWord(): void {

        this.verifyWordInDictionary();

        // Clear the resources
        this.word = '';
        this.wordDisplay.text = '';
        this.wordPoints = 0;
        this.potentialPointsDisplay.text = '';

        // Clear the letter list
        this.letterPosition = [];
    }

    /**
     * Clear the word from the board and place
     * the letters back on the gameboard
     */
    public clearWord(): void {

        // 6 - purple letter
        // 5 - white letter
        this.addLettersToGameboard(6, 5);

        // Clear the resources
        this.word = '';
        this.wordDisplay.text = '';
        this.wordPoints = 0;
        this.potentialPointsDisplay.text = '';

        // Clear the letter list
        this.letterPosition = [];
    }

    /**
     * Display the player word list
     */
    public wordsDisplay(): void {

        this.wordList.show();
    }

    /**
     * The game is over. Turn off the word list
     */
    public turnOffWordList(): void {

        this.wordList.turnOff();
    }

    // Store the letter position in the list
    public storeLetterPosition(letter: number): void {

        this.letterPosition.push(letter);
    }

    /**
     * Get the player score
     * 
     * @returns 
     */
    public getScore(): number {

        return this.score;
    }

    /**
     * Get the player list of words
     */
    public getWords(): string {

        return this.wordList.getWords();
    }

    /**
     * Get total number of words
     * 
     * @returns 
     */
    public getTotalWords(): number {

        return this.wordList.getTotalWords();
    }

    /**
     * Delete all resources
     */
    public delete(): void {

        this.wordDisplay.destroy();
        this.scoreDisplay.destroy();
        this.potentialPointsDisplay.destroy();

        this.wordList.delete();
    }

    /**
     * The player pressed the clear button.
     * Add the letters back to the player board.
     * Do not subtract any points.
     */
    private addLettersToGameboard(colorLetter: number, whiteLetter: number): void {

        let totalLetters: number = this.letterPosition.length;
        let count: number = 0;
        let isDone: boolean = false;

        for(let row = 5; row >= 3; row--) {

            for(let col = 0; col < this.gameGrid.NUMBER_OF_COLS; col++) {

                let letter: number = this.letterPosition[count];

                // Calculate the letter position in the spritesheet
                let letterPosition: number = colorLetter * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;               // Purple letter
                let selectedLetterPosition: number = whiteLetter * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;       // White letter

                // Create a new letter sprite
                // at the assign location in the grid
                let letterSprite = this.add.sprite(this.gameGrid.grid[row][col].x, this.gameGrid.grid[row][col].y, 'letters', letterPosition); 
                letterSprite.setOrigin(0, 0);
                letterSprite.setVisible(false);

                // Add the new letter sprite to the falling letter list
                this.fallingLetters.push({
                    sprite: letterSprite,
                    xGrid: this.gameGrid.grid[row][col].x,
                    yGrid: this.gameGrid.grid[row][col].y,
                    col: col,
                    row: row,
                    letter: letter,
                    character: this.letterCharacter[letter],
                    pointValue: this.letterValue[letter],
                    letterPosition: letterPosition,
                    selectedLetterPosition: selectedLetterPosition,
                    moveLetterCurrentTime: 0,
                    moveLetterStartTime: new Date().getTime()
                });

                count++;

                // Exit the inner loop
                if(count >= totalLetters) {

                    isDone = true;
                    break;
                }
            }

            // Exit the outer loop
            if(isDone == true) {

                break;
            }
        }
    }

    private verifyWordInDictionary(): void {

        // Verify that the word is spelled correctly
        // If spelled correctly (add word to word list and add points to player score)
        // If spelled incorrectly (add the word back to the player board and subtract points from player score)
        let isCorrect = this.dictionary.isSpelledCorrectly(this.word, this.wordPoints);

        // The word is spelled correctly
        if(isCorrect == true) {

            let isUnique: boolean = this.wordList.isUnique(this.word);

            // The word is NOT in the list
            if(isUnique == true) {

                // Add the points to the player score
                this.score += this.wordPoints;
                this.scoreDisplay.text = this.score.toString();

                // Add the spelled correct word to the word list
                this.wordList.add(this.word);
            }
            
            // The word is in the list
            else if(isUnique == false) {

                // Add the word letters back to the gameboard but with a different color
                // 3 - light brown letters
                // 5 - white letters
                this.addLettersToGameboard(3, 5);
            }
        }

        // The word is spelled incorrectly
        else if(isCorrect == false) {

            // Subtract the points from the player score
            this.score -= this.wordPoints;
            this.scoreDisplay.text = this.score.toString();

            // Add the word letters back to the gameboard
            // 4 - red letters
            // 5 - white letters
            this.addLettersToGameboard(4, 5);
        }
    }
}
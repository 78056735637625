import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlayService {

  private score: number = 0;
  private words: string = '';
  private totalWords: number = 0;

  constructor() { }

  public setPlayerScore(value: number): void {

    this.score = value;
  }

  public getPlayerScore(): number {

    return this.score;
  }

  public setPlayerWords(value: string): void {

    this.words = value;
  }

  public getPlayerWords(): string {

    return this.words;
  }

  public setTotalWords(value: number): void {

    this.totalWords = value;
  }

  public getTotalWords(): number {

    return this.totalWords;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PongSurvivorHomeComponent } from './pong-survivor-home/pong-survivor-home.component';

const routes: Routes = [
  {path: 'pong-survivor-home', component: PongSurvivorHomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

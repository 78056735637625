import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AbcFightHomeComponent } from './abc-fight-home/abc-fight-home.component';

const providers = [];

@NgModule({})
export class AbcFightSharedModule {

  static forRoot(): ModuleWithProviders<AppModule> {

    return {

      ngModule: AppModule,
      providers: providers
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AbcFightHomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

<!-- Display the rules for How Many -->
<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray w3-round-large">
        <p class="w3-xlarge w3-serif">How Many Rules</p>
    </div>

    <!-- Game play -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow: 1px 1px 0 #444;">Game Play</h4>
    </div>

    <div class="w3-container" style="margin-bottom: 20px;">
        <p class="w3-text-black">
            The HOST creates a phrase, sentence, or a combination of words for the players to use. 
        </p>

       <p class="w3-text-black"> 
            Everyone must use the letters submitted by the HOST to spell as many words as possible before time runs out.
        </p>

        <p class="w3-text-black">
            The player with the highest score is the winner.
        </p>
    </div>

    <!-- Things to remember -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow: 1px 1px 0 #444;">Things to remember</h4>
    </div>

    <div class="w3-container">
        
        <!-- Word is valid, spelled correctly, and unique -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-black" style="font-size: 25px;">Word</label>
            </div>
            <div>
                <label class="w3-text-black">Word is valid, spelled correctly, and unique</label>
            </div>
        </div>
        
            
        <!-- Word is valid, spelled correctly, and NOT unique -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-green" style="font-size: 25px;">Word</label>
            </div>
            <div>
                <label class="w3-text-black">Word is valid, spelled correctly, and NOT unique</label>
            </div>
        </div>
        
            
        <!-- Word is valid but spelled incorrectly -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-red" style="font-size: 25px;">Word</label>
            </div>
            <div>
                <label class="w3-text-black">Word is valid but spelled incorrectly</label>
            </div> 
        </div>
              

        <!-- Word is NOT valid -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-purple w3-opacity" style="text-decoration: line-through; font-size: 25px;">Word</label>
            </div>
            <div>
                <label class="w3-text-black">Word is NOT valid</label>
            </div>
        </div>
    </div>

    <hr />

    <div class="w3-container">
        <!-- Word is valid, spelled correctly, and unique -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-black" style="font-size: 25px;">Valid</label>
            </div>
            <div>
                <label class="w3-text-black">Use only letters in the phrase, sentence, or combination of words</label>
            </div>
        </div>

        <!-- Word is valid, spelled correctly, and unique -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-black" style="font-size: 25px;">Unique</label>
            </div>
            <div>
                <label class="w3-text-black">No other player spelled that word</label>
            </div>
        </div>
    </div>

    <!-- Game play -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow: 1px 1px 0 #444;">Scoring</h4>
    </div>

    <div class="w3-container">

        <!-- 1 Point -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-black" style="font-size: 20px;">1 Point</label>
            </div>
            <div>
                <label class="w3-text-black">Every word that is valid, spelled correctly, and NOT unique</label>
            </div>
        </div>

        <!-- 10 points -->
        <div style="margin-bottom: 20px;">
            <div>
                <label class="w3-text-black" style="font-size: 20px;">10 Points</label>
            </div>
            <div>
                <label class="w3-text-black">Every word that is valid, spelled correctly, and unique</label>
            </div>
        </div>
    </div>
</div>
import * as Phaser from 'phaser';

import { Animation } from './objects/animation';
import { Sprite } from './objects/sprite';

export class Bullet {

    private add: Phaser.GameObjects.GameObjectFactory;

    private bullets: Sprite[] = [];
    private selectedLetter: number = 0;
    private readonly RightWall: number = 800;
    private readonly LeftWall: number = 0;

    constructor(selectedLetter: number) {

        this.selectedLetter = selectedLetter;
    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.add = add;
    }

    public update(elapsedTime: number): void {

        // Move the bullet
        this.moveBullet(elapsedTime);

        // Draw the bullet
        this.drawBullet();
    }

    /**
     * Fire a bullet at the current position
     */
    public fire(x: number, y: number, bulletSpeed: number): void {

        // Get the bullet
        let bullet = this.add.image(x, y, 'bullets', this.selectedLetter);
        bullet.setOrigin(0, 0);
        bullet.setVisible(false);

        // Create the animation
        let anim = new Animation();
        anim.addFrame(bullet, 0);

        // Create a new sprite for the current bullet
        let sprite = new Sprite(anim);
        sprite.setX(x);
        sprite.setY(y);
        sprite.setVelocityX(bulletSpeed);
        sprite.setVelocityY(0);

        // Add the bullet to the list
        this.bullets.push(sprite);
    }

    /**
     * Move the bullet across the screen
     * 
     * @param elapsedTime 
     */
    private moveBullet(elapsedTime: number): void {

        // Loop through all the bullets the player has created
        for(let i = 0; i < this.bullets.length; i++) {

            if(this.bullets[i].getX() + this.bullets[i].getWidth() >= this.RightWall) {

                // Delete the image
                this.bullets[i].getImage().destroy();

                // Remove the bullet from the list
                this.bullets.splice(i, 1);

                continue;
            }

            // The bullet hit the left wall
            else if(this.bullets[i].getX() <= this.LeftWall) {

                // Delete the image
                this.bullets[i].getImage().destroy();

                // Remove the bullet from the list
                this.bullets.splice(i, 1);

                continue;
            }

            // Update the bullet position
            this.bullets[i].update(elapsedTime);
        }
    }

    /**
     * Draw the bullet on the screen
     */
    private drawBullet(): void {

        // Draw the bullet at the next position
        for(let i = 0; i < this.bullets.length; i++) {

            // Get the bullet
            let bullet = this.bullets[i].getImage();
            bullet.setX(Math.round(this.bullets[i].getX()));
            bullet.setY(Math.round(this.bullets[i].getY()));
        }
    }

    /**
     * Destroy the bullet on the screen
     */
    public destroy(): void {

        for(let i = 0; i < this.bullets.length; i++) {

            this.bullets[i].getImage().destroy();
        }
    }

    /**
     * Return a list of bullets
     */
    public getBullets(): Sprite[] {

        return this.bullets;
    }
}
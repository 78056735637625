<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray w3-round-large">
        <p class="w3-xlarge w3-serif">Word Blitz Leaderboard</p>
    </div>

    <!-- Display the leaderboard options -->
    <div class="w3-bar w3-light-gray w3-border w3-round-large">

        <button id="single-play" class="w3-bar-item w3-button w3-border-right tablink w3-blue-gray" (click)="openLeaderboard('single-play', 'Single-Player');">Single Play</button>
        <button id="level-play" class="w3-bar-item w3-button w3-border-right tablink" (click)="openLeaderboard('level-play', 'Levels');">Level Play</button>
    </div>

    <div id="Single-Player" class="w3-container playMode" style="display: block;">
        <ul class="w3-ul">

            <!-- Loop through the leaderboard -->
            <li *ngFor="let player of leaderboard; let i = index;" class="w3-bar">
                
                <!-- Display the appropriate icon for each player ranked in the top 25 -->
                <div class="{{ icons[i] }} w3-bar-item w3-text-gray w3-opacity-min" style="width: 85px; font-size: 60px;"></div>
    
                <!-- Display the player information -->
                <div class="w3-bar-item" style="margin-left: 20px;">
                    <div class="w3-text-blue-gray" style="font-size: 20px;">({{ i + 1}}) <label class="w3-text-green">{{ player.name }}</label></div>
                    <div class="w3-text-gray" style="font-size: 15px;">{{ player.date }}</div>
                    <div class="w3-text-orange" style="font-size: 15px;">{{ player.score }}</div>
                </div>
            </li>
        </ul>
    
        <!-- Return the player to the word blitz home page -->
        <div routerLink="/word-blitz-home" class="w3-button w3-blue-gray w3-round-large icofont icofont-home w3-large" title="Word Blitz Home" style="margin-bottom: 10px;">Home</div>
    
    </div>

    <div id="Levels" class="w3-container playMode" style="display: none;">
        <ul class="w3-ul">

            <!-- Loop through the leaderboard -->
            <li *ngFor="let player of levelLeaderboard; let i = index;" class="w3-bar">
                
                <!-- Display the appropriate icon for each player ranked in the top 25 -->
                <div class="{{ icons[i] }} w3-bar-item w3-text-gray w3-opacity-min" style="width: 85px; font-size: 60px;"></div>
    
                <!-- Display the player information -->
                <div class="w3-bar-item" style="margin-left: 20px;">
                    <div class="w3-text-blue-gray" style="font-size: 20px;">({{ i + 1}}) <label class="w3-text-green">{{ player.name }}</label></div>
                    <div class="w3-text-gray" style="font-size: 15px;">Completed Level: {{ player.level }}</div>
                    <div class="w3-text-gray" style="font-size: 15px;">{{ player.date }}</div>
                    <div class="w3-text-orange" style="font-size: 15px;">{{ player.score }}</div>
                </div>
            </li>
        </ul>
    
        <!-- Return the player to the word blitz home page -->
        <div routerLink="/word-blitz-home" class="w3-button w3-blue-gray w3-round-large icofont icofont-home w3-large" title="Word Blitz Home" style="margin-bottom: 10px;">Home</div>
    </div>
</div>

import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { HowManyLobbyService } from './how-many-lobby.service';

//import { Peer } from './peer/peer';
//import { GuestPeer } from './peer/guestPeer';
import { HostPeer } from './peer/hostPeer';

@Component({
  selector: 'app-how-many-lobby',
  templateUrl: './how-many-lobby.component.html',
  styleUrls: ['./how-many-lobby.component.scss']
})
export class HowManyLobbyComponent implements OnInit {

  private MIN_PLAYERS: number = 0;
  private readonly GAME_TIME: number = 300; // Represent seconds
  private readonly PORT: string = 'https://amesmi.openode.dev'; //'http://localhost:3000'; //'https://amesmi.openode.io';

  //private guestPeer: GuestPeer;
  private hostPeer: HostPeer;

  // Whether this is the host, guest, or it was not
  // setup properly
  lobbyType: string = 'not-available';
  lobbyName: string = '';

  // Represent the guest lobby status
  guestLobbyStatus: string = 'lobby-waiting-area';
  hostName: string = '';
  activePlayers: string[] = [];
  requestingPlayers: any[] = [];
  hostPhrase: string = '';
  wordList: string[] = [];
  validWords = [];
  countDownClock: string = "";
  gameManager: string = '';
  gameStatus: string = '';

  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  private hostInputPhrase: HTMLDivElement;
  private phraseInputDisplay: HTMLDivElement;
  private phraseInput: HTMLInputElement;
  private phraseButton: HTMLButtonElement;

  // Timer information
  private start: number;
  private diff: number;
  private minutes: any;
  private seconds: any;
  private duration: number;
  private myInterval: any;

  private letterCount = [
    {letter: 'a', count: 0},  {letter: 'b', count: 0},  {letter: 'c', count: 0},  {letter: 'd', count: 0},  {letter: 'e', count: 0},
    {letter: 'f', count: 0},  {letter: 'g', count: 0},  {letter: 'h', count: 0},  {letter: 'i', count: 0},  {letter: 'j', count: 0},
    {letter: 'k', count: 0},  {letter: 'l', count: 0},  {letter: 'm', count: 0},  {letter: 'n', count: 0},  {letter: 'o', count: 0},
    {letter: 'p', count: 0},  {letter: 'q', count: 0},  {letter: 'r', count: 0},  {letter: 's', count: 0},  {letter: 't', count: 0},
    {letter: 'u', count: 0},  {letter: 'v', count: 0},  {letter: 'w', count: 0},  {letter: 'x', count: 0},  {letter: 'y', count: 0},
    {letter: 'z', count: 0}
  ]

  private playersResults = [];

  constructor(private service: HowManyLobbyService, private route: Router, private ngZone: NgZone) { }

  ngOnInit(): void {

    // The user successfully setup the lobby
    if(this.service.getLobbyStatus() == true) {

      if(this.service.getIsHost() == true) {

        this.lobbyType = 'host-lobby';
        this.lobbyName = this.service.getLobbyName();
        this.MIN_PLAYERS = this.service.getTotalPlayers();

        this.activePlayers.push(this.service.getHostName() + ' (HOST)');

        // Creatw the HOST peer
        this.hostPeer = new HostPeer(this.service.getHostID(), this.service.getLobbyName());

        this.hostEventListener();
      }
    }

    // The lobby was not setup properly
    else {

      // The service for the lobby was not setup properly
      this.lobbyType = 'not-available';
    }

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  ngAfterViewInit(): void {

    if(this.service.getLobbyStatus() == true) {

      if(this.service.getIsHost() == true) {

        // Do Not Display the Phrase, Word List, Input Block until the HOST start the game
        this.hostInputPhrase = document.getElementById('host-input-phrase') as HTMLDivElement;
        this.hostInputPhrase.style.display = 'none';
  
        // Allow the HOST input the game phrase
        this.phraseInputDisplay = document.getElementById('phrase-input-display') as HTMLDivElement;
        this.phraseInput = document.getElementById('phrase-input') as HTMLInputElement;
        this.phraseButton = document.getElementById('phrase-button') as HTMLButtonElement;

        this.phraseInputDisplay.style.display = 'block';
        this.phraseButton.disabled = true;
        this.phraseInput.disabled = true;
      }
    }
  }

  ngOnDestroy(): void {

    // Close all connections
    if(this.hostPeer !== null && this.hostPeer !== undefined) {

      this.hostPeer.closeSocket();
      this.hostPeer.close();

      console.log('Host left the game');
    }
  }

  /**
   * Close the error modal
   */
   public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
    this.route.navigateByUrl('/how-many-home');
  }

  private hostEventListener(): void {

    this.hostPeer.addEventListener('display-guest-information', (event) => {

      let data = JSON.parse(event.message);

      // Get the player information that is requesting access to the lobby
      let playerName: string = data.playerName;
      let playerID: string = data.playerID;

      // Store the requesting playering information
      this.requestingPlayers.push({
        name: playerName,
        id: playerID
      });
    });

    this.hostPeer.addEventListener('datachannel-open', (event) => {

      // Each time a player connects to the host. Show that player on 
      // everyone board
      let object = {

        action: 'update-players-in-game',
        players: this.activePlayers
      }

      this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));
    });

    /**
     * Process all messages from the guests
     */
    this.hostPeer.addEventListener('datachannel-message', (event) => {

      // Store the guest results
      let guestResult = JSON.parse(event.message);

      if(guestResult.action === 'guest-word-list') {

        // Store the guest player words
        let guestPlayer = guestResult.results;

        // Add the guest to the overall list
        this.playersResults.push(guestPlayer);
        
        // Calculate the results of the game
        if(this.activePlayers.length === this.playersResults.length) {

          //console.log('Start Calculating the winner');
          this.calculatePlayersResults();
        }
      }
    });
  }

  /**
   * Deny the player access to the lobby
   * 
   * @param playerID 
   * @param playerName 
   */
  public denyButton(playerID: string, playerName: string): void {

    // Remove player from the requesting list
    for(let i = 0; i < this.requestingPlayers.length; i++) {

      if(playerID === this.requestingPlayers[i].id) {

        // Remove from list
        this.requestingPlayers.splice(i, 1);

        // exit loop
        break;
      }
    }

    // Inform the player that they have been allowed into the lobby
    let object = {
      application: 'how-many',
      action: 'cannot-enter-lobby',
      playerID: playerID,
      lobbyName: this.lobbyName
    }

    this.hostPeer.sendMessageToServer(JSON.stringify(object));

    // Turn off buttons
    let bar = document.getElementById('player-' + playerID) as HTMLDivElement;
    bar.remove();
  }

  /**
   * Allow the player to enter the lobby
   * 
   * @param playerID 
   * @param playerName 
   */
  public allowButton(playerID: string, playerName: string): void {

    this.activePlayers.push(playerName);

    // Create the WebRTC peer for this guest to connect to the host
    this.hostPeer.createPeer(playerID, playerName);

    // Inform the player that they have been allowed into the lobby
    let object = {
      application: 'how-many',
      action: 'you-can-enter-lobby',
      playerID: playerID,
      lobbyName: this.lobbyName
    }

    this.hostPeer.sendMessageToServer(JSON.stringify(object));

    // Activate the Game Button and Input field once the minimum
    // amount of players have been met
    if(this.activePlayers.length >= this.MIN_PLAYERS) {

      this.phraseButton.disabled = false;
      this.phraseInput.disabled = false;
    }

    // Turn off buttons
    let bar = document.getElementById('player-' + playerID) as HTMLDivElement;
    bar.remove();
  }


  /**********************************************************************************
   * 
   * Game Data Below
   * 
   **********************************************************************************/
  
  public startGame(): void {

    let input = document.getElementById('phrase-input') as HTMLInputElement;

    // Must input a real value before starting the game
    if(input.value != '') {

      // Turn the phrase input off
      this.phraseInputDisplay.style.display = 'none';

      // Assign the input to the host phrase display
      this.hostPhrase = input.value;

      // Calculate the amount of letters in phrase
      this.determineLetterTotalInPhrase(this.hostPhrase);

      // Display the host input phrase
      this.hostInputPhrase.style.display = 'block';
      this.gameManager = 'game-is-active';
      this.gameStatus = 'game-has-started';

      // 300 seconds = 5:00 minutes
      this.startTime(this.GAME_TIME);

      let object = {
        action: 'start-game',
        hostPhrase: this.hostPhrase,
        letterCount: this.letterCount
      }

      // Inform the guests that the game is starting
      this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));

      // Close the HOST socket. No longer needed
      this.hostPeer.closeSocket();

      // Delete the lobby because the game has started
      // It is no longer needed
      axios.post(this.PORT + '/howManyDeleteLobby', {lobbyName: this.service.getLobbyName()}).then((response) => {

      }).catch((error) => {

      });

      // Clear
      input.value = '';
    }
  }

  public submitWord(): void {

    let hostWord = document.getElementById('host-word') as HTMLInputElement;

    if(hostWord.value != '') {

      if(this.wordList.length > 0) {

        let isAvailable: boolean = true;

        // Make sure the word has not been submitted to the list already
        for(let i = 0; i < this.wordList.length; i++) {

          // The word is not available
          if(hostWord.value === this.wordList[i]) {

            isAvailable = false;

            break;
          }
        }

        // Add the word to the word list
        if(isAvailable == true) {

          // Add the word to the word list
          this.wordList.push(hostWord.value);
        }
      }

      // This is the first word in the list. Add the word
      else {

        // Add the word to the word list
        this.wordList.push(hostWord.value);
      }

      // Clear the word value
      hostWord.value = '';
    }
  }

  public removeWord(word: string): void {

    for(let i = 0; i < this.wordList.length; i++) {

      if(word === this.wordList[i]) {

        // Remove word from list
        this.wordList.splice(i, 1);

        // Exit loop
        break;
      }
    }
  }

  /**
   * Return the player to the home screen
   */
  public homeButton(): void {

    this.route.navigateByUrl('/how-many-home');
  }

  private startTime(duration: number): void {

    this.start = Date.now();
    this.duration = duration;

    this.timer();
    this.myInterval = setInterval(() => this.timer(), 1000);
  }

  private timer(): void {

    // get the number of seconds that have elapsed since 
    // startTimer() was called
    this.diff = this.duration - (((Date.now() - this.start) / 1000) | 0);

    // does the same job as parseInt truncates the float
    this.minutes = (this.diff / 60) | 0;
    this.seconds = (this.diff % 60) | 0;

    this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

    // Update the clock display
    this.countDownClock = this.minutes + ":" + this.seconds;

    let object = {
      action: 'update-time',
      time: this.countDownClock
    }

    // Update the time for the guest
    this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));
    
    if(this.diff <= 0) {

      // add one second so that the count down starts at the full duration
      // example 05:00 not 04:59
      //this.start = Date.now() + 1000;
      clearInterval(this.myInterval);
      
      this.gameManager = 'game-is-over';
      this.gameStatus = 'game-is-over';

      let object = {
        
        action: 'game-is-over'
      }

      // Inform the guest that the game is over
      this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));

      // Calculate the player score
      this.checkValidityOfWord();
    }
  }

  /**
   * Determine how many letters are in the phrase
   * 
   * @param hostPhrase 
   */
  private determineLetterTotalInPhrase(hostPhrase): void {

    for(let i = 0; i < hostPhrase.length; i++) {

      // Get the character at the specified position in the phrase sumbitted by the host
      let letter = hostPhrase.charAt(i).toLowerCase().trim();

      // Calulate the amount of times the letter shows up in the phrase
      switch(letter) {

        case 'a': this.letterCount[0].count++; break;
        case 'b': this.letterCount[1].count++; break;
        case 'c': this.letterCount[2].count++; break;
        case 'd': this.letterCount[3].count++; break;
        case 'e': this.letterCount[4].count++; break;
        case 'f': this.letterCount[5].count++; break;
        case 'g': this.letterCount[6].count++; break;
        case 'h': this.letterCount[7].count++; break;
        case 'i': this.letterCount[8].count++; break;
        case 'j': this.letterCount[9].count++; break;
        case 'k': this.letterCount[10].count++; break;
        case 'l': this.letterCount[11].count++; break;
        case 'm': this.letterCount[12].count++; break;
        case 'n': this.letterCount[13].count++; break;
        case 'o': this.letterCount[14].count++; break;
        case 'p': this.letterCount[15].count++; break;
        case 'q': this.letterCount[16].count++; break;
        case 'r': this.letterCount[17].count++; break;
        case 's': this.letterCount[18].count++; break;
        case 't': this.letterCount[19].count++; break;
        case 'u': this.letterCount[20].count++; break;
        case 'v': this.letterCount[21].count++; break;
        case 'w': this.letterCount[22].count++; break;
        case 'x': this.letterCount[23].count++; break;
        case 'y': this.letterCount[24].count++; break;
        case 'z': this.letterCount[25].count++; break;
      }
    }
  }

  private checkValidityOfWord(): void {

    let wordLetterCount = [
      {letter: 'a', count: 0},  {letter: 'b', count: 0},  {letter: 'c', count: 0},  {letter: 'd', count: 0},  {letter: 'e', count: 0},
      {letter: 'f', count: 0},  {letter: 'g', count: 0},  {letter: 'h', count: 0},  {letter: 'i', count: 0},  {letter: 'j', count: 0},
      {letter: 'k', count: 0},  {letter: 'l', count: 0},  {letter: 'm', count: 0},  {letter: 'n', count: 0},  {letter: 'o', count: 0},
      {letter: 'p', count: 0},  {letter: 'q', count: 0},  {letter: 'r', count: 0},  {letter: 's', count: 0},  {letter: 't', count: 0},
      {letter: 'u', count: 0},  {letter: 'v', count: 0},  {letter: 'w', count: 0},  {letter: 'x', count: 0},  {letter: 'y', count: 0},
      {letter: 'z', count: 0}
    ]

    for(let i = 0; i < this.wordList.length; i++) {

      // Convert to lower case and trim all whitespaces
      let word: string = this.wordList[i];

      for(let j = 0; j < word.length; j++) {

        // Get the character at the specified position
        let letter: string = word.charAt(j).toLowerCase().trim();

        // Calulate the amount of times the letter shows up in the phrase
        switch(letter) {

          case 'a': wordLetterCount[0].count++; break;
          case 'b': wordLetterCount[1].count++; break;
          case 'c': wordLetterCount[2].count++; break;
          case 'd': wordLetterCount[3].count++; break;
          case 'e': wordLetterCount[4].count++; break;
          case 'f': wordLetterCount[5].count++; break;
          case 'g': wordLetterCount[6].count++; break;
          case 'h': wordLetterCount[7].count++; break;
          case 'i': wordLetterCount[8].count++; break;
          case 'j': wordLetterCount[9].count++; break;
          case 'k': wordLetterCount[10].count++; break;
          case 'l': wordLetterCount[11].count++; break;
          case 'm': wordLetterCount[12].count++; break;
          case 'n': wordLetterCount[13].count++; break;
          case 'o': wordLetterCount[14].count++; break;
          case 'p': wordLetterCount[15].count++; break;
          case 'q': wordLetterCount[16].count++; break;
          case 'r': wordLetterCount[17].count++; break;
          case 's': wordLetterCount[18].count++; break;
          case 't': wordLetterCount[19].count++; break;
          case 'u': wordLetterCount[20].count++; break;
          case 'v': wordLetterCount[21].count++; break;
          case 'w': wordLetterCount[22].count++; break;
          case 'x': wordLetterCount[23].count++; break;
          case 'y': wordLetterCount[24].count++; break;
          case 'z': wordLetterCount[25].count++; break;
        }
      }

      // Was the right amount of letters used for the word
      let isWordValid: boolean = true;

      for(let j = 0; j < this.letterCount.length; j++) {

        // The word is not valid if 1 letter is misused
        if(wordLetterCount[j].count > this.letterCount[j].count) {

          isWordValid = false;

          // Exit loop
          break;
        }
      }

      // Store the results of the word validity check
      this.validWords.push({
        word: word,
        valid: isWordValid,
        isSpelledCorrectly: false,
        isUnique: false
      });

      // Reset the count to zero
      for(let j = 0; j < wordLetterCount.length; j++) {

        wordLetterCount[j].count = 0;
      }
    }

    // Check the dictionary for correct spelling
    this.checkDictionary();
  }

  private checkDictionary(): void {
  
    let object = {
      words: this.validWords
    }

    // Verify the word in the dictionary
    axios.post(this.PORT + '/checkDictionary', object).then((response) => {

      let list = response.data;

      // Store the results of all the players
      this.playersResults.push({
        name: this.service.getHostName(),
        results: list,
        score: 0
      });

    }).catch((error) => {

      this.errorMessage = 'Unable to connect to the server';
      this.errorModalDisplay.style.display = 'block';
    });
  }

  /**
   * Calculate the player results
   */
  private calculatePlayersResults(): void {

    // Loop through all the players to determine their score
    for(let i = 0; i < this.playersResults.length; i++) {

      for(let list of this.playersResults[i].results) {

        list.isUnique = this.isWordUnique(list.word, i);
      }
    }

    // Calculte the player score
    for(let i = 0; i < this.playersResults.length; i++) {

      for(let list of this.playersResults[i].results) {

        if(list.valid == true) {

          if(list.isSpelledCorrectly == true) {

            if(list.isUnique == true) {

              this.playersResults[i].score += 10;
            }

            else {

              this.playersResults[i].score += 1;
            }
          }
        }
      }
    }

    this.ngZone.run(() => {

      let object = {
        action: 'display-game-results',
        gameResults: this.playersResults
      }

      console.log(this.playersResults);

      // Send the results to all the players
      this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));

      let displayGameResults = document.getElementById('display-game-results') as HTMLDivElement;

      // Loop through all the players
      for(let i = 0; i < this.playersResults.length; i++) {

        let divElement = document.createElement('div');
        divElement.className = 'w3-border w3-border-gray w3-round-medium';
        divElement.style.width = '300px';
        divElement.style.marginBottom = '15px';

        // Create the player bar title
        let playerBar = document.createElement('div') as HTMLDivElement;
        playerBar.className = 'w3-bar';
        //playerBar.className = "w3-bar w3-border w3-border-gray w3-round-medium";
        playerBar.style.width = "300px";
        //playerBar.style.marginRight = '10px';

        // Store the player name
        let playerName = document.createElement('div') as HTMLDivElement;
        playerName.className = "w3-bar-item w3-text-blue-gray w3-left";
        playerName.innerHTML = this.playersResults[i].name;

        // Store the player score
        let playerScore = document.createElement('div') as HTMLDivElement;
        playerScore.className = "w3-bar-item w3-text-deep-orange w3-right";
        playerScore.innerHTML = this.playersResults[i].score;

        let line = document.createElement('hr') as HTMLHRElement;
        line.style.margin = '0px';

        // Store the player and score information in the bar
        playerBar.append(playerName);
        playerBar.append(playerScore);
        divElement.append(playerBar);

        // Add a divider
        divElement.append(line);

        // Create a word bar
        let wordBar = document.createElement('div') as HTMLDivElement;
        wordBar.className = 'w3-bar';

        // loop through all the words that each player spelled
        for(let j = 0; j < this.playersResults[i].results.length; j++) {

          // The word used the appropriate letters in the given phrase
          if(this.playersResults[i].results[j].valid == true) {

            // The word was spelled correctly 
            if(this.playersResults[i].results[j].isSpelledCorrectly == true) {

              // No other player spelled this word
              if(this.playersResults[i].results[j].isUnique == true) {
                
                let word = document.createElement('label') as HTMLLabelElement;
                word.className = 'w3-bar-item w3-text-black';
                word.innerHTML = this.playersResults[i].results[j].word;
                
                // Add the word to the bar
                wordBar.append(word);
              }

              // Other players spelled the same word
              else if(this.playersResults[i].results[j].isUnique == false) {

                let word = document.createElement('label') as HTMLLabelElement;
                word.className = 'w3-bar-item w3-text-green';
                word.innerHTML = this.playersResults[i].results[j].word;

                // Add the word to the bar
                wordBar.append(word);
              }
            }

            // The word was NOT spelled correctly
            else if(this.playersResults[i].results[j].isSpelledCorrectly == false) {

              let word = document.createElement('label') as HTMLLabelElement;
              word.className = 'w3-bar-item w3-text-red';
              word.innerHTML = this.playersResults[i].results[j].word;

              // Add the word to the bar
              wordBar.append(word);
            }
          }

          // The word did not use the appropriate letters
          else if(this.playersResults[i].results[j].valid == false) {

            let word = document.createElement('label') as HTMLLabelElement;
            word.className = 'w3-bar-item w3-text-purple w3-opacity';
            word.style.textDecoration = 'line-through';
            word.innerHTML = this.playersResults[i].results[j].word;

            // Add the word to the bar
            wordBar.append(word);
          }
        }

        // Add the word bar to the display view
        divElement.append(wordBar);

        // Store the player bar
        displayGameResults.append(divElement);
      }
    });
  }

  /**
   * Make sure the word has not been used by another player 
   */ 
  private isWordUnique(word: string, position: number): boolean {

    let status: boolean = true;

    for(let i = 0; i < this.playersResults.length; i++) {

      // Loop to the next word
      if(i === position) {
        continue;
      }

      // Make sure the word is unique
      else {

        let results: any[] = this.playersResults[i].results;

        for(let j = 0; j < results.length; j++) {

          // The word is not unique
          if(word.toLowerCase() === results[j].word.toLowerCase()) {

            status = false;

            // Exit loop
            break;
          }
        }

        if(status == false) {

          break;
        }
      }
    }

    // return the status 
    return status;
  }
}

import { Router } from '@angular/router';
import * as Phaser from 'phaser';
import { Peer } from '../peer/peer';
import { WordBlitzLobbyService } from '../word-blitz-lobby.service';
import { GameScreen } from './screens/game-screen';

export class Game {

    private game: Phaser.Game;
    private gameConfig: Phaser.Types.Core.GameConfig;
    private readonly backgroundColor: string = 'rgb(0, 0, 0)';

    private gameScreen: GameScreen;

    constructor(private route: Router) {

    }

    public init(element: string, GAME_WIDTH: number, GAME_HEIGHT: number, peer: Peer, service: WordBlitzLobbyService): void {

        this.gameScreen = new GameScreen(this.route, peer, service);

        this.gameConfig = {

            type: Phaser.AUTO,

            // Screen size
            width: GAME_WIDTH,
            height: GAME_HEIGHT,

            // Set the farme per second
            fps: {
                target: 30,
                forceSetTimeOut: true
            },

            // DIV element
            parent: element,

            // Set background color
            backgroundColor: this.backgroundColor,

            scene: [this.gameScreen],

            scale: {
                mode: Phaser.Scale.ScaleModes.FIT
            }
        }

        this.game = new Phaser.Game(this.gameConfig);
    }

    public delete(): void {

        if(this.gameScreen != null && this.gameScreen != undefined) {

            this.gameScreen.delete();
        }
        
        if(this.game != null && this.game != undefined) {

            this.game.destroy(false, false);
        }
    }
}
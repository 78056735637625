import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HowManyHomeComponent } from './how-many-home/how-many-home.component';
import { HowManyCreateLobbyComponent } from './how-many-create-lobby/how-many-create-lobby.component';
import { HowManyJoinLobbyComponent } from './how-many-join-lobby/how-many-join-lobby.component';
import { HowManyLobbyComponent } from './how-many-lobby/how-many-lobby.component';
import { HowManyRulesComponent } from './how-many-rules/how-many-rules.component';
import { HowManyGuestLobbyComponent } from './how-many-guest-lobby/how-many-guest-lobby.component';

const routes: Routes = [
  {path: "how-many-home", component: HowManyHomeComponent},
  {path: 'how-many-create-lobby', component: HowManyCreateLobbyComponent},
  {path: 'how-many-join-lobby', component: HowManyJoinLobbyComponent},
  {path: 'how-many-lobby/:lobbyID', component: HowManyLobbyComponent},
  {path: 'how-many-guest-lobby/:lobbyID', component: HowManyGuestLobbyComponent},
  {path: 'how-many-rules', component: HowManyRulesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { WordBlitzLobbyService } from '../word-blitz-lobby/word-blitz-lobby.service';

@Component({
  selector: 'app-word-blitz-create-lobby',
  templateUrl: './word-blitz-create-lobby.component.html',
  styleUrls: ['./word-blitz-create-lobby.component.scss']
})
export class WordBlitzCreateLobbyComponent implements OnInit {

  private readonly PORT: string = 'https://amesmi.openode.dev'; //'https://amesmi.openode.io';  //'http://localhost:3000';

  private readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private readonly alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  constructor(private route: Router, private service: WordBlitzLobbyService) { }

  ngOnInit(): void {

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Allow the host to create a new lobby and be the host of the lobby
   */
  public createLobby(): void {

    let lobbyName: string = '';

    // Determine the number of characters in the lobby name 
    let numberOfCharacters: number = this.randomInt(6, 9);

    // Determine the characters position in the alphabet
    for(let i = 0; i < numberOfCharacters; i++) {

      // Get the character position in the alphabet
      let position: number = this.randomInt(0, this.alphabet.length);

      // Add the character to the lobby name
      lobbyName += this.alphabet[position];
    }

    // Store the lobby creation date and time
    let date = new Date();
    let month = this.months[date.getMonth()];
    let d = date.getDate().toString() + " " + month + " " + date.getFullYear().toString();
    let time = date.getHours().toString() + ":" + date.getMinutes().toString();

    // Determine if the lobby is public or private
    let displayStatus = document.getElementsByName('display-status') as NodeListOf<HTMLInputElement>;
    let status: string = '';

    for(let i = 0; i < displayStatus.length; i++) {

      // If the radio button is selected then
      // store the radio button value
      if(displayStatus[i].checked == true) {

        status = displayStatus[i].value;
      }
    }

    // Get host name
    let hostName = document.getElementById('host-name') as HTMLInputElement;

    // Host name is not empty
    if(hostName.value !== '') {

      let hostID: string = uuidv4();    // Create an unique ID for the host
      let lobbyID: string = uuidv4();   // Create an unique ID for the lobby
      let isHost: boolean = true;       // This user is the host
    
      // Store the lobby information for the user (HOST)
      this.service.setLobbyName(lobbyName);
      this.service.setLobbyID(lobbyID);
      this.service.setHostID(hostID);
      this.service.setHostName(hostName.value);
      this.service.setDate(d);
      this.service.setTime(time);
      this.service.setDisplayStatus(status);
      this.service.setIsHost(true);
      this.service.setLobbyStatus(true);
      
      let object = {

        lobbyName: this.service.getLobbyName(),
        lobbyID: this.service.getLobbyID(),
        hostID: this.service.getHostID(),
        hostName: this.service.getHostName(),
        date: this.service.getDate(),
        time: this.service.getTime(),
        displayStatus: this.service.getDisplayStatus()
      }

      // Create a lobby on the server
      axios.post(this.PORT + '/createLobby', object).then((response) => {

        let result = response.data;

        // Successfully created the lobby on the server
        if(result == true) {

          // Place the HOST in the lobby
          this.route.navigateByUrl('/word-blitz-lobby/' + this.service.getLobbyID());
        }

        // Did not create the lobby.
        // There was an issue
        else {

          this.errorMessage = 'Unable to create the lobby. Try again!';
          this.errorModalDisplay.style.display = 'block';
        }

      }).catch((error) => {

        this.errorMessage = 'Unable to connect to server';
        this.errorModalDisplay.style.display = 'block';
      });

      // Clear the field
      hostName.value = '';
    }

    else {

      this.errorMessage = 'Must enter the host name';
      this.errorModalDisplay.style.display = 'block';
    }
  }

  /**
   * Close the error modal
   */
  public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Return a random number between two numbers
   * 
   * @param min 
   * @param max 
   */
  private randomInt(min: number, max: number): number {

    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
  }
}

import { GameGridEvent, GameGridEventDispatcher } from "./game-grid-event-dispatcher";

export class GameGrid extends GameGridEventDispatcher {

    public readonly TOTAL_VISIBLE_ROWS: number = 13;
    public readonly VISIBLE_ROW_BEGINNING: number  = 7;
    public readonly NUMBER_OF_ROWS: number = 20;
    public readonly NUMBER_OF_COLS: number = 9;
    public readonly LETTER_MOVEMENT: number = 80;

    public grid = [];

    constructor() {

        super();
    }

    /**
     * Create the gameboard grid for the letters to fall on
     */
    public create(): void {

        // Represent the top of the grid
        let gridTopPositionY: number = -480;

        for(let row = 0; row < this.NUMBER_OF_ROWS; row++) {

            let yPos = (row * this.LETTER_MOVEMENT) + gridTopPositionY;

            let column = [];

            for(let col = 0; col < this.NUMBER_OF_COLS; col++) {

                let startX: number = 0;
                let xPos = startX + this.LETTER_MOVEMENT * col;

                column[col] = {

                    x: xPos,
                    y: yPos,
                    occupied: 0
                }
            }

            // Create a new multidimensional array
            // can now be called grid[row][col].x
            this.grid[row] = column;
        }
    }

    /**
     * Check the column height in order to determine
     * if the game is over
     */
     public verifyColumn(col: number): void {

        let total: number = 0;

        // Loop through each row
        for(let row = this.VISIBLE_ROW_BEGINNING; row < this.NUMBER_OF_ROWS; row++) {

            // If the column is occupied
            if(this.grid[row][col].occupied === 1) {

                total += 1;
            }
        } 

        // The game is over. Inform the player
        if(total >= this.TOTAL_VISIBLE_ROWS) {

            /*
            this.isGameOver = true;

            this.deactivatePlayerControls();
            this.displayGameoverInformation();
            */

            this.dispatchEvent(new GameGridEvent('is-game-over', 'true'));
        }
    }

    public delete(): void {

        // Clear the grid array
        for(let i = this.grid.length - 1; i >= 0; i--) {

            this.grid.splice(i, 1);
        }
    }
}
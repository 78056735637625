<div [ngSwitch]='lobbyType'>

    <!-- Represent the GUEST lobby -->
    <div *ngSwitchCase="'guest-lobby'">

        <!-- -->
        <div [ngSwitch]='guestLobbyStatus'>

            <!-- The guest is in the lobby waiting -->
            <div *ngSwitchCase="'lobby-waiting-area'">

                <!-- Display the HOST and lobby information -->
                <div class="w3-container" style="margin-top: 20px;">
                    <div class="w3-text-blue-gray">HOST: <label class="w3-text-black">{{ hostName }}</label></div>
                    <div class="w3-text-blue-gray">Lobby Name: <label class="w3-text-black">{{ lobbyName }}</label></div>
                </div>

                <!-- Inform the guest that they will be let in soon -->
                <div class="w3-display-middle">
                    <h4 class="w3-text-gray">Host will let you in soon</h4>
                </div>
            </div>

            <!-- The guest has entered the lobby (start playing game) -->
            <div *ngSwitchCase="'enter-lobby'">

                <div class="w3-row">

                    <!-- Main game area -->
                    <div class="w3-col l8" style="margin-bottom: 20px;">
                        <div class="w3-container">
                            <!-- Display the phrase the HOST created -->
                            <!-- Should be display after the HOST presses start -->
                            <div id='guest-input-phrase'>

                                <div [ngSwitch]="gameManager">

                                    <!-- The game is over, display the game is over statement -->
                                    <div *ngSwitchCase="'game-is-over'">
                                        <div class="w3-text-gray" style="font-size: 25px; margin-top: 20px;">Game Over</div>
                                    </div>
        
                                    <!-- The game is active, display the count down clock -->
                                    <div *ngSwitchCase="'game-is-active'">
                                        <div class="w3-text-deep-orange" id="timer" style="font-size: 50px; margin-top: 20px;">{{ countDownClock }}</div>
                                    </div>
                                </div>

                                <div class="w3-center">
                                    <div class="w3-text-blue-gray" style="margin-top: 10px; font-size: 30px;">
                                        {{ hostPhrase }}
                                    </div>
                                </div>

                                <!-- Draw a divider -->
                                <hr class="w3-text-red" />

                                <!-- Allow the Guest to input a word -->
                                <div class="w3-center">

                                    <!-- See if the HOST has started the game-->
                                    <div [ngSwitch]='gameStatus'>

                                        <!-- The HOST has NOT started the game -->
                                        <div *ngSwitchCase="'waiting-for-host'">
                                            <label class="w3-text-blue-gray" style="font-size: 20px;">Waiting on host to start the game</label>
                                        </div>

                                        <!-- The HOST has started the game -->
                                        <div *ngSwitchCase="'game-has-started'">
                                            <div class="w3-bar">
                                                <input class="w3-input w3-bar-item w3-border w3-border-gray" id="guest-word" type="text" placeholder="Enter word" style="width: 250px; margin-bottom: 10px;">
                                                <button id="guest-submit-button" class="w3-button w3-bar-item w3-green w3-border w3-border-green w3-hover-light-gray w3-hover-border-light-gray" (click)="submitWord()">Submit</button>
                                            </div>

                                            <!-- Display the Word List -->
                                            <ul class="w3-ul">
                                                <li *ngFor="let word of wordList" class="w3-bar" style="width: 250px">

                                                    <button title="Remove Word" class="w3-bar-item w3-button w3-circle icofont icofont-not-allowed w3-text-red w3-hover-text-light-gray w3-hover-white w3-white" style="font-size: 30px; padding: 0px;" (click)="removeWord(word)"></button>
                                                    <div class="w3-bar-item w3-text-gray" style="font-size: 22px; padding: 0px; margin-left: 10px;">{{ word }}</div>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- Game is over determining who won the game -->
                                        <div *ngSwitchCase="'game-is-over'" class="w3-left">
                                            <div style="margin-bottom: 15px;">
                                                <label class="w3-text-blue-gray" style="font-size: 20px;">Game Results</label>
                                            </div>

                                            <!-- Display the game results -->
                                            <div id="display-game-results" style="width: 300px;"></div>

                                            <!-- Allow the player to go back to the home screen -->
                                            <div style="margin-top: 10px;">
                                                <button class="w3-button w3-blue-gray w3-round-medium" (click)="homeButton()">Home</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <!-- Display all players playing the game -->
                    <div class="w3-col l4">
        
                        <div class="w3-container">
                            <div class="w3-panel w3-light-gray w3-round-medium">
                                <h3 class="w3-text-blue-gray">Players</h3>
                            </div>
                        
                            <div *ngFor="let player of activePlayers" class="w3-bar">
                                <div class='w3-bar-item w3-text-blue-gray w3-left'>
                                    {{ player }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- A lobby is not available -->
    <!-- The host or guest did not join the lobby properly -->
    <!-- Must start over -->
    <div *ngSwitchCase="'not-available'">
        <div class="w3-display-middle">
            <div style="margin-bottom: 10px;">
                <h2>Opps!! Major Issue</h2>
                <h4>Please start over!</h4>
                <label><label class="w3-text-red" style="margin-right: 10px;">Note:</label>You cannot copy and paste or cut and paste a link</label>
            </div>
            <div routerLink="/how-many-home" class="w3-button w3-green w3-border w3-border-green w3-round-medium w3-hover-gray w3-hover-border-gray w3-medium">Home</div>
        </div>
    </div>    
</div>

<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>
import * as Phaser from 'phaser';
import { ButtonEvent, ButtonEventDispatcher } from './button-event-dispatcher';

export class RemoveLetterButtons extends ButtonEventDispatcher {

    private button25: Phaser.GameObjects.Sprite;
    private button50: Phaser.GameObjects.Sprite;
    private button75: Phaser.GameObjects.Sprite;

    constructor() {

        super();
    }

    /**
     * Create the remove buttons
     * 
     * @param add 
     */
    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.button25 = add.sprite(650, 8, 'remove_buttons', 2);
        this.button25.setOrigin(0, 0);
        this.button25.setInteractive();

        this.button50 = add.sprite(560, 8, 'remove_buttons', 1);
        this.button50.setOrigin(0, 0);
        this.button50.setInteractive();

        this.button75 = add.sprite(470, 8, 'remove_buttons', 0);
        this.button75.setOrigin(0, 0);
        this.button75.setInteractive();

        this.button_25_controls();
        this.button_50_controls();
        this.button_75_controls();
    }

    /**
     * Disable the button
     */
     public disable(): void {

        this.button25.setVisible(false);
        this.button50.setVisible(false);
        this.button75.setVisible(false);
        
        this.button25.disableInteractive();
        this.button50.disableInteractive();
        this.button75.disableInteractive();
    }

    /**
     * Remove the button resources
     */
     public destroy(): void {

        this.button25.destroy();
        this.button50.destroy();
        this.button75.destroy();
    }

    /**
     * Remove 25% of the letters
     */
     private button_25_controls(): void {

        // The player pressed the submit button
        this.button25.on('pointerdown', () => {

            // Change the button color
            this.button25.setFrame(5);
        });

        // The player released the submit button
        this.button25.on('pointerup', () => {

            this.dispatchEvent(new ButtonEvent('remove-letters', '0.25'));

            // Change the button back to its original color
            this.button25.setFrame(2);
            this.button25.setVisible(false);
            this.button25.disableInteractive();
        });
    }

    /**
     * Remove 50% of the letters
     */
     private button_50_controls(): void {
        
        // The player pressed the submit button
        this.button50.on('pointerdown', () => {

            // Change the button color
            this.button50.setFrame(4);
        });

        // The player released the submit button
        this.button50.on('pointerup', () => {

            this.dispatchEvent(new ButtonEvent('remove-letters', '0.50'));

            // Change the button back to its original color
            this.button50.setFrame(1);
            this.button50.setVisible(false);
            this.button50.disableInteractive();
        });
    }

    /**
     * Remove 75% of the letters
     */
     private button_75_controls(): void {
        
        // The player pressed the submit button
        this.button75.on('pointerdown', () => {

            // Change the button color
            this.button75.setFrame(3);
        });

        // The player released the submit button
        this.button75.on('pointerup', () => {

            this.dispatchEvent(new ButtonEvent('remove-letters', '0.75'));

            // Change the button back to its original color
            this.button75.setFrame(0);
            this.button75.setVisible(false);
            this.button75.disableInteractive();
        });
    }
}
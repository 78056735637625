import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { JeopardyService } from '../amesmi-jeopardy-home/jeopardy.service';

@Component({
  selector: 'app-amesmi-jeopardy-create-lobby',
  templateUrl: './amesmi-jeopardy-create-lobby.component.html',
  styleUrls: ['./amesmi-jeopardy-create-lobby.component.scss']
})
export class AmesmiJeopardyCreateLobbyComponent implements OnInit {

  private readonly PORT: string = 'http://localhost:3000'; //;  

  private readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private readonly alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  private doneSelecting: boolean = false;
  categories: string[] = [];  //["Bible", 'Country', 'Science', 'Math', 'Geography', 'History', 'English', 'NFL', 'MLB', 'NBA', 'Movies', 'TV Shows', 'Films', '1865', '1777'];
  
  // Rounds of Jeopardy
  firstRound: string[] = [];
  secondRound: string[] = [];
  finalRound: string[] = [];

  private gamePlay: string = '';

  constructor(private service: JeopardyService, private route: Router) { }

  ngOnInit(): void {

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';

    this.getCategories();
  }

  /**
   * Allow the host to create a new lobby and be the host of the lobby
   */
  public createLobby(): void {

    let lobbyName: string = '';

    // Determine the number of characters in the lobby name 
    let numberOfCharacters: number = this.randomInt(6, 9);

    // Determine the characters position in the alphabet
    for(let i = 0; i < numberOfCharacters; i++) {

      // Get the character position in the alphabet
      let position: number = this.randomInt(0, this.alphabet.length);

      // Add the character to the lobby name
      lobbyName += this.alphabet[position];
    }

    // Store the lobby creation date and time
    let date = new Date();
    let month = this.months[date.getMonth()];
    let d = date.getDate().toString() + " " + month + " " + date.getFullYear().toString();
    let time = date.getHours().toString() + ":" + date.getMinutes().toString();

    // Determine if the lobby is public or private
    let displayStatus = document.getElementsByName('display-status') as NodeListOf<HTMLInputElement>;
    let status: string = '';

    // Determine if the lobby is public or private
    for(let i = 0; i < displayStatus.length; i++) {

      // If the radio button is selected then
      // store the radio button value
      if(displayStatus[i].checked == true) {

        status = displayStatus[i].value;
      }
    }

    // Store the host name
    let hostName = document.getElementById('host-name') as HTMLInputElement;

    // Store the number of players in the Lobby
    let numberOfPlayers = document.getElementById('number-of-players') as HTMLInputElement;

    // The host name is not empty
    if(hostName.value !== '') {

      // The number of player is not empty
      if(numberOfPlayers.value !== '') {

        // Must be at least 4 people in order to play Amesmi Jeopardy
        let totalPlayers: number = Number.parseInt(numberOfPlayers.value);

        // Number of players playing the game must between 4 or 10 players
        if(totalPlayers >= 4 && totalPlayers <= 10) {

          // The HOST has selected all the categories for the game
          if(this.firstRound.length === 6 && this.secondRound.length === 6 && this.finalRound.length === 1) {

            let hostID: string = uuidv4();    // Create an unique ID for the host
            let lobbyID: string = uuidv4();   // Create an unique ID for the lobby
            let isHost: boolean = true;       // This user is the host
            let lobbyStatus: boolean = true;  // Set the lobby status

            // Store the lobby information for the user (HOST)
            // Store the lobby information for the user (HOST)
            this.service.setLobbyName(lobbyName);
            this.service.setLobbyID(lobbyID);
            this.service.setHostID(hostID);
            this.service.setHostName(hostName.value);
            this.service.setTotalPlayers(totalPlayers);
            this.service.setDate(d);
            this.service.setTime(time);
            this.service.setDisplayStatus(status);
            this.service.setIsHost(isHost);
            this.service.setLobbyStatus(lobbyStatus);
            this.service.setGamePlay(this.gamePlay);

            let object = {

              lobbyName: this.service.getLobbyName(),
              lobbyID: this.service.getLobbyID(),
              hostID: this.service.getHostID(),
              hostName: this.service.getHostName(),
              date: this.service.getDate(),
              time: this.service.getTime(),
              displayStatus: this.service.getDisplayStatus(),
              gamePlay: this.service.getGamePlay(),
              firstRound: this.firstRound,
              secondRound: this.secondRound,
              finalRound: this.finalRound
            }

            // Create a lobby on the server
            axios.post(this.PORT + '/amesmiJeopardyCreateLobby', object).then((response) => {

              // Store the game categories along with their questions
              // results[] = {category, question, answer}
              let results = response.data;

              // Create the jeopardy round with category, questions and answers
              this.service.createJeopardyRound(this.firstRound, this.secondRound, this.finalRound, results);

              // Place the HOST inside the lobby
              this.route.navigateByUrl('/amesmi-jeopardy-host-lobby/' + this.service.getLobbyID());

            }).catch((error) => {

              // Unable to connect to the remote server
              this.errorMessage = 'Unable to connect to the server.';
              this.errorModalDisplay.style.display = 'block';
            });

            // Clear the fields
            hostName.value = '';
            numberOfPlayers.value = '';
          }

          // Inform the player that they must enter select all categories before the lobby can be created
          else {

            this.errorMessage = 'You must select all categories before creating a lobby.';
            this.errorModalDisplay.style.display = 'block';
          }
        }

        // Must enter a number between 4 and 10
        else {

          this.errorMessage = 'Must enter a number between 4 and 10';
          this.errorModalDisplay.style.display = 'block';
        }
      }

      // The number of players is empty
      else {

        this.errorMessage = 'Must enter the total amount of user in the lobby (included you)';
        this.errorModalDisplay.style.display = 'block';
      }
    }

    // The host name is empty
    else {

      this.errorMessage = 'Must enter a name for the host';
      this.errorModalDisplay.style.display = 'block';
    }
  }

  /**
   * Close the error modal
   */
  public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Remove the button and add the category back to the list
   * 
   * @param category 
   */
  public removeCategory(category: string, round: string): void {

    // Remove the button
    let button = document.getElementById(category) as HTMLDivElement;
    button.remove();

    // Add the category back to the list
    this.categories.push(category);

    // A category was removed so the HOST is not done selecting categories
    this.doneSelecting = false;

    if(round === 'one') {

      // Check the first round for a match
      for(let i = 0; i < this.firstRound.length; i++) {

        if(category === this.firstRound[i]) {

          // Remove category
          this.firstRound.splice(i, 1);

          // exit Loop
          break;
        }
      }
    }

    else if(round === 'two') {

      // Check the second round for a match
      for(let i = 0; i < this.secondRound.length; i++) {

        if(category === this.secondRound[i]) {

          // Remove category
          this.secondRound.splice(i, 1);

          // exit Loop
          break;
        }
      }
    }

    else if(round === 'final') {

      // Check the third round for a match
      for(let i = 0; i < this.finalRound.length; i++) {

        if(category === this.finalRound[i]) {

          // Remove category
          this.finalRound.splice(i, 1);

          // exit Loop
          break;
        }
      }
    }
  }

  /**
   * The user is adding a category
   * 
   * @param category 
   */
  public addCategory(category: string): void {

    // Remove the category from the list
    for(let i = 0; i < this.categories.length; i++) {

      if(category === this.categories[i] && this.doneSelecting == false) {

        // Remove from the list
        this.categories.splice(i, 1);

        // Exit the loop
        break;
      }
    }

    // Add the category to the first round
    // No more than 6
    if(this.firstRound.length < 6) {

      this.firstRound.push(category);
    }

    // Add the category to the second round
    // No more than 6
    else if(this.secondRound.length < 6) {

      this.secondRound.push(category);
    }

    // Add the category to the final round
    // No more than 1
    else if(this.finalRound.length < 1) {

      this.finalRound.push(category);
    }

    // All the rounds are full
    if(this.firstRound.length === 6 && this.secondRound.length === 6 && this.finalRound.length === 1) {

      // The final round has been selected
      this.doneSelecting = true;
    }
  }

  /**
   * Return a random number between two numbers
   * 
   * @param min 
   * @param max 
   */
  private randomInt(min: number, max: number): number {

    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * Get all the categories from the database in order for the HOST to select from
   */
  public getCategories(): void {

    // Clear the list
    this.categories = [];

    // Determine if the lobby is public or private
    let typeOfPlay = document.getElementsByName('type-of-play') as NodeListOf<HTMLInputElement>;
    this.gamePlay = '';

    // Determine if this is regular play or teen play
    for(let i = 0; i < typeOfPlay.length; i++) {

      // If the radio button is selected then
      // store the radio button value
      if(typeOfPlay[i].checked == true) {

        this.gamePlay = typeOfPlay[i].value;

        break;
      }
    }

    // There are categories in the first round 
    if(this.firstRound.length > 0) {

      // Clear the list
      for(let i = this.firstRound.length - 1; i >= 0; i--) {

        this.firstRound.splice(i, 1);
      }
    }

    // There are categories in the second round
    if(this.secondRound.length > 0) {

      // Clear the list
      for(let i = this.secondRound.length - 1; i >= 0; i--) {

        this.secondRound.splice(i, 1);
      }
    }

    // There are categories in the final round
    if(this.finalRound.length > 0) {

      // Clear the list
      for(let i = this.finalRound.length - 1; i >= 0; i--) {

        this.finalRound.splice(i, 1);
      }
    }

    // Reset
    this.doneSelecting = false;

    axios.post(this.PORT + '/getJeopardyCategories', {typeOfPlay: this.gamePlay}).then((response) => {

      // Store the categories
      this.categories = response.data;

    }).catch((error) => {

      this.errorMessage = 'Unable to connect to the server';
      this.errorModalDisplay.style.display = 'block';
    });
  }
}

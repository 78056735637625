import * as Phaser from 'phaser';
import { GameGrid } from './game-grid';

export class GameLetters {

    private addLetterCurrentTime: number = 0;
    private addLetterStartTime: number = 0;
    private addRegularVowels: number = 0;
    private add: Phaser.GameObjects.GameObjectFactory;

    private readonly INITIAL_LETTER_AMOUNT: number = 16;
    private readonly TOTAL_LETTERS_IN_THE_ALPHABET: number = 26;
    private readonly letterValue: number[] = [1, 3, 3, 2, 1, 4, 2, 4, 1, 8, 5, 1, 3, 1, 1, 3, 10, 1, 1, 1, 1, 4, 4, 8, 4, 10];
    private readonly letterCharacter: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    constructor(private fallingLetters: any[], private gameGrid: GameGrid) {

    }

    public addInitialLetters(add: Phaser.GameObjects.GameObjectFactory): void {

        this.add = add;

        // Represent the vowels
        let vowels = [0, 4, 8, 14, 20];
        let letter: number = 0;
        let addVowels: number = 0;

        // Just add 16 letters 
        for(let i = 0; i < this.INITIAL_LETTER_AMOUNT; i++) {

            // Get a random vowel every 3 letter
            if(addVowels === 3) {

                letter = vowels[this.randomInt(0, 5)];

                // Reset to zero
                addVowels = 0;
            }

            // Add a regular letter (vowels or constants)
            else {

                letter = this.randomInt(0, this.TOTAL_LETTERS_IN_THE_ALPHABET);

                // Keep track for the next letter
                addVowels++;
            }

            // Determine which color letter to grab
            let letterColor: number = this.randomInt(0, 3);
            
            let letterPosition: number = letterColor * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;
            let selectedLetterPosition: number = 5 * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;

            // Generate a random position in the grid
            let availablePosition: boolean = false;

            // Loop until a position is found to be available
            while(availablePosition == false) {

                let col: number = this.randomInt(0, this.gameGrid.NUMBER_OF_COLS);

                // 7 and 20 represent the visible area on the gameboard
                let row: number = this.randomInt(this.gameGrid.VISIBLE_ROW_BEGINNING, this.gameGrid.NUMBER_OF_ROWS);

                // Position is not occupied
                if(this.gameGrid.grid[row][col].occupied === 0) {

                    // Create a new letter sprite
                    // at the assign location in the grid
                    let letterSprite = add.sprite(this.gameGrid.grid[row][col].x, this.gameGrid.grid[row][col].y, 'letters', letterPosition);
                    letterSprite.setOrigin(0, 0);
                    //letterSprite.scaleX = this.gameScreen.xScale;
                    //letterSprite.scaleY = this.gameScreen.yScale;

                    // Add the new letter sprite to the falling letter list
                    this.fallingLetters.push({
                        sprite: letterSprite,
                        xGrid: this.gameGrid.grid[row][col].x,
                        yGrid: this.gameGrid.grid[row][col].y,
                        col: col,
                        row: row,
                        letter: letter,
                        character: this.letterCharacter[letter],
                        pointValue: this.letterValue[letter],
                        letterPosition: letterPosition,
                        selectedLetterPosition: selectedLetterPosition,
                        moveLetterCurrentTime: 0,
                        moveLetterStartTime: new Date().getTime()
                    });

                    // Specify that the grid is occupied
                    this.gameGrid.grid[row][col].occupied = 1;

                    // Do not test the condition of the inner loop
                    availablePosition = true;

                    // Exit the loop (precautionary)
                    break;
                }
            }
        }

        // Initialize the start time for moving the letter and 
        // adding the letter to the board
        this.addLetterStartTime = new Date().getTime();
    }

    /**
     * Move the letters down the gameboard
     * 
     * @param currentTime 
     */
     public update(currentTime: number): void {

        this.addLetter(currentTime);
        this.moveLetters(currentTime);
    }

    /**
     * Return a random number between two numbers
     */
     private randomInt(min, max): number {

        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**
     * Move the letter down the board one space at a time
     * 
     * @param currentTime 
     */
     private moveLetters(currentTime: number): void {

        // Loop through all the letters in the list
        for(let i = 0; i < this.fallingLetters.length; i++) {

            // Get the letter current time
            this.fallingLetters[i].moveLetterCurrentTime = currentTime - this.fallingLetters[i].moveLetterStartTime;

            // If the current time is greater than or equal to 0.8 second then move
            // the letter to the next space
            if(this.fallingLetters[i].moveLetterCurrentTime >= 800) {

                // Get the current letter column and row
                let col: number = this.fallingLetters[i].col;
                let row: number = this.fallingLetters[i].row;

                // Determine the next row for the letter
                let nextRow = row + 1;

                // See if the letter is moving to the visible part of the gameboard
                if(nextRow === this.gameGrid.VISIBLE_ROW_BEGINNING) {

                    // The next space is not occupied
                    if(this.gameGrid.grid[nextRow][col].occupied === 0) {

                        let currentRow: number = this.fallingLetters[i].row;

                        // Update the letter new coordinate on the game board
                        this.fallingLetters[i].yGrid = this.gameGrid.grid[nextRow][col].y;
                        this.fallingLetters[i].row = nextRow;

                        // Inform the grid that the new space is occupied
                        this.gameGrid.grid[nextRow][col].occupied = 1;

                        // Inform the grid that the old space is unoccupied
                        this.gameGrid.grid[currentRow][col].occupied = 0;

                        // Display the letter on the gameboard
                        this.fallingLetters[i].sprite.setVisible(true);


                        /*****************************************************************
                         * 
                         * See if the next 2 spaces are occupied. If so then check and
                         * see if the column is full.
                         * 
                         *****************************************************************/
                         let row8: number = nextRow + 1;
                         let row9: number = nextRow + 2;
 
                         // Verify the next two rows are occupied. If so, see if the column
                         // is full
                         if(this.gameGrid.grid[row8][col].occupied === 1 && this.gameGrid.grid[row9][col].occupied === 1) {
 
                             this.gameGrid.verifyColumn(col);
                         } 
                    }
                }

                // The letter is still not on the visible part of the gameboard.
                // Move the letter to its new position but do not display the letter
                else if(nextRow < this.gameGrid.VISIBLE_ROW_BEGINNING) {

                    // The next space is not occupied
                    if(this.gameGrid.grid[nextRow][col].occupied === 0) {

                        let currentRow: number = this.fallingLetters[i].row;

                        // The next space is available on the game board
                        // Move the letter to its new position
                        this.fallingLetters[i].yGrid = this.gameGrid.grid[nextRow][col].y;
                        this.fallingLetters[i].row = nextRow;

                        // Inform the grid that the new space is occupied
                        this.gameGrid.grid[nextRow][col].occupied = 1;

                        // Inform the grid that the old space is unoccupied
                        this.gameGrid.grid[currentRow][col].occupied = 0;
                    }
                }

                // The letter is visible and active on the gameboard
                else {

                    // Make sure the next row is still on the gameboard
                    if(nextRow < this.gameGrid.NUMBER_OF_ROWS) {

                        // The next space is not occupied
                        if(this.gameGrid.grid[nextRow][col].occupied === 0) {

                            let currentRow: number = this.fallingLetters[i].row;

                            // The next space is available on the game board
                            // Move the letter to its new position
                            this.fallingLetters[i].yGrid = this.gameGrid.grid[nextRow][col].y;
                            this.fallingLetters[i].row = nextRow;

                            // Inform the grid that the new space is occupied
                            this.gameGrid.grid[nextRow][col].occupied = 1;

                            // Inform the grid that the old space is unoccupied
                            this.gameGrid.grid[currentRow][col].occupied = 0;
                        }
                    }
                }

                // Move the letter to its new position on the gameboard
                this.fallingLetters[i].sprite.x = this.fallingLetters[i].xGrid;
                this.fallingLetters[i].sprite.y = this.fallingLetters[i].yGrid;

                // Reset the timing
                this.fallingLetters[i].moveLetterCurrentTime = 0;
                this.fallingLetters[i].moveLetterStartTime = new Date().getTime();
            }
        }
    }

    /**
     * Add a new letter to the board every 1.2 seconds
     * 
     * @param currentTime 
     */
     private addLetter(currentTime: number): void {

        this.addLetterCurrentTime = currentTime - this.addLetterStartTime;
        
        if(this.addLetterCurrentTime >= 1200) {

            this.getLetter();

            // Reset the time
            this.addLetterCurrentTime = 0;
            this.addLetterStartTime = new Date().getTime();
        }
    }

    private getLetter(): void {

        // Represent the vowels
        let vowels: number[] = [0, 4, 8, 14, 20];
        let letter: number = 0;

        // Get a random vowel every 3 letter
        if(this.addRegularVowels === 3) {

            letter = vowels[this.randomInt(0, 5)];

            // Reset to zero
            this.addRegularVowels = 0;
        }

        // Add a regular letter (vowels or constants)
        else {

            letter = this.randomInt(0, this.TOTAL_LETTERS_IN_THE_ALPHABET);

            // Keep track for the next letter
            this.addRegularVowels++;
        }

        // Determine which color letter to grab
        let letterColor: number = this.randomInt(0, 3);

        // Calculate the letter position in the spritesheet
        let letterPosition: number = letterColor * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;
        let selectedLetterPosition: number = 5 * this.TOTAL_LETTERS_IN_THE_ALPHABET + letter;

        // Determine the letter position within the grid
        let col: number = this.randomInt(0, this.gameGrid.NUMBER_OF_COLS);
        let row: number = 6;

        // Create a new letter sprite
        // at the assign location in the grid
        let letterSprite = this.add.sprite(this.gameGrid.grid[row][col].x, this.gameGrid.grid[row][col].y, 'letters', letterPosition); 
        letterSprite.setOrigin(0, 0);
        letterSprite.setVisible(false);

        // Add the new letter sprite to the falling letter list
        this.fallingLetters.push({
            sprite: letterSprite,
            xGrid: this.gameGrid.grid[row][col].x,
            yGrid: this.gameGrid.grid[row][col].y,
            col: col,
            row: row,
            letter: letter,
            character: this.letterCharacter[letter],
            pointValue: this.letterValue[letter],
            letterPosition: letterPosition,
            selectedLetterPosition: selectedLetterPosition,
            moveLetterCurrentTime: 0,
            moveLetterStartTime: new Date().getTime()
        });
    }
}
<div class="w3-container">
    <div id="pong-survivor-screen" style="margin-top: 20px;"></div>

    <!-- Game controls -->
    <div class="w3-container" style="margin-bottom: 20px;">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Game Controls:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Move paddle up or down: Mouse movement</label>
        </div>
    </div>
</div>
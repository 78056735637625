import * as Phaser from 'phaser';

export class Dictionary {

    private cache: Phaser.Cache.CacheManager;

    constructor() {

    }

    /**
     * Setup the cache manager
     * 
     * @param cache 
     */
    public create(cache: Phaser.Cache.CacheManager): void {

        this.cache = cache;
    }

    /**
     * Verify that the word is spelled correctly 
     * 
     * @param word 
     * @returns 
     */
    public isSpelledCorrectly(word: string, points: number): boolean {

        // Inform the player that the word is spelled correctly
        let isCorrect: boolean = false;

        // Check the dictionary to see if the word already exist
        if(this.cache.text.get('dictionary').indexOf(word.toLowerCase()) > -1) {

            isCorrect = true;
        }

        return isCorrect;
    }
}
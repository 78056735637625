import { Component, OnInit } from '@angular/core';
import { WordBlitzLobbyService } from '../word-blitz-challenge/word-blitz-lobby/word-blitz-lobby.service';

@Component({
  selector: 'app-word-blitz-challenge-stats',
  templateUrl: './word-blitz-challenge-stats.component.html',
  styleUrls: ['./word-blitz-challenge-stats.component.scss']
})
export class WordBlitzChallengeStatsComponent implements OnInit {

  myWordList: string = '';
  opponentWordList: string = '';

  constructor(private service: WordBlitzLobbyService) { }

  ngOnInit(): void {

    this.myWordList = this.service.getMyWordList();
    this.opponentWordList = this.service.getOpponentWordList();
  }
}

import * as Phaser from 'phaser';
import { ButtonEvent, ButtonEventDispatcher } from './button-event-dispatcher';

export class ClearButton extends ButtonEventDispatcher {

    private button: Phaser.GameObjects.Sprite;

    constructor() {

        super();
    }

    /**
     * Create the button
     */
    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        // Create the submit button
        this.button = add.sprite(279, 1206, 'buttons', 1);
        this.button.setOrigin(0, 0);
        this.button.setInteractive();

        this.buttonControls();
    }

    /**
     * Disable the button
     */
    public disable(): void {

        this.button.setVisible(false);
        this.button.disableInteractive();
    }

    /**
     * Remove the submit button resources
     */
    public destroy(): void {

        this.button.destroy();
    }

    private buttonControls(): void {

        // The prayer pressed the button
        this.button.on('pointerdown', () => {

            // Change the button color
            this.button.setFrame(9);
        });

        // The player released the button
        this.button.on('pointerup', () => {

            this.dispatchEvent(new ButtonEvent('clear-button-pressed', ''));

            // Change the button back to its origina color
            this.button.setFrame(1);
        });
    }
}
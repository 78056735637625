import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { HowManyLobbyService } from '../how-many-lobby/how-many-lobby.service';

import { GuestPeer } from '../how-many-lobby/peer/guestPeer';

@Component({
  selector: 'app-how-many-guest-lobby',
  templateUrl: './how-many-guest-lobby.component.html',
  styleUrls: ['./how-many-guest-lobby.component.scss']
})
export class HowManyGuestLobbyComponent implements OnInit {

  private guestPeer: GuestPeer;
  private readonly PORT: string = 'https://amesmi.openode.dev'; //'http://localhost:3000'; //'https://amesmi.openode.io';

  lobbyType: string = 'not-available';
  lobbyName: string = '';

  // Represent the guest lobby status
  guestLobbyStatus: string = 'lobby-waiting-area';
  hostName: string = '';
  activePlayers: string[] = [];
  //requestingPlayers: any[] = [];
  hostPhrase: string = '';
  wordList: string[] = [];
  countDownClock: string = "";
  gameStatus: string = 'waiting-for-host';
  gameManager: string = '';
  validWords = [];

  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  private letterCount = [];
  playersResults = [];

  constructor(private service: HowManyLobbyService, private route: Router, private ngZone: NgZone, private httpClient: HttpClient) { }

  ngOnInit(): void {

    // The user successfully setup the lobby
    if(this.service.getLobbyStatus() == true) {

      if(this.service.getIsHost() == false) {

        this.lobbyType = 'guest-lobby';
        this.hostName = this.service.getHostName();
        this.lobbyName = this.service.getLobbyName();

        //this.activePlayers.push(this.hostName + ' (HOST)');

        // Place the guest in the waiting area of the lobby
        this.guestLobbyStatus = 'lobby-waiting-area';
        this.gameStatus = 'waiting-for-host';

        // Create the GUEST peer
        this.guestPeer = new GuestPeer(this.service.getGuestID(), this.service.getLobbyName());

        this.guestEventListener();
      }
    }

    // The lobby was not setup properly
    else {

      // The service for the lobby was not setup properly
      this.lobbyType = 'not-available';
    }

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  ngAfterViewInit(): void {

    if(this.service.getLobbyStatus() == true) {

      if(this.service.getIsHost() == false) {

        
      }
    }
  }

  ngOnDestroy(): void {

    // Close all connections
    if(this.guestPeer !== null && this.guestPeer !== undefined) {

      this.guestPeer.closeSocket();
      this.guestPeer.close();

      console.log('Guest left the game');
    }
  }

  /**
   * Close the error modal
   */
  public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
    this.route.navigateByUrl('/how-many-home');
  }

  public submitWord(): void {

    let guestWord = document.getElementById('guest-word') as HTMLInputElement;

    if(guestWord.value != '') {

      if(this.wordList.length > 0) {

        let isAvailable: boolean = true;

        // Make sure the word has not been submitted to the list already
        for(let i = 0; i < this.wordList.length; i++) {

          // The word is not available
          if(guestWord.value === this.wordList[i]) {

            isAvailable = false;

            break;
          }
        }

        // Add the word to the word list
        if(isAvailable == true) {

          // Add the word to the word list
          this.wordList.push(guestWord.value);
        }
      }

      // This is the first word in the list. Add the word
      else {

        // Add the word to the word list
        this.wordList.push(guestWord.value);
      }

      // Clear the word value
      guestWord.value = '';
    }
  }

  public removeWord(word: string): void {

    for(let i = 0; i < this.wordList.length; i++) {

      if(word === this.wordList[i]) {

        // Remove word from list
        this.wordList.splice(i, 1);

        // Exit loop
        break;
      }
    }
  }

  /**
   * Return the player to the home screen
   */
   public homeButton(): void {

    this.route.navigateByUrl('/how-many-home');
  }

  private guestEventListener(): void {

    // The GUEST cannot enter lobby
    this.guestPeer.addEventListener('cannot-enter-lobby', (event) => {

      // Get the lobby name and the player ID
      let object = {
        lobbyName: this.service.getLobbyName(),
        playerID: this.service.getGuestID()
      }

      axios.post(this.PORT + '/removeGuestFromHowMany', object).then((response) => {

        let status = response.data;

        if(status == true) {

          // Close the socket connect for the guest
          this.guestPeer.closeSocket();

          // Send the guest back the How Many home screen
          //alert('Access has been denied. Try another lobby!');
          this.errorMessage = 'Access has been denied. Try another lobby! You will be sent home!';
          this.errorModalDisplay.style.display = 'block';
        }

        else {

          // Could not remove the GUEST from the lobby
          // Please try again
          this.errorMessage = 'Unable to remove you from the lobby.';
          this.errorModalDisplay.style.display = 'block';
        }
      }).catch((error) => {

        // Unable to connect to the server
        this.errorMessage = 'Unable to connect to server';
        this.errorModalDisplay.style.display = 'block';
      });
    });

    // The GUEST can enter the lobby
    this.guestPeer.addEventListener('enter-lobby', (event) => {

      // Place the guest in the lobby
      this.guestLobbyStatus = 'enter-lobby';

      //this.activePlayers.push(this.service.getGuestName());

      // Start the WebRTC connection to the HOST
      this.guestPeer.connect(this.service.getHostID(), this.service.getHostName());
    });

    /**
     * Inform the player that a connection has been establish with the HOST
     */
    this.guestPeer.addEventListener('datachannel-open', (event) => {

    });

    /**
     * Process all incoming messages from the HOST
     */
    this.guestPeer.addEventListener('datachannel-message', (event) => {

      let data = JSON.parse(event.message);

      // Add the active players to the Guest board
      if(data.action === 'update-players-in-game') {

        this.ngZone.run(() => {

          this.activePlayers = data.players;
        });
      }

      // Inform the guest that the game is starting
      else if(data.action === 'start-game') {

        this.ngZone.run(() => {

          this.hostPhrase = data.hostPhrase;
          this.gameStatus = 'game-has-started';
          this.gameManager = 'game-is-active';
          this.letterCount = data.letterCount;
        });

        // Game has started so sockets are no longer needer
        this.guestPeer.closeSocket();
      }

      // Update the guest clock every second
      else if(data.action === 'update-time') {

        this.ngZone.run(() => {

          this.countDownClock = data.time;
        });
      }

      else if(data.action === 'game-is-over') {

        this.ngZone.run(() => {

          this.gameManager = 'game-is-over';
          this.gameStatus = 'game-is-over';

          // Calculate the player score
          this.checkValidityOfWord();
        });
      }

      else if(data.action === 'display-game-results') {

        this.ngZone.run(() => {

          this.playersResults = data.gameResults;

          console.log(this.playersResults);

          // Send the results to all the players
          //this.hostPeer.sendMessageToAllPeers(JSON.stringify(object));

          let displayGameResults = document.getElementById('display-game-results') as HTMLDivElement;

          // Loop through all the players
          for(let i = 0; i < this.playersResults.length; i++) {

            let divElement = document.createElement('div');
            divElement.className = 'w3-border w3-border-gray w3-round-medium';
            divElement.style.width = '300px';
            divElement.style.marginBottom = '15px';

            // Create the player bar title
            let playerBar = document.createElement('div') as HTMLDivElement;
            playerBar.className = 'w3-bar';
            //playerBar.className = "w3-bar w3-border w3-border-gray w3-round-medium";
            playerBar.style.width = "300px";
            //playerBar.style.marginRight = '10px';

            // Store the player name
            let playerName = document.createElement('div') as HTMLDivElement;
            playerName.className = "w3-bar-item w3-text-blue-gray w3-left";
            playerName.innerHTML = this.playersResults[i].name;

            // Store the player score
            let playerScore = document.createElement('div') as HTMLDivElement;
            playerScore.className = "w3-bar-item w3-text-deep-orange w3-right";
            playerScore.innerHTML = this.playersResults[i].score;

            let line = document.createElement('hr') as HTMLHRElement;
            line.style.margin = '0px';

            // Store the player and score information in the bar
            playerBar.append(playerName);
            playerBar.append(playerScore);
            divElement.append(playerBar);

            // Add a divider
            divElement.append(line);

            // Create a word bar
            let wordBar = document.createElement('div') as HTMLDivElement;
            wordBar.className = 'w3-bar';

            // loop through all the words that each player spelled
            for(let j = 0; j < this.playersResults[i].results.length; j++) {

              // The word used the appropriate letters in the given phrase
              if(this.playersResults[i].results[j].valid == true) {

                // The word was spelled correctly 
                if(this.playersResults[i].results[j].isSpelledCorrectly == true) {

                  // No other player spelled this word
                  if(this.playersResults[i].results[j].isUnique == true) {
                    
                    let word = document.createElement('label') as HTMLLabelElement;
                    word.className = 'w3-bar-item w3-text-black';
                    word.innerHTML = this.playersResults[i].results[j].word;
                    
                    // Add the word to the bar
                    wordBar.append(word);
                  }

                  // Other players spelled the same word
                  else if(this.playersResults[i].results[j].isUnique == false) {

                    let word = document.createElement('label') as HTMLLabelElement;
                    word.className = 'w3-bar-item w3-text-green';
                    word.innerHTML = this.playersResults[i].results[j].word;

                    // Add the word to the bar
                    wordBar.append(word);
                  }
                }

                // The word was NOT spelled correctly
                else if(this.playersResults[i].results[j].isSpelledCorrectly == false) {

                  let word = document.createElement('label') as HTMLLabelElement;
                  word.className = 'w3-bar-item w3-text-red';
                  word.innerHTML = this.playersResults[i].results[j].word;

                  // Add the word to the bar
                  wordBar.append(word);
                }
              }

              // The word did not use the appropriate letters
              else if(this.playersResults[i].results[j].valid == false) {

                let word = document.createElement('label') as HTMLLabelElement;
                word.className = 'w3-bar-item w3-text-purple w3-opacity';
                word.style.textDecoration = 'line-through';
                word.innerHTML = this.playersResults[i].results[j].word;

                // Add the word to the bar
                wordBar.append(word);
              }
            }

            // Add the word bar to the display view
            divElement.append(wordBar);

            // Store the player bar
            displayGameResults.append(divElement);
          }

          // Close the guest connection 
          this.guestPeer.close();
        });
      }
    });
  }

  private checkValidityOfWord(): void {

    let wordLetterCount = [
      {letter: 'a', count: 0},  {letter: 'b', count: 0},  {letter: 'c', count: 0},  {letter: 'd', count: 0},  {letter: 'e', count: 0},
      {letter: 'f', count: 0},  {letter: 'g', count: 0},  {letter: 'h', count: 0},  {letter: 'i', count: 0},  {letter: 'j', count: 0},
      {letter: 'k', count: 0},  {letter: 'l', count: 0},  {letter: 'm', count: 0},  {letter: 'n', count: 0},  {letter: 'o', count: 0},
      {letter: 'p', count: 0},  {letter: 'q', count: 0},  {letter: 'r', count: 0},  {letter: 's', count: 0},  {letter: 't', count: 0},
      {letter: 'u', count: 0},  {letter: 'v', count: 0},  {letter: 'w', count: 0},  {letter: 'x', count: 0},  {letter: 'y', count: 0},
      {letter: 'z', count: 0}
    ]

    for(let i = 0; i < this.wordList.length; i++) {

      // Convert to lower case and trim all whitespaces
      let word: string = this.wordList[i];

      for(let j = 0; j < word.length; j++) {

        // Get the character at the specified position
        let letter: string = word.charAt(j).toLowerCase().trim();

        // Calulate the amount of times the letter shows up in the phrase
        switch(letter) {

          case 'a': wordLetterCount[0].count++; break;
          case 'b': wordLetterCount[1].count++; break;
          case 'c': wordLetterCount[2].count++; break;
          case 'd': wordLetterCount[3].count++; break;
          case 'e': wordLetterCount[4].count++; break;
          case 'f': wordLetterCount[5].count++; break;
          case 'g': wordLetterCount[6].count++; break;
          case 'h': wordLetterCount[7].count++; break;
          case 'i': wordLetterCount[8].count++; break;
          case 'j': wordLetterCount[9].count++; break;
          case 'k': wordLetterCount[10].count++; break;
          case 'l': wordLetterCount[11].count++; break;
          case 'm': wordLetterCount[12].count++; break;
          case 'n': wordLetterCount[13].count++; break;
          case 'o': wordLetterCount[14].count++; break;
          case 'p': wordLetterCount[15].count++; break;
          case 'q': wordLetterCount[16].count++; break;
          case 'r': wordLetterCount[17].count++; break;
          case 's': wordLetterCount[18].count++; break;
          case 't': wordLetterCount[19].count++; break;
          case 'u': wordLetterCount[20].count++; break;
          case 'v': wordLetterCount[21].count++; break;
          case 'w': wordLetterCount[22].count++; break;
          case 'x': wordLetterCount[23].count++; break;
          case 'y': wordLetterCount[24].count++; break;
          case 'z': wordLetterCount[25].count++; break;
        }
      }

      // Was the right amount of letters used for the word
      let isWordValid: boolean = true;

      for(let j = 0; j < this.letterCount.length; j++) {

        // The word is not valid if 1 letter is misused
        if(wordLetterCount[j].count > this.letterCount[j].count) {

          isWordValid = false;

          // Exit loop
          break;
        }
      }

      // Store the results of the word validity check
      this.validWords.push({
        word: word,
        valid: isWordValid,
        isSpelledCorrectly: false,
        isUnique: false
      });

      // Reset the count to zero
      for(let j = 0; j < wordLetterCount.length; j++) {

        wordLetterCount[j].count = 0;
      }
    }

    // Check the dictionary for correct spelling
    this.checkDictionary();
  }

  private checkDictionary(): void {
  
    let object = {
      words: this.validWords
    }

    // Verify the word in the dictionary
    axios.post(this.PORT + '/checkDictionary', object).then((response) => {

      let list = response.data;

      // Store the results of all the players
      let myResults = {
        name: this.service.getGuestName(),
        results: list,
        score: 0
      };

      let object = {
        action: 'guest-word-list',
        results: myResults
      }

      // Send my results to the host
      this.guestPeer.sendMessage(JSON.stringify(object));

    }).catch((error) => {

      this.errorMessage = 'Unable to connect to the server';
      this.errorModalDisplay.style.display = 'block';
    });
  }
}

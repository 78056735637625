export class HostVideo {

    /**
     * Allow the user to turn the camera on/off and microphone on/off
     */
    private constraints = { video: true, audio: true };

    /**
     * The HOST id
     */
    private id: string = '';

    public stream: MediaStream;

    constructor() {

    }

    public async getCamera(id: string) {

        this.id = id;

        try {

            this.stream = await navigator.mediaDevices.getUserMedia(this.constraints);
            this.handleSuccess(this.stream);
        }

        catch(e) {

            console.log('Unable to get stream');
        }
    }

    private handleSuccess(stream: MediaStream): void {

        // Get the host video element
        let video = document.getElementById('video-' + this.id) as HTMLVideoElement;

        // Get the video track
        let videoTrack = stream.getVideoTracks();

        // Which device is being used
        console.log(`Using video device: ${videoTrack[0].label}`);

        // Start the video stream
        video.srcObject = stream;
    }
}
<!-- Display all the rules for Word Blitz -->
<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray w3-round-large">
        <p class="w3-xlarge w3-serif">Word Blitz Rules</p>
    </div>

    <!-- Letter Value -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow:1px 1px 0 #444">Letter Value</h4>
    </div>

    <div class="w3-container" style="margin-bottom: 20px;">
        <table class="w3-table-all" style="max-width: 400px; margin-bottom: 20px;">
            <tr><td>A</td><td>B</td><td>C</td><td>D</td><td>E</td><td>F</td></tr>
            <tr><td>1</td><td>3</td><td>3</td><td>2</td><td>1</td><td>4</td></tr>
        </table>

        <table class="w3-table-all" style="max-width: 400px; margin-bottom: 20px;">
            <tr><td>G</td><td>H</td><td>I</td><td>J</td><td>K</td><td>L</td></tr>
            <tr><td>2</td><td>4</td><td>1</td><td>8</td><td>5</td><td>1</td></tr>
        </table>

        <table class="w3-table-all" style="max-width: 400px; margin-bottom: 20px;">
            <tr><td>M</td><td>N</td><td>O</td><td>P</td><td>Q</td><td>R</td><td>S</td></tr>
            <tr><td>3</td><td>1</td><td>1</td><td>3</td><td>10</td><td>1</td><td>1</td></tr>
        </table>

        <table class="w3-table-all" style="max-width: 400px;">
            <tr><td>T</td><td>U</td><td>V</td><td>W</td><td>X</td><td>Y</td><td>Z</td></tr>
            <tr><td>1</td><td>1</td><td>4</td><td>4</td><td>8</td><td>4</td><td>10</td></tr>
        </table>
    </div>

    <!-- Regular Play Title -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow:1px 1px 0 #444">Regular Play</h4>
    </div>
    
    <ul class="w3-ul" style="margin-bottom: 20px;">
        <!-- 25% Letter removals -->
        <li class="w3-bar">
            <div>
                <img class="w3-bar-item" src="assets/images/projects/games2D/word-blitz/rules/remove25.png" style="max-width: 60px;">
            </div>
            
            <div class="w3-bar-item">
                <div class="w3-text-gray" style="font-size: 14px;">Remove 25% of the letters.</div>
            </div>
        </li>

        <!-- 50% Letter removals -->
        <li class="w3-bar">
            <img class="w3-bar-item w3-image" src="assets/images/projects/games2D/word-blitz/rules/remove50.png" style="max-width: 60px;">

            <div class="w3-bar-item">
                <div class="w3-text-gray" style="font-size: 14px;">Remove 50% of the letters.</div>
            </div>
        </li>

        <!-- 75% Letter removals -->
        <li class="w3-bar">
            <img class="w3-bar-item w3-image" src="assets/images/projects/games2D/word-blitz/rules/remove75.png" style="max-width: 60px;">

            <div class="w3-bar-item">
                <div class="w3-text-gray" style="font-size: 14px;">Remove 75% of the letters.</div>
            </div>
        </li>

        <!-- Select and move letter -->
        <li class="w3-bar">
            <img class="w3-bar-item w3-image" src="assets/images/projects/games2D/word-blitz/rules/letter.png" style="max-width: 60px;">

            <div class="w3-bar-item">
                <div class="w3-text-gray" style="font-size: 14px;">Tap letter to spell a word.</div>
                <div class="w3-text-gray" style="font-size: 14px;">Press and drag letter.</div>
            </div>
        </li>

        <!-- Press submit -->
        <li class="w3-bar">
            <div>
                <img class="w3-image" src="assets/images/projects/games2D/word-blitz/rules/submit.png" style="max-width: 100px;">
            </div>

            <div>
                <div class="w3-text-gray" style="font-size: 14px;">Submit word to the dictionary.</div>
                <div class="w3-text-gray" style="font-size: 14px;"><label class="w3-text-deep-orange">Spelled correctly</label> - add points to score.</div>
                <div class="w3-text-gray" style="font-size: 14px;"><label class="w3-text-deep-orange">Spelled incorrectly</label> - subtract points from score. Place letters back on board.</div>
            </div>
        </li>

        <!-- Press clear -->
        <li class="w3-bar">
            <div>
                <img class="w3-image" src="assets/images/projects/games2D/word-blitz/rules/clear.png" style="max-width: 100px;">
            </div>
            
            <div>
                <div class="w3-text-gray" style="font-size: 14px;">Remove selected letters.</div>
                <div class="w3-text-gray" style="font-size: 14px;">Place letters back on board.</div>
            </div>
        </li>

        <!-- Press words -->
        <li class="w3-bar">
            <div>
                <img class="w3-image" src="assets/images/projects/games2D/word-blitz/rules/words.png" style="max-width: 100px; ">
            </div>
            
            <div>
                <div class="w3-text-gray" style="font-size: 14px;">Display spelled words.</div>
            </div>
        </li>

        <!-- Press words -->
        <li class="w3-bar">
            <div>
                <div class="w3-text-blue-gray w3-light-gray w3-round-large" style="font-size: 16px; max-width: 110px; padding: 5px;">Game Over</div>
            </div>
           
            <div>
                <div class="w3-text-gray" style="font-size: 14px;">When a column is completely filled with letters.</div>
            </div>
        </li>
    </ul>

    <!-- Level Play Title -->
    <div class="w3-container w3-blue-gray w3-round-xlarge" style="margin-bottom: 20px;">
        <h4 style="text-shadow:1px 1px 0 #444">Level Play</h4>
    </div>

    <div class="w3-container">

        <div *ngFor="let level of levelDescription" style="margin-bottom: 10px;">
            <label class="w3-text-blue-gray" style="margin-right: 10px;">{{ level.title }}:</label>
            <label class="w3-text-black">{{ level.description }}</label>
        </div>
    </div>

    <!-- Return the player to the word blitz home page -->
    <div routerLink="/word-blitz-home" class="w3-button w3-blue-gray w3-round-large icofont icofont-home w3-large" title="Word Blitz Home" style="margin-top: 15px; margin-bottom: 10px;">Home</div>
</div>
import * as Phaser from 'phaser';

export class Levels {

    private readonly Total_Levels: number = 5;

    public display: boolean = false;
    public completedGame: boolean = false;

    // Keep track of
    private currentLevel: number = 0;

    private displayCurrentTime: number;
    private displayStartTime: number;

    private levelText: Phaser.GameObjects.Text;
    private levelImage: Phaser.GameObjects.Sprite;

    constructor() {}

    /**
     * Create the level
     * 
     * @param add 
     */
    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.levelImage = add.sprite(100, 125, 'levels', 0).setOrigin(0, 0);
        this.levelImage.setVisible(this.display);

        this.levelText = add.text(685, 3, this.currentLevel.toString(), {fontSize: '35px', color: 'rgb(255, 255, 0)', align: 'left'});
        this.levelText.setOrigin(0, 0);
        this.levelText.setVisible(true);
    }

    /**
     * Loop to the next Level
     */
    public nextLevel(): void {

        this.currentLevel += 1;
        this.display = true;

        // Game is still active
        if(this.currentLevel <= this.Total_Levels) {

            // Display the current level
            this.levelText.text = this.currentLevel.toString();
        
            // Get the current image
            this.levelImage.setFrame(this.currentLevel - 1);
            this.levelImage.setVisible(this.display);
        }

        // Player has defeated the game
        else {

            this.completedGame = true;
        }   

        // Get the start time
        this.displayStartTime = new Date().getTime();
    }

    /**
     * Display the next Level for 3 seconds
     */
    public update(currentTime: number): void {

        this.displayCurrentTime = currentTime - this.displayStartTime;

        // Start the next level after 3 seconds
        if(this.displayCurrentTime >= 3000) {

            this.display = false;

            // Turn level display off
            this.levelImage.setVisible(this.display);

            // Reset the time
            this.displayCurrentTime = 0;
            this.displayStartTime = new Date().getTime();
        }
    }

    /**
     * Get the current level
     */
    public getCurrentLevel(): number {

        return this.currentLevel;
    }

    /**
     * Remove the level resources
     */
    public destroy(): void {

        this.levelImage.destroy();

        this.display = false;
        this.completedGame = false;
        this.currentLevel = 0;

        this.displayCurrentTime = 0;
        this.displayStartTime = 0;

        this.levelText.destroy();
    }
}
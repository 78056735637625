import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MazeEscapeHomeComponent } from './maze-escape-home/maze-escape-home.component';

const providers = [];

@NgModule({})
export class MazeEscapeSharedModule {

  static forRoot(): ModuleWithProviders<AppModule> {

    return {

      ngModule: AppModule,
      providers: providers
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MazeEscapeHomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="w3-container">
    <div class="w3-panel w3-leftbar w3-light-gray w3-round-large">
        <p class="w3-xlarge w3-serif">Word Blitz Level Stats</p>
    </div>

    <ul class="w3-ul">
        <!-- Display the player score earned during the game -->
        <li class="w3-bar">
            <div class="w3-bar-item w3-text-blue-gray" style="font-size: 20px; width: 200px;">Score</div>
            <div class="w3-bar-item w3-text-orange w3-leftbar" style="font-size: 20px;">{{ playerScore }}</div>
        </li>

        <!-- Display the total words spelled during the game -->
        <li class="w3-bar">
            <div class="w3-bar-item w3-text-blue-gray" style="font-size: 20px; width: 200px;">Total Words</div>
            <div class="w3-bar-item w3-text-orange w3-leftbar" style="font-size: 20px;">{{ totalWords }}</div>
        </li>

        <!-- Highest Level Completed -->
        <li class="w3-bar">
            <div class="w3-bar-item w3-text-blue-gray" style="font-size: 20px; width: 200px;">Highest Level</div>
            <div class="w3-bar-item w3-text-orange w3-leftbar" style="font-size: 20px;">{{ maxLevel }} x 100</div>
        </li>

        <!-- Display the final score -->
        <li class="w3-bar">
            <div class="w3-bar-item w3-text-blue-gray" style="font-size: 20px; width: 200px;">Final Score</div>
            <div class="w3-bar-item w3-text-orange w3-leftbar" style="font-size: 20px;">{{ finalScore }}</div>
        </li>

        <!-- Only display the rank if they player is ranked in the top 25 -->
        <li *ngIf="rank !== 0" class="w3-bar w3-light-gray">
            <div class="w3-bar-item w3-text-blue-gray" style="font-size: 20px; width: 200px;">Rank</div>
            <div class="w3-bar-item w3-text-red w3-leftbar" style="font-size: 20px;">{{ rank }}</div>
        </li>

        <!-- Display the words for each level -->
        <li *ngFor="let current of wordList" class="w3-bar">
            <div class="w3-bar-item">
                <div class="w3-text-blue-gray" style="font-size: 20px;"><label class="w3-text-deep-orange">Level {{ current.level }}:</label> {{ current.title }}</div>
                <div class="w3-text-black">{{ current.words }}</div>
            </div>
        </li>

        <!-- Buttons -->
        <li class="w3-bar" style="width: 450px;">

            <!-- Return the player to the main game page in order to select another game -->
            <div class="w3-bar-item w3-button w3-hover-white icofont icofont-home w3-text-blue-gray" title="Word Blitz Home" style="font-size: 40px;" routerLink="/word-blitz-home"></div>
            
            <!-- Return the player to the Word Blitz home page -->
            <div class="w3-bar-item w3-button w3-hover-white icofont icofont-play-alt-3 w3-text-blue-gray" title="Play Again" style="font-size: 40px;" routerLink="/word-blitz-levels"></div>
            
            <!-- The player is rank so allow the player to save their score -->
            <div *ngIf="rank !== 0" class="w3-bar-item w3-button w3-hover-white icofont icofont-save w3-text-red" title="Save" style="font-size: 35px;" (click)="saveButton()"></div>
        </li>
    </ul>

    <!-- Display a w3-modal that allows the player to enter their name -->
    <div id="enter-name-modal" class="w3-modal">
        <div class="w3-modal-content w3-card-4 w3-animate-zoom" style="margin-left: auto; margin-right: auto; max-width: 300px;">
            <div class="w3-container w3-round-medium">
                <h1 class="w3-xlarge w3-text-blue-gray">Rank <label class="w3-text-red">{{ rank }}</label> of 25</h1>
                <label class="w3-text-green" style="font-size: 13px;">Enter name <label class="w3-text-gray w3-opacity-max">(6 Character Minimum)</label></label>
                <input class="w3-input w3-border" type="text" id="player-name">
                <br />

                <!-- The the player the option to return to the menu and/or save the results -->
                <button (click)="okButton()" class="w3-btn w3-opacity-min w3-blue w3-round">Ok</button>
                <button (click)="cancelButton()" class="w3-right w3-btn w3-opacity-min w3-red w3-round">Cancel</button>
                <br /><br />
                <label class="w3-text-red">{{ warningMessage }}</label>
            </div>
        </div>
    </div>
</div>

import * as Phaser from 'phaser';
import { GameScreen } from '../../screens/gameScreen';
import { PlayerControls } from './player-controls';

export class Player extends PlayerControls {

    constructor(characterPosition: number) {

        super();

        // Starting position for each color
        this.position = characterPosition * 7;
        this.colorPosition = characterPosition;
    }

    /**
     * Create the player controllable character
     * 
     * @param scene 
     */
    public create(scene: GameScreen): void {

        this.createControls(scene);

        // Create animation from a spritesheet
        scene.anims.create({key: 'walkLeft', frameRate: 5, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position, this.position + 6] }), repeat: -1});
        scene.anims.create({key: 'walkRight', frameRate: 5, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position, this.position + 5] }), repeat: -1});
        scene.anims.create({key: 'standing', frameRate: 0, frames: scene.anims.generateFrameNumbers('fighters', { frames: [this.position] }), repeat: -1});

        // Create the character
        //this.character = add.sprite(100, GameData.ground, 'fighters', this.position).setOrigin(0, 0);
        this.character = scene.physics.add.sprite(100, 20, 'fighters', this.position).setOrigin(0, 0);
        this.character.setVisible(true);

        // Do not allow the player to move past the game walls
        this.character.setGravityY(300);
        this.character.setBounce(0.2);
        this.character.setCollideWorldBounds(true);

        // Create the energy bar for the player
        this.energyBar = scene.add.sprite(0, 0, 'energybar', this.colorPosition);
        this.energyBar.setOrigin(0, 0);
        this.energyBar.setVisible(true);

        this.energyContainer = scene.add.sprite(0, 0, 'energybar', 6);
        this.energyContainer.setOrigin(0, 0);
        this.energyContainer.setVisible(true);

        // Create a particle system
        this.characterHasExploded = false;
        this.characterExplosion = scene.add.particles('explosion', this.colorPosition);
        this.characterExplosion.createEmitter({
            angle: {min: 240, max: 300},
            speed: {min: 200, max: 400},
            quantity: 30,
            lifespan: 2000,
            alpha: {start: 1, end: 0},
            scale: {min: 0.1, max: 0.5},
            gravityY: 800,
            name: 'james',
            on: false
        });

        // Calculate the amount of life to deduct each time the player is hit
        this.lifeDeduction = this.energyBar.displayWidth / this.totalLives;
    }

    public update(): void {

        // The game is not over so allow the player to continue to move
        // their character
        if(this.myGame.isGameOver == false && this.isDead == false) {

            this.handlePlayerMovement();
            this.handlePlayerFighting();

            // Move the energy bar base on the player position (should be above the player)
            //let x = this.character.x + this.character.width / 2 - this.energybarInside.width / 2;
            let y = this.character.y - 15;

            this.energyBar.x = this.character.x;
            this.energyBar.y = y;
            this.energyContainer.x = this.character.x;
            this.energyContainer.y = y;
        }

        // The game is over
        else {

            /*
            // The character has not exploded (one time operation)
            if(this.characterHasExploded == false) {

                // The character is exploding and it's
                // visibility is turned off
                this.playerExploding();

                this.characterHasExploded = true;
            }
            */

            // Remove the enemy from the game
            if(this.removePlayer == false) {

                // Before removing, make sure there are no more explosion
                // particles alive on the board
                if(this.characterExplosion.emitters.getByName('james').getAliveParticleCount() <= 0) {

                    this.removePlayer = true;

                    // Destroy this particle system for this enemy
                    this.characterExplosion.destroy();
                }
            } 
        }
    }

    /**
     * Return the player character
     */
    public getPlayer(): Phaser.Physics.Arcade.Sprite {

        return this.character;
    }

    /**
     * Remove the player character from the game
     */
    public destroy(): void {

        this.character.destroy();
        this.energyBar.destroy();
        this.energyContainer.destroy();
        //this.characterExplosion.destroy();
        this.myGame.anims.remove('walkLeft');
        this.myGame.anims.remove('walkRight');
        this.myGame.anims.remove('standing');
        this.deleteControls();
    }

    /**
     * Test the collision between an enemy throwing a fighting move
     * and the player
     * 
     * @param enemyX 
     * @param enemyY 
     */
    public testCollision(enemyX: number, enemyY: number): void {

        // Collision box
        let x: number = this.character.x + 20;
        let y: number = this.character.y + 33;
        let w: number = 39;
        let h: number = 82;

        // within the
        let collisionX: boolean = ((enemyX >= x) && (enemyX <= (x + w)));
        let collisionY: boolean = ((enemyY >= y) && (enemyY <= (y + h)));

        // The enemy is hitting the player
        if(collisionX == true && collisionY == true) {

            // Reduce the player life reduction
            this.playerLifeReduction();

            // The player has no more energy so the game is over
            if(this.energyBar.displayWidth === 0 && this.myGame.isGameOver == false) {

                // The character is exploding and it's
                // visibility is turned off
                this.playerExploding();

                this.myGame.isGameOver = true;
                this.isDead = true;
            }
        }
    }

    /**
     * The player energy bar is reduce each time they get hit by a game object
     */
    private playerLifeReduction(): void {

        // Reduce the player life each time they are hit
        this.energyBar.displayWidth -= this.lifeDeduction;
    }

    /**
     * Display the player exploding after losing all of their energy
     */
    private playerExploding(): void {

        // Show the character exploding 
        if(this.characterHasExploded == false) {

            this.characterExplosion.emitParticleAt(this.character.x + this.character.width / 2, this.character.y + this.character.height / 2);
         
            this.characterHasExploded = true;
        }
          
        /*
        // Make the character and energy bar invisible
        this.character.visible = false;
        this.energyBar.visible = false;
        this.energyContainer.visible = false;
        */
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AbcFightHomeComponent } from './abc-fight-home/abc-fight-home.component';

const routes: Routes = [
  {path: 'abc-fight-home', component: AbcFightHomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

import axios from 'axios';
import * as d3 from 'd3';

@Component({
  selector: 'app-word-blitz-tournament-entrance',
  templateUrl: './word-blitz-tournament-entrance.component.html',
  styleUrls: ['./word-blitz-tournament-entrance.component.scss']
})
export class WordBlitzTournamentEntranceComponent implements OnInit {

  private readonly PORT: string = 'https://amesmi.openode.dev'; //'http://localhost:3000'; //'https://amesmi.openode.io'; 

  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  private totalMatchesInRound: number [] = [];
  private round_description: string[] = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

  private testJSON = {
    "name": "Clifford Shanks",
    "born": 1862,
    "died": 1906,
    "location": "Petersburg, VA",
    "parents": [
      {
        "name": "James Shanks",
        "born": 1831,
        "died": 1884,
        "location": "Petersburg, VA",
        "parents": [
          {
            "name": "Robert Shanks",
            "born": 1781,
            "died": 1871,
            "location": "Ireland/Petersburg, VA"
          },
          {
            "name": "Elizabeth Shanks",
            "born": 1795,
            "died": 1871,
            "location": "Ireland/Petersburg, VA"
          }
        ]
      },
      {
        "name": "Ann Emily Brown",
        "born": 1826,
        "died": 1866,
        "location": "Brunswick/Petersburg, VA",
        "parents": [
          {
            "name": "Henry Brown",
            "born": 1792,
            "died": 1845,
            "location": "Montgomery, NC"
          },
          {
            "name": "Sarah Houchins",
            "born": 1793,
            "died": 1882,
            "location": "Montgomery, NC"
          }
        ]
      }
    ]
  }

  private players = [
    {name: 'James Smith', position: 1},
    {name: 'Thomas Smith', position: 2},
    {name: 'Terrick Smith', position: 3},
    {name: 'Tori Smith', position: 4},
    {name: 'Madden Smith', position: 5},
    {name: 'Dallas Smith', position: 6},
    {name: 'Jordan Smith', position: 7},
    {name: 'Tristan Smith', position: 8},
  ]

  constructor() { }

  ngOnInit(): void {

    this.getRegisteredPlayers();

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  public submitPlayerCode(): void {

    let playerCode = document.getElementById('player-code') as HTMLInputElement;

    if(playerCode.value !== '') {

    }

    // Inform the player to enter a valid code
    else {

      this.errorMessage = 'You must submit a code in order to enter the tournament.';
      this.errorModalDisplay.style.display = 'block';
    }
  }

  /**
   * Close the error modal
   */
  public closeModal(): void {

      this.errorModalDisplay.style.display = 'none';
  }

  private getRegisteredPlayers(): void {

    axios.post(this.PORT + '/getPlayers').then((response) => {

      // Player information
      let players = response.data;

      this.calculateNumberOfRounds();

    }).catch((error) => {

      this.errorMessage = 'Unable to connect to server';
      this.errorModalDisplay.style.display = 'block';
    });
  }

  /**
   * Determine the number of rounds base on the number of players
   * that registered for Word Blitz Tournament play
   */
  private calculateNumberOfRounds(): void {

    // Total players registered for the tournament
    let totalPlayers: number = this.players.length;
    let done: boolean = true;
    let i: number = 0;

    // Loop until the number of games are determine for each round
    while(done == true) {

      totalPlayers = totalPlayers / 2;

      if(totalPlayers < 1) {

        // Stop calculating
        done = false;
      }

      else {

        this.totalMatchesInRound[i] = totalPlayers;
        i++;
      }
    }

    console.log(this.totalMatchesInRound);
    this.createTournamentBracket();
  }

  private createTournamentBracket(): void {

    //let tournamentBracket = document.getElementById('tournament-bracket') as HTMLDivElement;

    let margin = {top: 0, right: 320, bottom: 0, left: 0};
    let width = 960 - margin.left - margin.right;
    let height = 500 - margin.top - margin.bottom;

    let svg = d3.select('#tournament-bracket').append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .style('background-color', 'rgb(255, 0, 0)')
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Get the JSON document
    let input = this.testJSON;

    let data = d3.hierarchy(input);

    let tree = d3.tree()
    .size([height, width])
    .separation((a, b) => {
      return a.parent === b.parent ? 1 : 0.5;
    });

    let treeData = tree(data);
    let nodes = treeData.descendants();
    //let links = treeData.links();

    /*
    let link = svg.selectAll(".link")
    .data(treeData.links())
    .enter().append("path")
    .attr("class", "link")
    .attr("d", d3.linkHorizontal());
    */
  }
}

import { GameScreen } from '../../screens/gameScreen';
import { EnemyData } from '../enemy/enemy-data';

enum Movement {

    left = 1,
    right = 2
}

export class EnemyControls extends EnemyData {

    protected myGame: GameScreen;

    // Enemy controls
    private walkLeft: boolean = false;
    private walkRight: boolean = false;
    private standing: boolean = false;
    private throwPunch: boolean = false;
    private startKicking: boolean = false;
    private isFighting: number = 0;
    private readonly isPunching: number = 0;
    private readonly isKicking: number = 1;

    private readonly fistRadius: number = 9;
    private SPEED: number = 0;

    constructor() {

        super();
    }

    protected createControls(scene: GameScreen): void {

        this.myGame = scene;

        this.SPEED = this.getRandomNumber(60, 100);

        // Set the start time to fight
        this.fightDirection = Movement.left;
    }

    /**
     * Handle movement of the enemy
     * Also, determine if the enemy
     * is close enough to fight the
     * player
     * 
     * @param distance 
     */
    protected movement(distance: number): boolean {

        // Do not fight the player
        let fightPlayer: boolean = false;

        // The player is to the right of the enemy
        if(distance > 0) {

            // The enemy is moving to the right
            this.fightDirection = Movement.right;

            // Only move the enemy when they are
            // not close enough to hit the player
            if(Math.abs(distance) > 55) {

                // The enemy is walking right
                if(this.walkRight == false) {

                    // Start the walking right animation
                    this.character.play(this.enemyWalkingRight);

                    this.walkRight = true;
                    this.standing = false;
                    this.walkLeft = false;
                }

                // Move the enemy character to the right
                this.character.setVelocityX(this.SPEED);
            }

            // The enemy is trying to fight the player to 
            // the right of them
            else {

                fightPlayer = true;
            }
        }

        // The player is to the left of the enemy
        else {

            // The enemy is moving to the left
            this.fightDirection = Movement.left;

            // Only move the enemy when they are
            // not close enough to hit the player
            if(Math.abs(distance) > 55) {

                // The enemy is walking right
                if(this.walkLeft == false) {

                    // Start the walking right animation
                    this.character.play(this.enemyWalkingLeft);

                    this.walkLeft = true;
                    this.walkRight = false;
                    this.standing = false;
                }

                // Move the enemy character to the right
                this.character.setVelocityX(-this.SPEED);
            }

            else {

                fightPlayer = true;
            }
        }

        return fightPlayer;
    }

    /**
     * The enemy is starting to fight the player
     */
    protected startFighting(): void {

        this.punchX = 0;
        this.punchY = 0;
        this.kickX = 0;
        this.kickY = 0;

        // Stop the enemy from walking
        if(this.standing == false) {

            this.character.play(this.enemyStanding);

            this.standing = true;
            this.walkLeft = false;
            this.walkRight = false;

            // Do not move the enemy
            this.character.setVelocityX(0);
            this.startFightTime = Date.now();
        }

        // Elapsedtime is used to determine when to throw a punch
        let elapsedTime = Date.now() - this.startFightTime;

        // The enemy is facing to the left
        if(this.fightDirection === Movement.left) {

            // Throw a fighting move every 1/2 of seconds
            if(elapsedTime >= 250) {

                // The character want to throw a punch
                if(this.isFighting === this.isPunching) {

                    if(this.throwPunch == false) {

                        this.character.setFrame(this.position + 2);

                        // Calculate character punch location
                        // 48 is the difference in distance from the player head to their fist
                        this.punchX = this.character.x;
                        this.punchY = this.character.y + 48 + this.fistRadius;

                        //this.player.testCollision(punchX, punchY);

                        this.throwPunch = true;
                    }
    
                    // Reset
                    else {
    
                        this.character.setFrame(this.position);
                        this.throwPunch = false;

                        // Determine which fight move to do next
                        this.isFighting = this.getRandomNumber(0, 1);
                    }
                }

                // The character would like to kick
                else if(this.isFighting === this.isKicking) {

                    if(this.startKicking == false) {

                        this.character.setFrame(this.position + 4);

                        // 84 is the difference bewteen the character head and their RIGHT foot
                        this.kickX = this.character.x;
                        this.kickY = this.character.y + 84 + this.fistRadius;

                        //this.player.testCollision(kickX, kickY);

                        this.startKicking = true;
                    }
    
                    // Reset
                    else {
    
                        this.character.setFrame(this.position);
                        this.startKicking = false;

                        // Determine which fight move to do next
                        this.isFighting = this.getRandomNumber(0, 1);
                    }
                }

                // Reset the time
                this.startFightTime = Date.now();
            }
        }

        // The enemy is facing the right
        else if(this.fightDirection === Movement.right) {

            // Throw a punch
            if(elapsedTime >= 500) {

                // The character want to throw a punch
                if(this.isFighting === this.isPunching) {

                    if(this.throwPunch == false) {

                        this.character.setFrame(this.position + 1);

                        // Calculate character punch location
                        // 48 is the difference in distance from the player head to their fist
                        this.punchX = this.character.x + this.character.displayWidth;
                        this.punchY = this.character.y + 48 + this.fistRadius;

                        //this.player.testCollision(punchX, punchY);

                        this.throwPunch = true;
                    }
    
                    // Reset
                    else {
    
                        this.character.setFrame(this.position);
                        this.throwPunch = false;

                        // Determine which fight move to do next
                        this.isFighting = this.getRandomNumber(0, 1);
                    }
                }

                // The character would like to kick
                else if(this.isFighting === this.isKicking) {

                    if(this.startKicking == false) {

                        this.character.setFrame(this.position + 3);

                        // 84 is the difference bewteen the character head and their RIGHT foot
                        this.kickX = this.character.x + this.character.displayWidth;
                        this.kickY = this.character.y + 84 + this.fistRadius;

                        //this.player.testCollision(kickX, kickY);

                        this.startKicking = true;
                    }
    
                    // Reset
                    else {
    
                        this.character.setFrame(this.position);
                        this.startKicking = false;

                        // Determine which fight move to do next
                        this.isFighting = this.getRandomNumber(0, 1);
                    }
                }

                // Reset the time
                this.startFightTime = Date.now();
            }
        }
    }

    // Get a random number between two integers
    protected getRandomNumber(min: number, max: number): number {
        
        min = Math.ceil(min);
        max = Math.floor(max);
        
        //The maximum is inclusive and the minimum is inclusive 
        return Math.floor(Math.random() * (max - min + 1) + min);   
    }
}
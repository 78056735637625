import * as Phaser from 'phaser';
import * as MAZE from 'generate-maze';

export class Maze {

    private isCreated: boolean = false;

    // Width and height of each cell in the grid
    private cellWidth: number = 0;
    private cellHeight: number = 0;

    // Represent the maze for the game
    private maze: any;

    // Represent the walls within the maze
    private wall: Phaser.GameObjects.Graphics[] = [];

    private readonly spacing: number = 5;

    private mazeWalls = [];

    public readonly MAZE_WIDTH: number = 490;
    public readonly MAZE_HEIGHT: number = 490;

    private add: Phaser.GameObjects.GameObjectFactory;

    constructor() {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        this.add = add;
    }

    /**
     * Generate a new maze base on the following 
     * square size
     * 
     * @param squareSize 
     */
    public generateMaze(squareSize: number): void {

        this.maze = MAZE(squareSize, squareSize);

        // Calculate the width and height of each cell (all cells are equal)
        this.cellWidth = this.MAZE_WIDTH / squareSize;
        this.cellHeight = this.MAZE_HEIGHT / squareSize;

        // Create the maze
        this.createMaze(squareSize);

        this.isCreated = true; 

        console.log('Maze was generated');
    }

    /**
     * Destroy the current maze
     */
    public destroy(): void {

        // The maze has been created
        if(this.isCreated) {

            for(let i = this.wall.length - 1; i >= 0; i--) {

                this.wall[i].destroy();
            }

            this.isCreated = false;
        }
    }

    /**
     * Get the cell width
     */
     public getCellWidth(): number {

        return this.cellWidth;
    }

    /**
     * Get the cell height
     */
    public getCellHeight(): number {

        return this.cellHeight;
    }

    /**
     * Get the walls that represent the maze
     */
     public getMazeWalls(): any[] {

        return this.mazeWalls;
    }

    /**
     * Create the maze
     */
    private createMaze(size: number): void {

        // Set the color of the maze
        let rectColor: number = 0x9ACD32;
        let count: number = 0;

        for(let col = 0; col < size; col++) {

            for(let row = 0; row < size; row++) {

                // Draw the top wall
                if(this.maze[row][col].top == true) {

                    let x: number = col * this.cellWidth + this.spacing;
                    let y: number = row * this.cellHeight + this.spacing;
                    let width: number = this.cellWidth;
                    let height: number = 1;

                    // Draw the rectangle
                    let rect = new Phaser.Geom.Rectangle(x, y, width, height);
                    this.wall[count] = this.add.graphics({fillStyle: {color: rectColor}});
                    this.wall[count].fillRectShape(rect);

                    this.mazeWalls[count] = {

                        x: x,
                        y: y,
                        width: width,
                        height: height
                    };

                    count += 1;
                }

                // Draw the bottom wall
                if(this.maze[row][col].bottom === true) {

                    let x: number = col * this.cellWidth + this.spacing;
                    let y: number = row * this.cellHeight + this.spacing + this.cellHeight;
                    let width: number = this.cellWidth;
                    let height: number = 1;

                    // Draw the rectangle
                    let rect = new Phaser.Geom.Rectangle(x, y, width, height);
                    this.wall[count] = this.add.graphics({fillStyle: {color: rectColor}});
                    this.wall[count].fillRectShape(rect);

                    this.mazeWalls[count] = {

                        x: x,
                        y: y,
                        width: width,
                        height: height

                    };

                    count += 1;
                }

                // Draw the left wall of the cell
                if(this.maze[row][col].left === true) {

                    let x: number = col * this.cellWidth + this.spacing;
                    let y: number = row * this.cellHeight + this.spacing;
                    let width: number = 1;
                    let height: number = this.cellHeight;

                    // Draw the rectangle
                    let rect = new Phaser.Geom.Rectangle(x, y, width, height);
                    this.wall[count] = this.add.graphics({fillStyle: {color: rectColor}});
                    this.wall[count].fillRectShape(rect);

                    this.mazeWalls[count] = {

                        x: x,
                        y: y,
                        width: width,
                        height: height

                    };

                    count += 1;
                }

                // Draw the right wall of the cell
                if(this.maze[row][col].right === true) {

                    let x: number = col * this.cellWidth + this.spacing + this.cellWidth;
                    let y: number = row * this.cellHeight + this.spacing;
                    let width: number = 1;
                    let height: number = this.cellHeight;

                    // Draw the rectangle
                    let rect = new Phaser.Geom.Rectangle(x, y, width, height);
                    this.wall[count] = this.add.graphics({fillStyle: {color: rectColor}});
                    this.wall[count].fillRectShape(rect);

                    this.mazeWalls[count] = {

                        x: x,
                        y: y,
                        width: width,
                        height: height
                    };

                    count += 1;
                }
            }
        }
    }
}
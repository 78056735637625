import { Component, OnInit } from '@angular/core';

import axios from 'axios';

@Component({
  selector: 'app-word-blitz-tournament-registration',
  templateUrl: './word-blitz-tournament-registration.component.html',
  styleUrls: ['./word-blitz-tournament-registration.component.scss']
})
export class WordBlitzTournamentRegistrationComponent implements OnInit {

  private readonly PORT: string = 'https://amesmi.openode.dev';  //'http://localhost:3000'; //'https://amesmi.openode.io'; 
  private readonly alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  
  name: string = '';
  code: string = '';
  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  registrationStatus = '';

  constructor() { }

  ngOnInit(): void {

    this.registrationStatus = 'register';

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Add a player to the tournament
   */
  public addPlayer(): void {

    let playerName = document.getElementById('player-name') as HTMLInputElement;
    let emailAddress = document.getElementById('email-address') as HTMLInputElement;

    /**
     * The player entered some valid information
     */
    if(playerName.value !== '' && emailAddress.value !== '') {

      let playerID: string = '';

      // Determine the number of characters in the player ID
      let numberOfCharacters: number = this.randomInt(6, 9);

      // Determine the characters position in the alphabet
      for(let i = 0; i < numberOfCharacters; i++) {

        // Get the character position in the alphabet
        let position: number = this.randomInt(0, this.alphabet.length);

        // Add the character to the lobby name
        playerID += this.alphabet[position];
      }

      let object = {
        name: playerName.value,
        email: emailAddress.value,
        id: playerID
      }

      // The player to the tournament
      axios.post(this.PORT + '/addPlayerToTournament', object).then((response) => {

        // Get the status of adding the player to the tournament
        let data = response.data;

        // Player was successfully add to the tournament
        if(data.status == true) {

          // Switch the view to the valid code view
          this.registrationStatus = 'validCode';

          // Display the player code
          this.code = playerID;
          this.name = object.name
        }

        // Was unable to add the player to the tournament
        else {

          //this.errorMessage = 'Invalid email or email has already been submitted. Unable to add you to the tournament. Try again!';
          this.errorMessage = data.message;
          this.errorModalDisplay.style.display = 'block';
        }

      }).catch((error) => {

        this.errorMessage = 'Unable to connect to server';
        this.errorModalDisplay.style.display = 'block';
      });

      // Clear the fields
      playerName.value = '';
      emailAddress.value = '';
    }

    // Please enter a name and email address
    else {

      this.errorMessage = 'Must enter your name and a valid email address';
      this.errorModalDisplay.style.display = 'block';
    }
  }

  /**
   * Close the error modal
   */
   public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Return a random number between two numbers
   * 
   * @param min 
   * @param max 
   */
  private randomInt(min: number, max: number): number {

    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
  }
}

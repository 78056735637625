import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsComponent } from './projects/projects.component';

import { AbcFightSharedModule } from 'projects/games2d-abc-fight/src/app/app.module';
import { ColorFightSharedModule } from 'projects/games2d-color-fight/src/app/app.module';
import { MazeEscapeSharedModule } from 'projects/games2d-maze-escape/src/app/app.module';
import { PongSurvivorSharedModule } from 'projects/games2d-pong-survivor/src/app/app.module';
import { WordBlitzSharedModule } from 'projects/games2d-word-blitz/src/app/app.module';
import { HowManySharedModule } from 'projects/games2d-how-many/src/app/app.module';
import { AmesmiJeopardySharedModule } from 'projects/games2d-amesmi-jeopardy/src/app/app.module';
import { UsCitiesSharedModule } from 'projects/games2d-us-cities/src/app/app.module';

const routes: Routes = [

  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'projects', component: ProjectsComponent},

  // 2D Games
  {path: 'abc-fight-home', loadChildren: '../../projects/games2d-abc-fight/src/app/app.module#AbcFightSharedModule'},
  {path: 'color-fight-home', loadChildren: '../../projects/games2d-color-fight/src/app/app.module#ColorFightSharedModule'},
  {path: 'maze-escape-home', loadChildren: '../../projects/games2d-maze-escape/src/app/app.module#MazeEscapeSharedModule'},
  {path: 'pong-survivor-home', loadChildren: '../../projects/games2d-pong-survivor/src/app/app.module#PongSurvivorSharedModule'},
  {path: 'word-blitz-home', loadChildren: '../../projects/games2d-word-blitz/src/app/app.module#WordBlitzSharedModule'},
  {path: 'how-many-home', loadChildren: '../../projects/games2d-how-many/src/app/app.module#HowManySharedModule'},
  {path: 'amesmi-jeopardy-home', loadChildren: '../../projects/games2d-amesmi-jeopardy/src/app/app.module#AmesmiJeopardySharedModule'},
  {path: 'us-cities-home', loadChildren: '../../projects/games2d-us-cities/src/app/app.module#UsCitiesSharedModule'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    AbcFightSharedModule.forRoot(),
    ColorFightSharedModule.forRoot(),
    MazeEscapeSharedModule.forRoot(),
    PongSurvivorSharedModule.forRoot(),
    WordBlitzSharedModule.forRoot(),
    HowManySharedModule.forRoot(),
    AmesmiJeopardySharedModule.forRoot(),
    UsCitiesSharedModule.forRoot()
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

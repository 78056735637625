export class EnemyData {

    protected position: number = 0;
    protected colorPosition: number = 0;

    // Enemy character and energy bars
    protected character: Phaser.Physics.Arcade.Sprite;
    protected energyContainer: Phaser.GameObjects.Sprite;
    protected energyBar: Phaser.GameObjects.Sprite;
    protected characterExplosion: Phaser.GameObjects.Particles.ParticleEmitterManager;
    protected characterHasExploded: boolean = false;
    protected readonly totalLives: number = 10;
    protected lifeDeduction: number = 0;

    protected enemyWalkingLeft: string;
    protected enemyWalkingRight: string;
    protected enemyStanding: string;

    protected fightDirection: any;
    protected startFightTime: number = 0;

    public isDead: boolean = false;
    public removeEnemy: boolean = false;

    public punchX: number = 0;
    public punchY: number = 0;
    public kickX: number = 0;
    public kickY: number = 0;

    constructor() {

    }
}
<div class="w3-container">
    <h2 class="w3-text-gray">Word Blitz Tournament</h2>

    <!-- Allow the player to enter thier code in order to enter the tournament. -->
    <div class="w3-bar" style="margin-top: 20px;">
        <input id="player-code" class="w3-bar-item w3-input w3-border w3-border-green">
        <button class="w3-bar-item w3-button w3-green w3-border w3-border-green" (click)="submitPlayerCode()">Submit</button>
    </div>

    <hr />

    <!-- Display the tournament bracket -->
    <div id="tournament-bracket"></div>

    <!--<div *ngFor="let player of players">

        <div class="w3-panel w3-white w3-border w3-border-blue-gray w3-round-medium" style="width: 200px; padding: 5px;">
            <span class="w3-badge w3-blue-gray w3-opacity-min" style="margin-right: 10px;">{{ player.position }}</span>
            <span class="w3-text-gray">{{ player.name }}</span>
        </div>
    </div>-->
</div>

<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>
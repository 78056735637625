import * as Phaser from 'phaser';

import { Sprite } from './objects/sprite';
import { Animation } from './objects/animation';
import { Bullet } from './bullet';
import { Enemy } from './enemies/enemy';

export class Player {

    private lives: number = 10;
    private score: number = 0;

    private selectedLetter: number;
    private sprite: Sprite;
    private myScore: Phaser.GameObjects.Text;
    private energyBars: Phaser.GameObjects.Sprite[] = [];
    private bullet: Bullet;
    private enemy: Enemy[];

    // Speed of the player
    private readonly SPEED: number = 0.13;
    private readonly BULLET_SPEED: number = 0.3;

    private spacebar: Phaser.Input.Keyboard.Key;
    private keyboard: Phaser.Types.Input.Keyboard.CursorKeys;

    private readonly LeftWall: number = 0;
    private readonly RightWall: number = 800;
    private readonly TopWall: number = 37;
    private readonly BottomWall: number = 462;

    private xPos: number = 0;
    private yPos: number = 0;
    private readonly width: number = 30;
    private readonly height: number = 30;

    private isDead: boolean = false;

    constructor() {

    }

    public init(selectedLetter: number): void {

        // Represent the player bullet
        this.selectedLetter = selectedLetter;
        this.bullet = new Bullet(selectedLetter);
    }

    // Create the player character
    public create(add: Phaser.GameObjects.GameObjectFactory, input: Phaser.Input.InputPlugin): void {

        // Player letter
        let myLetter = add.image(30, 250, 'game_letters', this.selectedLetter);
        myLetter.setOrigin(0, 0);
        myLetter.setVisible(false);

        // Create the animation
        let anim: Animation = new Animation();
        anim.addFrame(myLetter, 0);

        // Create a sprite for the current letter
        this.sprite = new Sprite(anim);
        this.sprite.setX(30);
        this.sprite.setY(250);
        this.sprite.setVelocityX(0.3);
        this.sprite.setVelocityY(0);

        // Setup the player score
        this.myScore = add.text(120, 2, this.score.toString(), {fontSize: '35px', color: 'rgb(255, 255, 0)', align: 'left'});
        this.myScore.setOrigin(0, 0);
        this.myScore.setVisible(true);

        // Beginning position of the energy bars
        let beginX = 134;
        let beginY = 466;

        // Create the energy bars for the player
        for(let i = 0; i < this.lives; i++) {

            // Display the energy bars
            this.energyBars[i] = add.sprite(beginX, beginY, 'energybars', i).setOrigin(0, 0);
            this.energyBars[i].setVisible(true);

            beginX += 66;
        }

        // Keyboard controls
        this.keyboard = input.keyboard.createCursorKeys();
        this.spacebar = input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

        // Create a bullet
        this.bullet.create(add);
    }

    public update(elapsedTime: number): void {

        // The player is shooting a bullet at the enemy
        if(Phaser.Input.Keyboard.JustDown(this.spacebar)) {

            // Set the starting position of the bullet
            this.bullet.fire(this.sprite.getX() + this.sprite.getWidth(), this.sprite.getY() + this.sprite.getHeight() / 2, this.BULLET_SPEED);
        }

        // Draw the player bullet
        this.bullet.update(elapsedTime);

        // Move the player to the next position
        this.movePlayer(elapsedTime);

        // Draw the player to the screen
        this.drawPlayer();
    }

    /**
     * Use the keyboard to move the player around the screen
     * 
     * @param elapsedTime 
     */
    private movePlayer(elapsedTime: number): void {

        // Reset the sprite position
        let velocityX = 0;
        let velocityY = 0;

        // Move right
        if(this.keyboard.right.isDown) {

            // The text is NOT hitting the right wall
            if((this.sprite.getX() + this.sprite.getWidth()) < this.RightWall) {
                
                velocityX += this.SPEED;
            }
        }

        // Move left
        else if(this.keyboard.left.isDown) {

            // The text is NOT hitting the left wall
            if(this.sprite.getX() > this.LeftWall) {

                velocityX -= this.SPEED;
            }
        }

        // Move up
        if(this.keyboard.up.isDown) {

            // The text is NOT hitting the top wall
            if(this.sprite.getY() > this.TopWall) {

                velocityY -= this.SPEED;
            }
        }

        // Move down
        else if(this.keyboard.down.isDown) {
            
            // The text is NOT hitting the bottom wall
            if(this.sprite.getY() + this.sprite.getHeight() < this.BottomWall) {

                velocityY += this.SPEED;
            }
        }

        // Update the sprite position
        this.sprite.setVelocityX(velocityX);
        this.sprite.setVelocityY(velocityY);
        this.sprite.update(elapsedTime)
    }

    /**
     * Draw the player to the screen
     */
    private drawPlayer(): void {

        // The text
        let letter = this.sprite.getImage();
        letter.setX(Math.round(this.sprite.getX()));
        letter.setY(Math.round(this.sprite.getY()));

        this.collision(letter);
    }

    /**
     * Test collision between player and enemy bullets
     * 
     * @param letter 
     */
    private collision(letter: Phaser.GameObjects.Image): void {

        // Test the collision with the enemy bullets
        for(let i = 0; i < this.enemy.length; i++) {

            // Get the bullets for the current enemy
            let bullets = this.enemy[i].getBullets();

            // Loop through the bullets on the screen
            for(let j = 0; j < bullets.length; j++) {

                // Player was hit
                if((letter.x < bullets[j].getX()) && (letter.x + 18) > bullets[j].getX() &&
                   (letter.y < bullets[j].getY()) && (letter.y + letter.height) > bullets[j].getY()) {

                    // Remove the bullet from the screen
                    bullets[j].getImage().destroy();
                    bullets.splice(j, 1);

                    // Reduce the player energy bar
                    this.isHit();

                    // Exit this loop
                    break;
                }
            }
        }

        // Test the collision between the player and enemy
        for(let i = 0; i < this.enemy.length; i++) {

            // Get the enemy sprite
            let enemy = this.enemy[i].getSprite().getImage();

            // Get player bullet
            let playerBullets = this.bullet.getBullets();

            // See if the current enemy was hit
            for(let j = 0; j < playerBullets.length; j++) {

                // Enemy was hit
                if((enemy.x < playerBullets[j].getX()) && (enemy.x + enemy.width) > playerBullets[j].getX() &&
                   (enemy.y < playerBullets[j].getY()) && (enemy.y + enemy.height) > playerBullets[j].getY()) {

                    // Remove the bullet from the screen
                    playerBullets[j].getImage().destroy();
                    playerBullets.splice(j, 1);

                    // Reduce the player energy bar
                    this.enemy[i].isHit();

                    // This enemy is dead
                    if(this.enemy[i].enemyIsDead()) {

                        // Add the enemy point value to the player score
                        this.score += this.enemy[i].getPoints();
                        this.myScore.text = this.score.toString();

                        // Destroy all resources and remove
                        // from the enemy list
                        this.enemy[i].destroy();
                        this.enemy.splice(i, 1);
                    }

                    // Exit this loop
                    break;
                }
            }
        }
    }

    /**
     * Delete the player resources
     */
    public destroy(): void {

        this.sprite.getImage().destroy();

        //this.myScore.destroy();
        this.bullet.destroy();

        this.lives = 10;
        this.score = 0;

        this.selectedLetter = 0;
        this.myScore.destroy();

        this.xPos = 0;
        this.yPos = 0;

        this.isDead = false;
        
        for(let i = 0; i < this.energyBars.length; i++) {

            this.energyBars[i].destroy();
        }
    }

    /**
     * Clear bullets for next level
     */
    public clearBullets(): void {

        this.bullet.destroy();
    }

    /**
     * Get the player information
     */
    public getSprite(): Sprite {

        return this.sprite;
    }

    /**
     * The player was hits
     */
    private isHit(): void {

        // There are energy bars remaining
        if(this.energyBars.length > 0) {

            // Reduce by one
            this.lives -= 1;

            // Remove the energu bar from screen
            this.energyBars[this.lives].destroy();
            this.energyBars.splice(this.lives, 1);

            // Player is dead. End the game
            if(this.lives === 0) {

                this.isDead = true;
            }
        }
    }

    /**
     * Add the enemies
     * 
     * @param bullets 
     */
    public addEnemies(enemy: Enemy[]): void {

        this.enemy = enemy;
    }

    /**
     * The player is dead
     */
    public playerIsDead(): boolean {

        return this.isDead;
    }

    /**
     * Get the player score
     */
    public getScore(): number {

        return this.score;
    }
}
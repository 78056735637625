import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { WordBlitzLobbyService } from '../word-blitz-lobby/word-blitz-lobby.service';

@Component({
  selector: 'app-word-blitz-join-lobby',
  templateUrl: './word-blitz-join-lobby.component.html',
  styleUrls: ['./word-blitz-join-lobby.component.scss']
})
export class WordBlitzJoinLobbyComponent implements OnInit {

  private readonly PORT: string = 'https://amesmi.openode.dev'; //'https://amesmi.openode.io';  //'http://localhost:3000';

  lobbies: any[] = [];
  errorMessage: string = '';
  private errorModalDisplay: HTMLDivElement;

  constructor(private service: WordBlitzLobbyService, private route: Router) { }

  ngOnInit(): void {

    this.getActiveLobbies();

    this.errorModalDisplay = document.getElementById('error-modal') as HTMLDivElement;
    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * The player is trying to join a private lobby
   */
  public lobbyCodeJoin(): void {

    let guestName = document.getElementById('user-name') as HTMLInputElement;
    let lobbyName = document.getElementById('lobby-code') as HTMLInputElement;

    if(guestName.value !== '' && lobbyName.value !== '') {

      // Get the lobby information form the server
      axios.post(this.PORT + '/getLobbyInformation', {lobbyName: lobbyName.value}).then((response) => {

        // Get the requested lobby status
        let lobby = response.data;

        // The lobby is available for the player to join
        if(lobby.status == true) {

          let guestID: string = uuidv4();
          let isHost: boolean = false;

          // Store the lobby information for the guest player
          this.service.setLobbyName(lobbyName.value);
          this.service.setLobbyID(lobby.lobbyID);
          this.service.setHostName(lobby.hostName);
          this.service.setHostID(lobby.hostID);
          this.service.setGuestID(guestID);
          this.service.setGuestName(guestName.value);
          this.service.setIsHost(isHost);
          this.service.setLobbyStatus(true);

          let object = {
            lobbyID: this.service.getLobbyID(),
            guestName: this.service.getGuestName(),
            guestID: this.service.getGuestID()
          }

          axios.post(this.PORT + '/addGuestToLobby', object).then((response) => {

            let result = response.data;
    
            // The player was successfully added to the lobby
            if(result == true) {
    
              // Place the player in the lobby
              this.route.navigateByUrl('/word-blitz-lobby/' + this.service.getLobbyID());
            }
    
            else {
    
              this.errorMessage = 'Unable to add the player to the lobby';
              this.errorModalDisplay.style.display = 'block';
            }
    
          }).catch((error) => {
    
            this.errorMessage = 'Unable to connect to the server';
            this.errorModalDisplay.style.display = 'block';
          });

          // Clear the field
          guestName.value = '';
          lobbyName.value = '';
        }

        // The lobby is NOT available for the player to join
        else if(lobby.status == false) {

          // Lobby is NOT available to join
          this.errorMessage = 'Lobby is NOT available to join';
          this.errorModalDisplay.style.display = 'block';
        }

      }).catch((error) => {

        // Unable to connect to the server
        this.errorMessage = 'Unable to connect to server';
        this.errorModalDisplay.style.display = 'block';
      });
    }

    // Inform the user that they must enter a username and lobby code 
    else {

      this.errorMessage = 'Private lobby: require your name and lobby code';
      this.errorModalDisplay.style.display = 'block';
    }
  }

  /**
   * The player is trying to join a public lobby
   * 
   * @param lobbyID 
   * @param lobbyName 
   * @param hostID 
   * @param hostName 
   */
  public joinLobby(lobbyID: string, lobbyName: string, hostID: string, hostName: string): void {

    // Get the guest name
    let guestName = document.getElementById(lobbyName) as HTMLInputElement;
  
    if(guestName.value !== '') {

      let guestID: string = uuidv4();
      let isHost: boolean = false;

      // Store the lobby information for the guest
      this.service.setLobbyName(lobbyName);
      this.service.setLobbyID(lobbyID);
      this.service.setHostName(hostName);
      this.service.setHostID(hostID);
      this.service.setGuestID(guestID);
      this.service.setGuestName(guestName.value);
      this.service.setIsHost(isHost);
      this.service.setLobbyStatus(true);

      let object = {
        lobbyID: this.service.getLobbyID(),
        guestName: this.service.getGuestName(),
        guestID: this.service.getGuestID()
      }

      axios.post(this.PORT + '/addGuestToLobby', object).then((response) => {

        let result = response.data;

        // The player was successfully added to the lobby
        if(result == true) {

          // Place the player in the lobby
          this.route.navigateByUrl('/word-blitz-lobby/' + this.service.getLobbyID());
        }

        else {

          this.errorMessage = 'Unable to add the player to the lobby';
          this.errorModalDisplay.style.display = 'block';
        }

      }).catch((error) => {

        this.errorMessage = 'Unable to connect to the server';
        this.errorModalDisplay.style.display = 'block';
      });

      // Clear the text block
      guestName.value = '';
    }
  }

  public closeModal(): void {

    this.errorModalDisplay.style.display = 'none';
  }

  /**
   * Retrieve all public lobbies from the server
   */
  private getActiveLobbies(): void {

    axios.post(this.PORT + '/getLobbies').then((response) => {

      // Get all the lobbies from the server
      this.lobbies = response.data;

    }).catch((error) => {

      this.errorMessage = 'Unable to connect to server';
      this.errorModalDisplay.style.display = 'block';
    });
  }
}

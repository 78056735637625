export class WordList {

    //private list: string = '';
    private myList: string = '';
    private opponentList: string = '';

    private words: string[] = [];
    private isVisible: boolean = false;
    private SCREEN_WIDTH: number = 720;

    //private listText: Phaser.GameObjects.Text;
    private myListText: Phaser.GameObjects.Text;
    private opponentListText: Phaser.GameObjects.Text;

    //private totalWordsDisplay: Phaser.GameObjects.Text;
    private wordDisplayBackground: Phaser.GameObjects.Sprite;
    
    constructor() {

    }

    public create(add: Phaser.GameObjects.GameObjectFactory) {

        this.wordDisplayBackground = add.sprite(0, 80, 'word_list');
        this.wordDisplayBackground.setOrigin(0, 0);
        this.wordDisplayBackground.setDepth(2);
        this.wordDisplayBackground.setVisible(this.isVisible);

        /*
        this.totalWordsDisplay = add.text(165, 90, "", {fontSize: "35px", color: "rgb(0, 0, 0)"});
        this.totalWordsDisplay.setOrigin(0, 0);
        this.totalWordsDisplay.setDepth(3);
        this.totalWordsDisplay.setVisible(this.isVisible);
        */

        /*
        this.listText = add.text(20, 130, "", {fontSize: "35px", color: "rgb(0, 0, 0)"});
        this.listText.setWordWrapWidth(this.SCREEN_WIDTH);
        this.listText.setLineSpacing(20);
        this.listText.setDepth(4);
        this.listText.setVisible(this.isVisible);
        */

        // Show the current player word list
        this.myListText = add.text(20, 130, "", {fontSize: "35px", color: "rgb(0, 0, 0)"});
        this.myListText.setWordWrapWidth(this.SCREEN_WIDTH);
        this.myListText.setLineSpacing(20);
        this.myListText.setDepth(4);
        this.myListText.setVisible(this.isVisible);

        // Show the current opponent word list
        this.opponentListText = add.text(20, 640, "", {fontSize: "35px", color: "rgb(0, 0, 0)"});
        this.opponentListText.setWordWrapWidth(this.SCREEN_WIDTH);
        this.opponentListText.setLineSpacing(20);
        this.opponentListText.setDepth(4);
        this.opponentListText.setVisible(this.isVisible);
    }

    /**
     * Clear all resources
     */
    public delete(): void {

        //this.listText.destroy();
        this.myListText.destroy();
        this.opponentListText.destroy();

        //this.totalWordsDisplay.destroy();
        this.wordDisplayBackground.destroy();
    }

    /**
     * Add a word to the word list
     * 
     * @param word 
     */
    public add(word: string): void {

        this.words.push(word);

        // Determine the total number of words stored
        // and convert to a string
        //let total: number = this.words.length;
        //this.totalWordsDisplay.text = total.toString();

        // add word to list
        //this.list += word + " ";

        // Store the words in the list
        //this.listText.setText(this.list);
    }

    /**
     * Add a word to my list
     * 
     * @param word 
     */
    public updateMyWordList(word: string): void {

        // Add the word to the list
        this.myList += word + " ";

        // Store the words in the list
        this.myListText.setText(this.myList);
    }

    /**
     * Add a word to the opponent list
     * 
     * @param word 
     */
    public updateOpponentWordList(word: string): void {

        // Add the word to the list
        this.opponentList += word + " ";

        // Store the words in the list
        this.opponentListText.setText(this.opponentList);
    }

    /**
     * Determine if the word has already been stored in the list
     * 
     * @param word 
     * @returns 
     */
    public isUnique(word: string): boolean {

        let unique: boolean = true;

        for(let i = 0; i < this.words.length; i++) {
            
            // The word is not unique
            if(word.toLowerCase() === this.words[i].toLowerCase()) {

                unique = false;

                break;
            }
        }

        return unique;
    }

    /**
     * Toggle bewteen turning the background on/off
     */
    public show(): void {

        this.isVisible = !this.isVisible;
        
        this.wordDisplayBackground.setVisible(this.isVisible);
        //this.totalWordsDisplay.setVisible(this.isVisible);
        
        //this.listText.setVisible(this.isVisible);
        this.myListText.setVisible(this.isVisible);
        this.opponentListText.setVisible(this.isVisible);
    }

    /**
     * Turn the word list off because the game is over
     */
    public turnOff(): void {

        this.wordDisplayBackground.setVisible(false);
        //this.totalWordsDisplay.setVisible(false);
        
        //this.listText.setVisible(false);
        this.myListText.setVisible(false);
        this.opponentListText.setVisible(false);
    }

    /**
     * Get the player list of words
     * 
     * @returns 
     */

    /*
    public getWords(): string {

        return this.list;
    }
    */

    /**
     * Return all the words spelled by this player
     * 
     * @returns 
     */
    public getMyWords(): string {

        return this.myList;
    }

    /**
     * Return all the words spelled by the opponent
     * 
     * @returns 
     */
    public getOpponentWords(): string {

        return this.opponentList;
    }

    /**
     * Return the total number of words
     * 
     * @returns 
     */
    public getTotalWords(): number {

        return this.words.length;
    }
}
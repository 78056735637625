import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WordBlitzLobbyService {

  private lobbyName: string = '';
  private lobbyID: string = '';
  private hostID: string = '';
  private hostName: string = '';
  private guestID: string = '';
  private guestName: string = '';
  private date: string = '';
  private time: string = '';
  private displayStatus: string = '';
  private isHost: boolean = false;
  private lobbyStatus: boolean = false;

  private myWordList: string = '';
  private opponentWordList: string = '';

  constructor() { }

  public setLobbyName(value: string): void { this.lobbyName = value; }
  public getLobbyName(): string { return this.lobbyName; }

  public setLobbyID(value: string): void { this.lobbyID = value; }
  public getLobbyID(): string { return this.lobbyID; }

  public setHostID(value: string): void { this.hostID = value; }
  public getHostID(): string { return this.hostID; }

  public setHostName(value: string): void { this.hostName = value; }
  public getHostName(): string { return this.hostName; }

  public setGuestID(value: string): void { this.guestID = value; }
  public getGuestID(): string { return this.guestID; }

  public setGuestName(value: string): void { this.guestName = value; }
  public getGuestName(): string { return this.guestName; }

  public setDate(value: string): void { this.date = value; }
  public getDate(): string { return this.date; }

  public setTime(value: string): void { this.time = value; }
  public getTime(): string { return this.time; }

  public setDisplayStatus(value: string): void { this.displayStatus = value; }
  public getDisplayStatus(): string { return this.displayStatus; }

  public setIsHost(value: boolean): void { this.isHost = value; }
  public getIsHost(): boolean { return this.isHost; }

  public setLobbyStatus(value: boolean): void { this.lobbyStatus = value; }
  public getLobbyStatus(): boolean { return this.lobbyStatus; }

  public setMyWordList(value: string): void { this.myWordList = value; }
  public getMyWordList(): string { return this.myWordList; }

  public setOpponentWordList(value: string): void { this.opponentWordList = value; }
  public getOpponentWordList(): string { return this.opponentWordList; }
}

import { Component, OnInit } from '@angular/core';

import { Game } from './game-code/game';

@Component({
  selector: 'app-pong-survivor-home',
  templateUrl: './pong-survivor-home.component.html',
  styleUrls: ['./pong-survivor-home.component.scss']
})
export class PongSurvivorHomeComponent implements OnInit {

  private game: Game;

  constructor() { 

    this.game = new Game();
  }

  ngOnInit(): void {

    this.game.init();
  }

  ngOnDestroy(): void {

    // Delete the game
    this.game.delete();

    console.log('Deleted all resources in the game');
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WordBlitzHomeComponent } from './word-blitz-home/word-blitz-home.component';
import { WordBlitzPlayComponent } from './word-blitz-play/word-blitz-play.component';
import { WordBlitzLevelsComponent } from './word-blitz-levels/word-blitz-levels.component';
import { WordBlitzChallengeComponent } from './word-blitz-challenge/word-blitz-challenge.component';
import { WordBlitzRankingsComponent } from './word-blitz-rankings/word-blitz-rankings.component';
import { WordBlitzRulesComponent } from './word-blitz-rules/word-blitz-rules.component';
import { WordBlitzPlayStatsComponent } from './word-blitz-play-stats/word-blitz-play-stats.component';
import { WordBlitzLevelsStatsComponent } from './word-blitz-levels-stats/word-blitz-levels-stats.component';
import { PlayService } from './word-blitz-play/play.service';
import { LevelsService } from './word-blitz-levels/levels.service';
import { WordBlitzLobbyService } from './word-blitz-challenge/word-blitz-lobby/word-blitz-lobby.service';
import { WordBlitzCreateLobbyComponent } from './word-blitz-challenge/word-blitz-create-lobby/word-blitz-create-lobby.component';
import { WordBlitzJoinLobbyComponent } from './word-blitz-challenge/word-blitz-join-lobby/word-blitz-join-lobby.component';
import { WordBlitzLobbyComponent } from './word-blitz-challenge/word-blitz-lobby/word-blitz-lobby.component';
import { WordBlitzChallengeStatsComponent } from './word-blitz-challenge-stats/word-blitz-challenge-stats.component';
import { WordBlitzTournamentComponent } from './word-blitz-tournament/word-blitz-tournament.component';
import { WordBlitzTournamentRegistrationComponent } from './word-blitz-tournament/word-blitz-tournament-registration/word-blitz-tournament-registration.component';
import { WordBlitzTournamentEntranceComponent } from './word-blitz-tournament/word-blitz-tournament-entrance/word-blitz-tournament-entrance.component';

const providers = [PlayService, LevelsService, WordBlitzLobbyService];

@NgModule({})
export class WordBlitzSharedModule {

  static forRoot(): ModuleWithProviders<AppModule> {

    return {

      ngModule: AppModule,
      providers: providers
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    WordBlitzHomeComponent,
    WordBlitzPlayComponent,
    WordBlitzLevelsComponent,
    WordBlitzChallengeComponent,
    WordBlitzRankingsComponent,
    WordBlitzRulesComponent,
    WordBlitzPlayStatsComponent,
    WordBlitzLevelsStatsComponent,
    WordBlitzCreateLobbyComponent,
    WordBlitzJoinLobbyComponent,
    WordBlitzLobbyComponent,
    WordBlitzChallengeStatsComponent,
    WordBlitzTournamentComponent,
    WordBlitzTournamentEntranceComponent,
    WordBlitzTournamentRegistrationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

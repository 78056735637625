<div [ngSwitch]='lobbyType'>
    
    <!-- Represent the HOST lobby -->
    <div *ngSwitchCase="'host-lobby'">

        <!-- Display important information for the host -->
        <div id="host-information" class="w3-container" style="margin-top: 20px;">

            <!-- Display the lobby code for the HOST to share with potential opponents -->
            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray" style="font-size: 20px;">Lobby Code: <label class="w3-text-red">{{ lobbyName }}</label></label>
            </div>

            <div style="margin-bottom: 10px;">
                <label class="w3-text-blue-gray">If lobby is private then share code with opponent.</label>
            </div>
            
            <hr class="w3-red" />
            
            <!-- Display all the opponents that are trying to challenge the current host -->
            <div style="margin-bottom: 10px;"><label class="w3-text-blue-gray">Waiting on opponent to join.</label></div>
        </div>

        <!-- Display the game -->
        <div id="host-gameboard" style="margin: auto;"></div>
    </div>

    <!-- Represent the GUEST lobby -->
    <div *ngSwitchCase="'guest-lobby'">

        <!-- Display the gameboard for the player -->
        <div id="guest-gameboard" style="margin: auto;"></div>
    </div>

    <!-- A lobby is not available -->
    <!-- The host or guest did not join the lobby properly -->
    <!-- Must start over -->
    <div *ngSwitchCase="'not-available'">
        <div class="w3-display-middle">
            <div style="margin-bottom: 10px;">
                <h2>Opps!! Major Issue</h2>
                <h4>Please start over!</h4>
                <label><label class="w3-text-red" style="margin-right: 10px;">Note:</label>You cannot copy and paste or cut and paste a link</label>
            </div>
            <div routerLink="/word-blitz-home" class="w3-button w3-green w3-border w3-border-green w3-round-medium w3-hover-gray w3-hover-border-gray w3-medium">Home</div>
        </div>
    </div>
</div>

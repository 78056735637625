import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'MenuScreen'
}

export class MenuScreen extends Phaser.Scene {

    private isActive: boolean = false;

    private background: Phaser.GameObjects.Sprite;
    private playButton: Phaser.GameObjects.Sprite;

    private completedLevel: number = 0;

    constructor() {

        super(sceneConfig);

        this.isActive = false;
    }

    public init(data: any): void {
        
        this.completedLevel = data.completedLevel;

        // This is the first time the game is being played or
        // the user left the game and came back
        if(this.completedLevel == undefined && this.completedLevel == null) {

            // Start with level 1
            this.completedLevel = 1;
        }

        // The player is continuing to play the game so get
        // the last level completed
        else {

            this.completedLevel = data.completedLevel;
        }
    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/color-fight');

        // Load the images and spritesheet
        this.load.image('menu_background', 'screens/menu/color_fight_menu.png');
        this.load.spritesheet('menu_buttons', 'screens/menu/color_fight_menu_button.png', {frameWidth: 213, frameHeight: 86});
    }

    public create(): void {

        this.background = this.add.sprite(0, 0, 'menu_background').setOrigin(0, 0);

        // Create the play button
        this.playButton = this.add.sprite(80, 262, 'menu_buttons', 0).setOrigin(0, 0);
        this.playButton.setVisible(true);
        this.playButton.setInteractive();

        // The player has lifted their finger
        this.playButton.on('pointerup', () => {

            this.playButton.setFrame(0);
            this.delete();

            // Switch to the select character scene
            this.scene.start('SelectCharacterScreen', {completedLevel: this.completedLevel});
        });

        // The player has pressed the button
        this.playButton.on('pointerdown', () => {

            this.playButton.setFrame(1);
        });

        this.isActive = true;
    }

    public update(): void {

    }

    public delete(): void {

        if(this.isActive) {

            // Remove all reference for the game object
            this.background.destroy();
            this.playButton.destroy();

            this.isActive = false;

            console.log('Menu Screen objects have been removed');
        }
    }
}
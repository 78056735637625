import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmesmiJeopardyHomeComponent } from './amesmi-jeopardy-home/amesmi-jeopardy-home.component';
import { AmesmiJeopardyCreateLobbyComponent } from './amesmi-jeopardy-create-lobby/amesmi-jeopardy-create-lobby.component';
import { AmesmiJeopardyJoinLobbyComponent } from './amesmi-jeopardy-join-lobby/amesmi-jeopardy-join-lobby.component';
import { AmesmiJeopardyHostLobbyComponent } from './amesmi-jeopardy-host-lobby/amesmi-jeopardy-host-lobby.component';
import { AmesmiJeopardyGuestLobbyComponent } from './amesmi-jeopardy-guest-lobby/amesmi-jeopardy-guest-lobby.component';
import { AmesmiJeopardyRulesComponent } from './amesmi-jeopardy-rules/amesmi-jeopardy-rules.component';
import { JeopardyService } from './amesmi-jeopardy-home/jeopardy.service';

const providers = [JeopardyService];

@NgModule({})
export class AmesmiJeopardySharedModule {

  static forRoot(): ModuleWithProviders<AppModule> {

    return {

      ngModule: AppModule,
      providers: providers
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AmesmiJeopardyHomeComponent,
    AmesmiJeopardyCreateLobbyComponent,
    AmesmiJeopardyJoinLobbyComponent,
    AmesmiJeopardyHostLobbyComponent,
    AmesmiJeopardyGuestLobbyComponent,
    AmesmiJeopardyRulesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

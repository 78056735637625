import { GameGrid } from "../object/game-grid";
import { PlayerEvent, PlayerEventDispatcher } from "./player-event-dispatcher";

export class GamePlayerControls extends PlayerEventDispatcher {

    private readonly letterSize: number = 80;
    private spriteCol: number = 0;
    private spriteRow: number = 0;
    private sprite: Phaser.GameObjects.Sprite = null;
    private letterPosition: number = 0;
    private selectedLetterPosition: number = 0;
    private isLetterSelected: boolean = false;
    private isLetterMoving: boolean = false;
    private letterCharacter: string = '';
    private letterValue: number = 0;
    private letter: number = 0;

    private minX: number = 0;
    private maxX: number = 0;
    private minY: number = 0;
    private maxY: number = 0;

    constructor(private fallingLetters: any[], private gameGrid: GameGrid) {

        super();
    }

    public create(input: Phaser.Input.InputPlugin): void {

        this.minX = this.gameGrid.grid[this.gameGrid.VISIBLE_ROW_BEGINNING][0].x;
        this.maxX = this.gameGrid.grid[this.gameGrid.VISIBLE_ROW_BEGINNING][this.gameGrid.NUMBER_OF_COLS - 1].x + 80;
        this.minY = this.gameGrid.grid[this.gameGrid.VISIBLE_ROW_BEGINNING][0].y;
        this.maxY = this.gameGrid.grid[this.gameGrid.NUMBER_OF_ROWS - 1][0].y + 80;

        input.on('pointerdown', (pointer) => {

            // Get the location of where the player touch
            let touchX: number = pointer.x;
            let touchY: number = pointer.y;

            if(this.isLetterSelected == false) {

                // Did the player click somewhere on the gameboard
                if((touchX >= this.minX && touchX <= this.maxX) && (touchY >= this.minY && touchY <= this.maxY)) {

                    // Loop through all the letters
                    for(let i = 0; i < this.fallingLetters.length; i++) {

                        // Get the next sprite in the list
                        this.sprite = this.fallingLetters[i].sprite;
                        this.spriteCol = this.fallingLetters[i].col;
                        this.spriteRow = this.fallingLetters[i].row;
                        this.letterPosition = this.fallingLetters[i].letterPosition;
                        this.selectedLetterPosition = this.fallingLetters[i].selectedLetterPosition;
                        this.letterCharacter = this.fallingLetters[i].character;
                        this.letterValue = this.fallingLetters[i].pointValue;
                        this.letter = this.fallingLetters[i].letter;

                        // The player click a letter
                        if(touchX >= this.sprite.x && touchX <= (this.sprite.x + this.sprite.width) &&
                           touchY >= this.sprite.y && touchY <= (this.sprite.y + this.sprite.height)) {

                            // Change the color of the letter to the selected colored letter
                            this.sprite.setFrame(this.selectedLetterPosition);
                            this.sprite.setVisible(true);

                            // Remove the sprite from the falling letter list
                            this.fallingLetters.splice(i, 1);

                            // The player has selected a letter
                            this.isLetterSelected = true;

                            // Exit the loop
                            break;
                        }
                    }
                }
            }
        });

        input.on('pointermove', (pointer) => {

            // If a letter is selected then allow the player
            // to move the letter to a new space
            if(this.isLetterSelected) {

                // Get the coordinate of the user pointer
                let touchX: number = pointer.x;
                let touchY: number = pointer.y;

                // The player is moving the letter to the RIGHT
                if(touchX >= (this.sprite.x + this.sprite.width)) {

                    // Make sure the player is not trying to move the letter off
                    // of the game board
                    if((this.spriteCol + 1) < this.gameGrid.NUMBER_OF_COLS) {

                        // Before moving the letter to its new space
                        // make sure a letter is not occupying that space
                        if(this.gameGrid.grid[this.spriteRow][this.spriteCol + 1].occupied === 0) {

                            // Update the sprite position
                            this.sprite.x = this.gameGrid.grid[this.spriteRow][this.spriteCol + 1].x;

                            // Inform the grid that the previous space is not occupied
                            this.gameGrid.grid[this.spriteRow][this.spriteCol].occupied = 0;

                            // Inform the grid that the new space is occupied by the player
                            this.gameGrid.grid[this.spriteRow][this.spriteCol + 1].occupied = 1;

                            // Update the player sprite position
                            this.spriteCol += 1;

                            // The letter is moving
                            this.isLetterMoving = true;
                        }
                    }
                }

                // The player is moving the letter to the LEFT
                else if(touchX < this.sprite.x) {

                    // Make sure the player is not trying to move the letter off
                    // of the game board
                    if((this.spriteCol - 1) >= 0) {

                        // Before moving the letter to its new space
                        // make sure a letter is not occupying that space
                        if(this.gameGrid.grid[this.spriteRow][this.spriteCol - 1].occupied === 0) {

                            // Update the sprite position
                            this.sprite.x = this.gameGrid.grid[this.spriteRow][this.spriteCol - 1].x;

                            // Inform the grid that the previous space is not occupied
                            this.gameGrid.grid[this.spriteRow][this.spriteCol].occupied = 0;

                            // Inform the grid that the new space is occupied by the player
                            this.gameGrid.grid[this.spriteRow][this.spriteCol - 1].occupied = 1;

                            // Update the player sprite position
                            this.spriteCol -= 1;

                            // The letter is moving
                            this.isLetterMoving = true;
                        }
                    }
                }

                // The player is moving the letter DOWN
                // The player will not be able to move up
                if(touchY >= (this.sprite.y + this.sprite.height)) {

                    // Make sure the player is not trying to move the letter off
                    // of the game board
                    if((this.spriteRow + 1) < this.gameGrid.NUMBER_OF_ROWS) {

                        // Before moving the letter to its new space
                        // make sure a letter is not occupying that space
                        if(this.gameGrid.grid[this.spriteRow + 1][this.spriteCol].occupied === 0) {

                            // Update the sprite position
                            this.sprite.y = this.gameGrid.grid[this.spriteRow + 1][this.spriteCol].y;

                            // Inform the grid that the previous space is not occupied
                            this.gameGrid.grid[this.spriteRow][this.spriteCol].occupied = 0;

                            // Inform the grid that the new space is occupied by the player
                            this.gameGrid.grid[this.spriteRow + 1][this.spriteCol].occupied = 1;

                            // Update the player sprite position
                            this.spriteRow += 1;

                            // The letter is moving
                            this.isLetterMoving = true;
                        }
                    }
                }
            }
        });

        input.on('pointerout', (pointer) => {

            // The user should never move their finger from off of the board
            // but just in case
            if(this.isLetterSelected) {

                // The letter was moving
                if(this.isLetterMoving) {

                    // Change the color of the letter back to its original color
                    this.sprite.setFrame(this.letterPosition);

                    // Add the letter back to the falling letter list
                    this.fallingLetters.push({
                        sprite: this.sprite,
                        xGrid: this.gameGrid.grid[this.spriteRow][this.spriteCol].x,
                        yGrid: this.gameGrid.grid[this.spriteRow][this.spriteCol].y,
                        col: this.spriteCol,
                        row: this.spriteRow,
                        letter: this.letter,
                        character: this.letterCharacter,
                        pointValue: this.letterValue,
                        letterPosition: this.letterPosition,
                        selectedLetterPosition: this.selectedLetterPosition,
                        moveLetterCurrentTime: 0,
                        moveLetterStartTime: new Date().getTime()
                    });

                    // Reset the selection and moving value
                    this.isLetterSelected = false;
                    this.isLetterMoving = false;

                    // Reset the sprite
                    this.sprite = null;
                }
            }
        });

        input.on('pointerup', (pointer) => {

            // A letter was selected
            if(this.isLetterSelected) {

                // The player has moved the letter
                // Place the letter back into the falling letter list
                if(this.isLetterMoving) {

                    // Change the color of the letter back to its original color
                    this.sprite.setFrame(this.letterPosition);

                    // Add the letter back to the falling letter list
                    this.fallingLetters.push({
                        sprite: this.sprite,
                        xGrid: this.gameGrid.grid[this.spriteRow][this.spriteCol].x,
                        yGrid: this.gameGrid.grid[this.spriteRow][this.spriteCol].y,
                        col: this.spriteCol,
                        row: this.spriteRow,
                        letter: this.letter,
                        character: this.letterCharacter,
                        pointValue: this.letterValue,
                        letterPosition: this.letterPosition,
                        selectedLetterPosition: this.selectedLetterPosition,
                        moveLetterCurrentTime: 0,
                        moveLetterStartTime: new Date().getTime()
                    });
                }

                // The player did not move the letter
                // Remove the letter from the game board and place
                // the letter on the spelling area of the game
                else {

                    // Destroy this object
                    this.sprite.setVisible(false);

                    // Free up the current grid spot
                    this.gameGrid.grid[this.spriteRow][this.spriteCol].occupied = 0;

                    // Add the letter to the gameboard
                    let object = {
                        letter: this.letterCharacter,
                        pointValue: this.letterValue,
                        letterPosition: this.letter
                    }

                    this.dispatchEvent(new PlayerEvent('add-letter-to-word', JSON.stringify(object)));
                }

                // Reset the selection and moving value
                this.isLetterSelected = false;
                this.isLetterMoving = false;

                // Reset the sprite
                this.sprite = null;
            }
        });
    }
}
import * as Phaser from 'phaser';

export class GameGems {

    private rect: Phaser.Geom.Rectangle;
    private gems: Phaser.GameObjects.Graphics;

    private maze_square_size: number;
    private MAZE_WIDTH: number;
    private MAZE_HEIGHT: number;
    private color: number;
    private exit: boolean;

    constructor(maze_square_size: number, MAZE_WIDTH: number, MAZE_HEIGHT: number, color: number, exit: boolean) {

        this.MAZE_WIDTH = MAZE_WIDTH;
        this.MAZE_HEIGHT = MAZE_HEIGHT;
        this.maze_square_size = maze_square_size;
        this.color = color;
        this.exit = exit
    }

    public create(add: Phaser.GameObjects.GameObjectFactory): void {

        if(this.exit) {

            // Calculate the position for the door gem on this level
            // Should be in the same position each time
            let x = this.maze_square_size - 1;
            let y = this.maze_square_size - 1;

            let width = this.MAZE_WIDTH / this.maze_square_size - 3;
            let height = this.MAZE_HEIGHT / this.maze_square_size - 3;

            // Calculate the position on the maxe
            let xPos = 7 + x * (width + 3);
            let yPos = 7 + y * (height + 3);   
            
            // Draw the gem on the maze
            this.rect = new Phaser.Geom.Rectangle(xPos, yPos, width, height);
            this.gems = add.graphics({fillStyle: {color: this.color}});
            this.gems.fillRectShape(this.rect);
        }

        else {

            // Calculate the position, width, and height of the gem
            let x = this.getRandomNumber(0, this.maze_square_size-3);
            let y = this.getRandomNumber(0, this.maze_square_size-3);
            let width = this.MAZE_WIDTH / this.maze_square_size - 3;
            let height = this.MAZE_HEIGHT / this.maze_square_size - 3;

            // Calculate the position on the maxe
            let xPos = 7 + x * (width + 3);
            let yPos = 7 + y * (height + 3);

            // Draw the gem on the maze
            this.rect = new Phaser.Geom.Rectangle(xPos, yPos, width, height);
            this.gems = add.graphics({fillStyle: {color: this.color}});
            this.gems.fillRectShape(this.rect);
        }
    }

    /**
     * Return a random number between 2 numbers
     * 
     * @param min 
     * @param max 
     */
    private getRandomNumber(min: number, max: number): number {

        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public getX(): number {

        return this.rect.x;
    }

    public getY(): number {

        return this.rect.y;
    }

    public getWidth(): number {

        return this.rect.width;
    }

    public getHeight(): number {

        return this.rect.height;
    }

    public destroy(): void {

        this.rect.setEmpty();
        this.gems.destroy();
    }
}
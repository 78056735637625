import * as Phaser from 'phaser';

import { GameScene } from './gameScene';
import { LetterSelectionScene } from './letterSelectionScene';
import { GameOverScene } from './gameOverScene';
import { MenuScene } from './menuScene';

export class Game {

    private readonly gameWidth: number = 800;
    private readonly gameHeight: number = 500;
    private readonly backgroundColor: string = 'rgb(0, 0, 0)';

    private game: Phaser.Game;
    private gameScene: GameScene;
    private letterSelectionScene: LetterSelectionScene;
    private gameOverScene: GameOverScene;
    private menuScene: MenuScene;
    private gameConfig: Phaser.Types.Core.GameConfig;

    constructor() {

    }

    public init(): void {

        // Initialize the modal
        //let onlineGame: HTMLDivElement = document.getElementById('start-single-game') as HTMLDivElement;
        //onlineGame.style.maxWidth = this.gameWidth + 'px';
        //onlineGame.style.maxHeight = this.gameHeight + 'px';

        // Setup game scene
        this.gameScene = new GameScene();
        this.letterSelectionScene = new LetterSelectionScene();
        this.gameOverScene = new GameOverScene();
        this.menuScene = new MenuScene();

        this.gameConfig = {

            type: Phaser.WEBGL,

            // Screen size
            width: this.gameWidth,
            height: this.gameHeight,

            // Set the frames per second
            fps: {
                target: 60,
                forceSetTimeOut: true
            },

            // DIV element
            parent: 'abc-fight-screen',

            // Set background color
            backgroundColor: this.backgroundColor,

            scene: [this.menuScene, this.letterSelectionScene, this.gameScene, this.gameOverScene]
        }

        // Start the game
        this.game = new Phaser.Game(this.gameConfig);
    }

    /**
     * Remove all resources from the game
     */
    public delete(): void {

        this.game.destroy(false, false);
    }
}
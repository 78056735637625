import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class JeopardyService {

  private lobbyName: string = '';
  private lobbyID: string = '';
  private hostID: string = '';
  private hostName: string = '';
  private guestID: string = '';
  private guestName: string = '';
  private date: string = '';
  private time: string = '';
  private displayStatus: string = '';
  private isHost: boolean = false;
  private lobbyStatus: boolean = false;
  private totalPlayers: number = 0;
  private gamePlay: string = '';

  // Game categories
  private firstRound: any[] = [];
  private secondRound: any[] = [];
  private finalRound: any[] = [];

  constructor() { }

  public setLobbyName(value: string): void { this.lobbyName = value; }
  public getLobbyName(): string { return this.lobbyName; }

  public setLobbyID(value: string): void { this.lobbyID = value; }
  public getLobbyID(): string { return this.lobbyID; }

  public setHostID(value: string): void { this.hostID = value; }
  public getHostID(): string { return this.hostID; }

  public setHostName(value: string): void { this.hostName = value; }
  public getHostName(): string { return this.hostName; }

  //public setHostGuestID(value: string): void { this.hostGuestID.push(value); }
  //public getHostGuestIDs(): string[] { return this.hostGuestID; }

  //public setHostGuestName(value: string): void { this.hostGuestName.push(value); }
  //public getHostGuestNames(): string[] { return this.hostGuestName; }

  public setGuestID(value: string): void { this.guestID = value; }
  public getGuestID(): string { return this.guestID; }

  public setGuestName(value: string): void { this.guestName = value; }
  public getGuestName(): string { return this.guestName; }

  public setDate(value: string): void { this.date = value; }
  public getDate(): string { return this.date; }

  public setTime(value: string): void { this.time = value; }
  public getTime(): string { return this.time; }

  public setDisplayStatus(value: string): void { this.displayStatus = value; }
  public getDisplayStatus(): string { return this.displayStatus; }

  public setIsHost(value: boolean): void { this.isHost = value; }
  public getIsHost(): boolean { return this.isHost; }

  public setLobbyStatus(value: boolean): void { this.lobbyStatus = value; }
  public getLobbyStatus(): boolean { return this.lobbyStatus; }

  public setTotalPlayers(value: number): void { this.totalPlayers = value; }
  public getTotalPlayers(): number { return this.totalPlayers; }

  public setGamePlay(value: string): void { this.gamePlay = value; }
  public getGamePlay(): string { return this.gamePlay; }

  public getFirstRound(): any[] { return this.firstRound; }
  public getSecondRound(): any[] { return this.secondRound; }
  public getFinalRound(): any[] { return this.finalRound; }
  public createJeopardyRound(firstRound: string[], secondRound: string[], finalRound: string[], results: any[]): void {

    let score1: number[] = [100, 200, 300, 400, 500];
    let score2: number[] = [200, 400, 600, 800, 1000];

    // Clear the array list
    this.firstRound.splice(0, this.firstRound.length);
    this.secondRound.splice(0, this.secondRound.length);
    this.finalRound.splice(0, this.finalRound.length);

    // Store the first round category
    for(let i = 0; i < firstRound.length; i++) {

      for(let j = 0; j < results.length; j++) {

        if(firstRound[i] === results[j].category) {

          // Store a new set of id
          let id: string[] = [];

          // Create a unique id for the first 
          for(let i = 0; i < 5; i++) {

            let myID: string = uuidv4();

            id.push(myID);
          }

          // Create question for the first round
          let category = {
            name: results[j].category,
            question: results[j].question,
            answer: results[j].answer,
            score: score1,
            id: id
          }

          this.firstRound.push(category);

          // Exit the inner loop and go to the next category in the firstRound
          break;
        }
      }
    }


    // Store the second round category
    for(let i = 0; i < secondRound.length; i++) {

      for(let j = 0; j < results.length; j++) {

        if(secondRound[i] === results[j].category) {

          // Store a new set of id
          let id: string[] = [];

          // Create a unique id for the first 
          for(let i = 0; i < 5; i++) {

            let myID: string = uuidv4();

            id.push(myID);
          }

          // Create question for the first round
          let category = {
            name: results[j].category,
            question: results[j].question,
            answer: results[j].answer,
            score: score2,
            id: id
          }

          this.secondRound.push(category);

          // Exit the inner loop and go to the next category in the firstRound
          break;
        }
      }
    }


    // Store the final round category
    for(let i = 0; i < finalRound.length; i++) {

      for(let j = 0; j < results.length; j++) {

        if(finalRound[i] === results[j].category) {

          let position: number = this.randomInt(0, 5);

          // Create question for the first round
          let category = {
            name: results[j].category,
            question: results[j].question[position],
            answer: results[j].answer[position],
            id: uuidv4()
          }

          this.finalRound.push(category);

          // Exit the inner loop and go to the next category in the firstRound
          break;
        }
      }

      // Exit this Loop
      break;
    }
  }

  /**
   * Return a random number between two numbers
   * 
   * @param min 
   * @param max 
   */
   private randomInt(min: number, max: number): number {

    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min)) + min;
  }
}

<div class="w3-container">
    <div id="color-fight-screen" style="margin-top: 20px;"></div>
    <!--<div routerLink="/projects" class="w3-button w3-blue-gray w3-round-medium">Back to Projects</div>-->

    <!-- Credit in congratulation photo -->
    <div class="w3-container" style="margin-bottom: 20px;">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Game Controls:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Q - Punch Left</label>
        </div>
        <div>
            <label style="font-size: 16px;">W - Punch Right</label>
        </div>
        <div>
            <label style="font-size: 16px;">A - Kick Left</label>
        </div>
        <div>
            <label style="font-size: 16px;">S - Kick Right</label>
        </div>
        <div>
            <label style="font-size: 16px;">Space Bar - Jump</label>
        </div>
    </div>

    <div class="w3-container">
        <div>
            <label class="w3-text-blue-gray" style="font-size: 25px;">Credit:</label>
        </div>
        <div>
            <label style="font-size: 16px;">Game Design by Dallas</label>
        </div>
        <div>
            <span>Congratulation Fireworks Photo by <a href="https://unsplash.com/@shima_giraffe?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Nagatoshi Shimamura</a> on <a href="https://unsplash.com/s/photos/celebration?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
        </div>
    </div>
</div>
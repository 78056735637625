import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlayService } from './play.service';

import { Game } from './single-player-game/game';

@Component({
  selector: 'app-word-blitz-play',
  templateUrl: './word-blitz-play.component.html',
  styleUrls: ['./word-blitz-play.component.scss']
})
export class WordBlitzPlayComponent implements OnInit {

  private game: Game;

  // Actual game width and height
  private readonly GAME_WIDTH: number = 720;
  private readonly GAME_HEIGHT: number = 1280;

  constructor(private route: Router, private service: PlayService) { 

    this.game = new Game(this.route, this.service);
  }

  ngOnInit(): void {

    // Calculate the display width and height for the game
    let height: number = window.innerHeight - 54;
    let width: number = this.GAME_WIDTH * height / this.GAME_HEIGHT;

    // Assign the calculated width and height to the display canvas
    let element = document.getElementById('single-player-game') as HTMLDivElement;
    element.style.width = width + 'px';
    element.style.height = height + 'px';

    // Start the game
    this.game.init('single-player-game', this.GAME_WIDTH, this.GAME_HEIGHT);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

    this.game.delete();
  }
}

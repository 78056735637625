import * as Phaser from 'phaser';

import { Maze } from '../game/maze';
import { Clock } from '../game/clock';
import { Level } from '../game/level';
import { Player } from '../game/player';
import { GameGems } from '../game/gameGems';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScreen'
}

export class GameScreen extends Phaser.Scene {

    private isActive: boolean = false;

    // Screen width and height
    private screenWidth: number = 0;
    private screenHeight: number = 0;

    private maze: Maze;
    private level: Level;
    private clock: Clock;
    private player: Player;

    // Respresent the number of columns and rows in the
    // maze. Try to keep this as a square
    private maze_square_size: number = 10;

    private startTime: number = 0;
    //private gameGems: Phaser.GameObjects.Graphics[] = [];
    private gameGems: GameGems[] = [];
    private doorGem: GameGems[] = [];

    constructor(width: number, height: number) {

        super(sceneConfig);

        this.isActive = false;
        this.screenWidth = width;
        this.screenHeight = height;

        this.maze = new Maze();
        this.level = new Level(this.screenWidth, this.screenHeight);
        this.clock = new Clock(this.screenWidth, this.screenHeight);
        this.player = new Player(this.screenWidth, this.screenHeight, this.maze);
    }

    public init(data: any): void {

    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/maze-escape');

        // Load the player sprite
        this.load.image('player', 'player/player.png');
    }

    public create(): void {

        this.maze.create(this.add);
        this.level.create(this.add);
        this.clock.create(this.add);
        this.player.create(this.add, this);
    }

    public update(): void {

        // The game is over
        if(this.player.gameIsOver() || this.clock.gameIsOver()) {

            // Display the game is over screen (time ran out)
            this.delete();

            // Switch to the game is over screen
            this.scene.start('GameoverScreen');
        }

        // The player is playing the game
        else {

            // The player has collected the door gem
            // Switch to the next level
            if(this.doorGem.length === 0) {

                // Are there any gems remaining on this level
                for(let i = 0; i < this.gameGems.length; i++) {

                    // If so remove them before turning everything off
                    // Destroy the game gem
                    this.gameGems[i].destroy();

                    // Remove the game gem from the list
                    this.gameGems.splice(i, 1);
                }

                // There are only 10 levels
                // If the player completes level 10 then
                // the game is over so display the congratulation
                // information
                if(this.level.getLevel() === 11) {

                    // Display the completed game display
                    this.level.turnOffNextLevel();

                    // Display the congratulation screen for beating the game
                    this.delete();

                    // Switch to the game is over screen
                    this.scene.start('CongratulationScreen');
                }

                else if(this.startTime === 0) {

                    // Make the player invisible
                    this.player.turnPlayerOff();

                    // Delete the current maze
                    this.maze.destroy();

                    // Switch to the next level
                    this.level.nextLevel();

                    // Turn on the next level display
                    this.level.turnOnNextLevel();

                    // Turn off the clock
                    this.clock.turnOffClock();

                    // Get a start display time for level
                    this.startTime = new Date().getTime();
                }

                else {

                    // Set the current time
                    let currentTime = new Date().getTime() - this.startTime;

                    // Pause for 3 seconds
                    if(currentTime >= 3000) {

                        // Calculate the size of the maze
                        this.maze_square_size += 2;

                        // Generate the maze
                        // Its a different maze each time
                        this.maze.generateMaze(this.maze_square_size);

                        // Create the player for the new maze
                        this.player.setSize(this.maze_square_size);
                        this.player.setMazeWalls(this.maze.getMazeWalls());

                        // Create the game gems to be collected by the player
                        this.createGems();

                        // Reset the times
                        this.startTime = 0;

                        // Turn off the next level display
                        this.level.turnOffNextLevel();

                        // Turn on the clock
                        this.clock.turnOnClock();

                        // Make the player visible
                        this.player.turnPlayerOn();
                    }
                }
            }

            else {

                this.clock.update();
                this.player.update();

                // Did the player collide with a gem
                for(let i = 0; i < this.gameGems.length; i++) {

                    // Get the player information
                    let x = this.player.getPlayer().x;
                    let y = this.player.getPlayer().y;
                    let width = this.player.getPlayer().width;
                    let height = this.player.getPlayer().height;

                    // Get the current gem information
                    //let bounds = this.gameGems[i].
                    let gemX = this.gameGems[i].getX();
                    let gemY = this.gameGems[i].getY();
                    let gemWidth = this.gameGems[i].getWidth();
                    let gemHeight = this.gameGems[i].getHeight();

                    // The player has collided with a gem
                    if(x < gemX + gemWidth && x + width > gemX &&
                       y < gemY + gemHeight && y + height > gemY) {
              
                        // Destroy the game gem
                        this.gameGems[i].destroy();
 
                        // Remove the game gem from the list
                        this.gameGems.splice(i, 1);
 
                        // Add the gems to the player collection
                        this.player.addGems();
 
                        // Add more time to the player clock
                        this.clock.addMoreTime();
 
                        // Exit the loop
                        break;
                    }
                }

                // Did the player collide with the door gem
                for(let i = 0; i < this.doorGem.length; i++) {

                    // Get the player information
                    let x = this.player.getPlayer().x;
                    let y = this.player.getPlayer().y;
                    let width = this.player.getPlayer().width;
                    let height = this.player.getPlayer().height;

                    // Get the current gem information
                    //let bounds = this.doorGem[i].getBounds();
                    let gemX = this.doorGem[0].getX();
                    let gemY = this.doorGem[0].getY();
                    let gemWidth = this.doorGem[0].getWidth();
                    let gemHeight = this.doorGem[0].getHeight();

                    // The player has collided with a gem
                    if(x < gemX + gemWidth && x + width > gemX &&
                       y < gemY + gemHeight && y + height > gemY) {
             
                        // Destroy the game gem
                        this.doorGem[i].destroy();

                        // Remove the game gem from the list
                        this.doorGem.splice(i, 1);

                        // Exit the loop
                        break;
                    }
                }
            }
        }
    }

    public delete(): void {

        this.maze.destroy();
        this.clock.destroy();
        this.level.destroy();
        this.player.destroy();

        this.isActive = false;
    }

    private createGems(): void {

        // Get the current level
        let currentLevel = this.level.getLevel();
        
        // Total gems for this level
        let total = currentLevel + 1;

        for(let i = 0; i < total; i++) {

            this.gameGems[i] = new GameGems(this.maze_square_size, this.maze.MAZE_WIDTH, this.maze.MAZE_HEIGHT, 0xFFd700, false);
            this.gameGems[i].create(this.add);
        }

        this.doorGem[0] = new GameGems(this.maze_square_size, this.maze.MAZE_WIDTH, this.maze.MAZE_HEIGHT, 0xCD853F, true);
        this.doorGem[0].create(this.add);
    }
}
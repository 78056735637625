<div [ngSwitch]='lobbyActive'>

    <!-- Display the following information if the lobby is active -->
    <div *ngSwitchCase="'yes'">

        <div class="w3-row">

            <!-- Main Game Area -->
            <div class="w3-col l8" style="margin-bottom: 20px;">
                
                <div class="w3-container">

                    <!-- Display the player camera feed, name, and score -->
                    <div class="w3-center" style="background-color: rgb(51, 51, 51); overflow-x: auto; overflow-y: hidden; white-space: nowrap; padding: 5px;">
                        
                        <!-- Show the the player live camera feed, name, and score -->
                        <span class="container" *ngFor="let player of activePlayers">

                            <!-- Store the video feed for all players -->
                            <video id="video-{{ player.id }}" width="300" height="150" autoplay></video>

                            <div class="overlay w3-round-medium w3-container w3-black w3-opacity-min">
                                <label class="w3-text-white" style="font-size: 12px;">{{ player.name }}<label *ngIf="player.isHost == true" class="w3-text-deep-orange" style="margin-left: 5px;">{{ player.score }}</label></label>
                            </div>
                        </span>             
                    </div>
                </div>
            </div>

            <!-- Display the players in the lobby waiting -->
            <!-- Display the players in the lobby -->
            <!-- Display the lobby name -->
            <!-- Display the Current Round in the game -->
            <div class="w3-col l4">

            </div>
        </div>
    </div>

    <!-- The lobby was not setup properly so it is NOT active -->
    <div *ngSwitchCase="'no'">
        <div class="w3-display-middle">
            <div style="margin-bottom: 10px;">
                <h2>Opps!! Major Issue</h2>
                <h4>Please start over!</h4>
                <label><label class="w3-text-red" style="margin-right: 10px;">Note:</label>You cannot copy and paste or cut and paste a link</label>
            </div>
            <div routerLink="/amesmi-jeopardy-home" class="w3-button w3-green w3-border w3-border-green w3-round-medium w3-hover-gray w3-hover-border-gray w3-medium">Home</div>
        </div>
    </div>
</div>

<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>

import * as Phaser from 'phaser';

import { Player } from './game/player';
import { Levels } from './game/levels';
import { Enemies } from './game/enemies/enemies';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScene'
};

export class GameScene extends Phaser.Scene {

    private gameboard: Phaser.GameObjects.Sprite;

    private isGameOver: boolean = false;

    // Handle time
    private startTime: number;
    private currentTime: number;

    private mySelection: number = 0;

    private levels: Levels;
    private player: Player;
    private enemies: Enemies;

    constructor() {

        super(sceneConfig);
    }

    /**
     * Initialize game variables
     */
    public init(data): void {

        this.player = new Player();
        this.levels = new Levels();
        this.enemies = new Enemies();

        this.isGameOver = false;

        this.startTime = new Date().getTime();
        this.currentTime = this.startTime;

        // Get my selection from the letter selection screen
        this.mySelection = data.mySelection;

        // Initialize the player controls
        this.player.init(this.mySelection);
        this.enemies.init(this.mySelection);
    }

    /**
     * This will load all the images, text files, etc for the game
     */
    public preload(): void {

        // Specify the image folder
        this.load.setBaseURL('../assets/images/projects/games2D/abc-fight');

        // load images
        this.load.image('gameboard', 'game/gameboard.png');
        this.load.spritesheet('energybars', 'game/energy_bars.png', {frameWidth: 66, frameHeight: 30});
        this.load.spritesheet('levels', 'game/game_levels.png', {frameWidth: 600, frameHeight: 250});
        this.load.spritesheet('bullets', 'game/bullets.png', {frameWidth: 20, frameHeight: 2});
        this.load.spritesheet('game_letters', 'game/letters.png', {frameWidth: 30, frameHeight: 30});
    }

    /**
     * 
     */
    public create(): void {

        this.gameboard = this.add.sprite(0, 0, 'gameboard').setOrigin(0, 0);

        this.player.create(this.add, this.input);
        this.enemies.create(this.add);
        this.levels.create(this.add);

        // Display the level first before doing anything else
        this.levels.nextLevel();
        this.enemies.createEnemiesForLevel(this.levels.getCurrentLevel());

        //  Add the player to the enemies
        this.player.addEnemies(this.enemies.getEnemies());
    }

    public update(): void {
        
        // Run as long as the game is not over
        if(this.isGameOver == false) {

            // Calculate the time
            let elapsedTime = new Date().getTime() - this.currentTime;
            this.currentTime += elapsedTime;

            // Turn the current level for 3 seconds
            if(this.levels.display) {

                // Display the current level
                this.levels.update(new Date().getTime())
            }

            // All the enemies for this level has been killed
            // Switch to the next level
            else if(this.enemies.getEnemies().length === 0) {
            
                // Clear the player bullets
                this.player.clearBullets();

                // Display the level first before doing anything else
                this.levels.nextLevel();

                // Switch to the Game Over Scene
                if(this.levels.completedGame) {

                    this.isGameOver = true;

                    let myScore: number = this.player.getScore();
                    let myLevel: number = this.levels.getCurrentLevel();

                    this.delete();

                    this.scene.start('GameOverScene', {score: myScore, level: myLevel});
                }

                // Update the enemies for the new level
                else {

                    this.enemies.createEnemiesForLevel(this.levels.getCurrentLevel());

                    // Switch to the game is over scene
                    this.player.addEnemies(this.enemies.getEnemies());
                }
            }

            // Allow the player to play the game
            else {

                /**
                 * The player is dead so the game is over
                 */
                if(this.player.playerIsDead()) {

                    this.isGameOver = true;

                    let myScore: number = this.player.getScore();
                    let myLevel: number = this.levels.getCurrentLevel();

                    // Remove resources from the screen
                    this.delete();

                    // Switch to the game is over scene
                    this.scene.start('GameOverScene', {score: myScore, level: myLevel});
                }

                else {

                    // Update the sprite animations
                    this.player.update(elapsedTime);
                    this.enemies.update(elapsedTime);
                }
            }
        }
    }

    /**
     * Remove all references from the game
     */
    public delete(): void {

        this.gameboard.destroy();
        
        this.player.destroy();
        this.levels.destroy();
        this.enemies.destroy();
    }
}
<!-- Create a new row -->
<div class="w3-row home-background-image">
    <div class="w3-col m12 l12">
        <div class="w3-container" style="width: 100%; height: 600px">
            <h3>Welcome to <span style="font-size: 30px;">AMESMI</span> Entertainment.</h3>
  
            <p>
            It's never too early to start exploring interests that can become careers. 
            We're in elementary school now, but our love for math and the encouragement of our parents will likely 
            lead to STEM (science, technology, engineering, and mathematics) careers later. We designed these games 
            and our Dad coded them for us. Now, we're going to learn to code too. 
            </p>
          
            <p>
            Try out our games. We hope you like them!
            </p>
  
            Madden and Dallas
        </div>
    </div>
</div>
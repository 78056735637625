<div class="w3-container">

    <div [ngSwitch]='registrationStatus'>

        <!-- Display the tournament registration information -->
        <div *ngSwitchCase="'register'">

            <h4 class="w3-text-gray" style="font-size: 30px; margin-top: 10px;">Tournament Information</h4>

            <div style="max-width: 400px;">
                <div class="w3-text-blue-gray" style="margin-bottom: 20px;">
                    The tournament is held on a Saturday at 11:00 am. There will be a 10 minute grace
                    period for players to enter the assign lobby. If unable to start at that time then 
                    you forfiet your game and your opponent moves to the next round. Once the tournament 
                    starts, it will continue until there is a winner.
                </div>
            
                <div class="w3-text-red w3-opacity">
                    Emails are only used for sending login and game start times. Once a tournament is over,
                    the email is deleted. You will have to input your email address each time you play in
                    a tournament.
                </div>
            </div>
            

            <hr class="w3-red" />

            <h4 class="w3-text-gray" style="font-size: 30px; margin-top: 10px;">Player Information</h4>

            <!-- Allow the user (HOST) to enter their name -->
            <div style="margin-bottom: 15px;">
                <input id="player-name" class="w3-input w3-border w3-border-green" type="text" title="Enter your name" placeholder="Enter your name" style="max-width: 350px;" required>
            </div>

            <div style="margin-bottom: 15px;">
                <input id="email-address" class="w3-input w3-border w3-border-green" type="text" title="Enter email address" placeholder="Enter email address" style="max-width: 350px;" required>
            </div>

            <!-- Create a new lobby by pressing the button -->
            <div style="margin-bottom: 15px;">
                <button id="submit-button" class="w3-button w3-round w3-blue w3-border-blue w3-hover-light-blue w3-hover-border-light-blue" title="Add Player Button" (click)="addPlayer()">Submit</button>
            </div>
        </div>

        <!-- The user successfully registered, display their valid code entrance -->
        <div *ngSwitchCase="'validCode'">
            
            <h2 class="w3-text-blue-gray">Word Blitz Tournament</h2>
            <div style="margin-bottom: 15px;">
                <label class="w3-text-black" style="font-size: 22px;">Hi {{ name }}!</label>
            </div>
            <div style="margin-bottom: 15px;">
                <label class="w3-text-black" style="font-size: 18px;">Your code is: <label class="w3-text-blue-gray">{{ code }}</label></label>
            </div>
            <div style="margin-bottom: 15px;">
                <label class="w3-text-black">Use this code on game day. It should be in your email also.</label>
            </div>

            <!-- Return the player back to the word blitz home screen -->
            <button routerLink="/word-blitz-home" class="w3-button w3-round w3-blue w3-border-blue w3-hover-light-blue w3-hover-border-light-blue" title="Home">Home</button>
        </div>
    </div>
</div>

<!-- Display a modal with the error information -->
<!-- Only appears when there is a problem -->
<div id="error-modal" class="w3-modal">
    <div class="w3-modal-content w3-card-4">
        <header class="w3-container w3-light-gray">
            <h2 class="w3-text-blue-gray w3-xxlarge"><i class="icofont icofont-error w3-text-red"></i>Error</h2>
        </header>

        <div class="w3-container w3-center">
            <label class="w3-text-gray" style="font-size: 20px;">{{ errorMessage }} </label>
        </div>

        <footer class="w3-container">
            <div class="w3-button w3-blue w3-border w3-border-blue w3-hover-light-blue w3-hover-border-light-blue w3-round-large" (click)="closeModal()" style="margin: 10px;">OK</div>
        </footer>
    </div>
</div>
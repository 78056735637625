import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'SelectCharacterScreen'
}

export class SelectCharacterScreen extends Phaser.Scene {

    private isActive: boolean = false;
    private background: Phaser.GameObjects.Sprite;

    // Represent a character object
    private character: Phaser.GameObjects.Sprite[] = [];

    // Represent the color description for each character
    private readonly colorText: string[] = ['Orange', 'Green', 'Blue', 'Purple', 'Red', 'Yellow'];
    private readonly characterText: Phaser.GameObjects.Text[] = [];

    private completedLevel: number = 0;

    constructor() {

        super(sceneConfig);

        this.isActive = false;
    }

    public init(data: any): void {

        // Store the player completed level information
        this.completedLevel = data.completedLevel;
    }

    public preload(): void {

        // Project asset location (images, data files, etc)
        this.load.setBaseURL('../assets/images/projects/games2D/color-fight');

        // Load the images and spritesheet
        this.load.image('select_character_background', 'screens/selection/background.png');
        this.load.spritesheet('characters', 'screens/selection/characters.png', {frameWidth: 90, frameHeight: 127});
    }

    public create(): void {

        this.background = this.add.sprite(0, 0, 'select_character_background').setOrigin(0, 0);

        let beginX: number = 16;
        let beginY: number = 180;

        // There are 6 color characters
        for(let i = 0; i < 6; i++) {

            // Determine which color to make active
            if(i < this.completedLevel) {

                // Store the character
                this.character[i] = this.add.sprite(beginX, beginY, 'characters', i).setOrigin(0, 0);
                this.character[i].setVisible(true);
                this.character[i].setInteractive();
            }

            // Draw a grayed out color that the player cannot select
            else {

                // Store the character
                this.character[i] = this.add.sprite(beginX, beginY, 'characters', 6).setOrigin(0, 0);
                this.character[i].setVisible(true);
            }

            // Store the text description for each selectable character
            this.characterText[i] = this.add.text(0, 0, this.colorText[i], {fontSize: '16px', color: 'rgb(255, 255, 255)'});
            this.characterText[i].setOrigin(0, 0);
            this.characterText[i].setVisible(true);

            // Calculate the position of the text base on the selectable character for the text
            let x = this.character[i].x + this.character[i].width / 2 - this.characterText[i].width / 2;
            let y = this.character[i].y + this.character[i].height + 10;

            // Assign the text position for the character
            this.characterText[i].x = x;
            this.characterText[i].y = y;

            // Determine if this color is active
            if(i < this.completedLevel) {

                // Inform the player the mouse is over a character
                this.character[i].on('pointerover', () => {

                    this.character[i].setFrame(i + 7);
                });

                // Switch the character back to its regular display
                // because the mouse has move from over the character
                this.character[i].on('pointerout', () => {

                    this.character[i].setFrame(i);
                });

                // The player is selecting the character
                this.character[i].on('pointerdown', () => {

                    this.delete();

                    // Pass the selected character to the game
                    this.scene.start('GameScreen', {playerCharacter: i});
                });
            }

            beginX += 115;
        }

        this.isActive = true;
    }

    public update(): void {

    }

    public delete(): void {

        if(this.isActive) {

            // Remove the background object
            this.background.destroy();

            // Remove the characters object
            for(let i = 0; i < 6; i++) {

                this.character[i].destroy();
                this.characterText[i].destroy();

                this.isActive = false;
            }

            console.log('SelectCharacterScreen objects have been removed');
        }
    }
}
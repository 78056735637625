import { PeerEvent, PeerEventDispatcher } from './peerEventDispatcher';
import { Peer } from './peer';

export class HostPeer extends PeerEventDispatcher {

    private peer: Peer[] = [];
    private websocket: WebSocket;
    private readonly SOCKET_URL: string = 'wss://amesmi.openode.dev'; /*'wss://amesmi.openode.io';*/ //'ws://localhost:3000';

    private readonly pcConfig = {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]};

    constructor(myID: string, private lobbyName: string) {

        super();

        this.websocket = new WebSocket(this.SOCKET_URL, ['protocolOne', 'protocolTwo']);

        // The socket is open and can start receiving messages from the server
        this.websocket.onopen = () => {

            //console.log('Successfully connected to the server');

            let object = {
                application: 'how-many',
                action: 'add-socket-to-lobby',
                playerID: myID,
                lobbyName: lobbyName
            }

            this.sendMessageToServer(JSON.stringify(object));

            this.incomingMessages();
        }
    }

    /**
     * The Player is sending a message to the server
     * 
     * @param message 
     */
    public sendMessageToServer(message: string): void {

        //console.log('Sending message to server');
        //console.log(message);

        switch(this.websocket.readyState) {

            case WebSocket.CLOSED:
                //console.log('Socket is closed');
                break;

            case WebSocket.CLOSING:
                //console.log('Socket is closing');
                break;

            case WebSocket.CONNECTING:
                //console.log('Socket is connecting');
                break;

            case WebSocket.OPEN:

                // Sending message to server
                this.websocket.send(message)

                break;
        }
    }

    /**
     * Send a message to a guest peer
     * 
     * @param message 
     * @param position 
     */
    public sendMessageToPeer(message: string, position: number): void {

        this.peer[position].dataChannel.send(message);
    }

    /**
     * Send a message to all the peers in the lobby
     * 
     * @param message 
     */
    public sendMessageToAllPeers(message: string): void {

        for(let i = 0; i < this.peer.length; i++) {

            this.peer[i].dataChannel.send(message);
        }
    }

    /**
     * Close the socket to the server
     */
    public closeSocket(): void {

        if(this.websocket != null && this.websocket != undefined) {

            this.websocket.close();
        }
    }

    /**
     * Close all connection to the remote peer
     * 
     * @param playerID 
     * @param playerName 
     */
    public close(): void {

        // Close all connections properly
        for(let i = 0; i < this.peer.length; i++) {

            if(this.peer[i].dataChannel != null && this.peer[i].dataChannel != undefined) {

                this.peer[i].dataChannel.close();
            }

            if(this.peer[i].peerConnection != null && this.peer[i].peerConnection != undefined) {

                this.peer[i].peerConnection.close();
                this.peer[i].peerConnection = null;
            }
        }

        // Clear the array
        for(let i = this.peer.length - 1; i >= 0; i--) {

            this.peer.splice(i, 1);
        }
    }

    /**
     * 
     * 
     * @param playerID 
     * @param playerName 
     */
    public async createPeer(playerID: string, playerName: string) {

        let rtcPeer = new Peer();

        rtcPeer.otherPeerID = playerID;
        rtcPeer.otherPeerName = playerName;

        // Create a new peer connection
        rtcPeer.peerConnection = new RTCPeerConnection(this.pcConfig);

        // Create a new data channel
        rtcPeer.dataChannel = await rtcPeer.peerConnection.createDataChannel(playerName, { ordered: true });

        // Handle all incoming message from connecting peer
        rtcPeer.peerConnection.ondatachannel = e => {

            e.channel.onopen = (event) => this.dataChannelOpen(event);
            e.channel.onclose = (event) => this.dataChannelClose(rtcPeer, event);
            e.channel.onmessage = (event) => this.dataChannelMessage(event);
            e.channel.onerror = (event) => this.dataChannelError(rtcPeer, event);
        }

        // Not supported in FireFox
        rtcPeer.peerConnection.onconnectionstatechange = (event) => {

            switch(rtcPeer.peerConnection.connectionState) {

                case 'closed': /*console.log('peer closed');*/ break;
                case 'connected': /*console.log('peer connected');*/ break;         // Once peers are connected, then start the application
                case 'connecting': /*console.log('peer connecting');*/ break;
                case 'disconnected': /*console.log('peer disconnected');*/ break;
                case 'failed': /*console.log('peer failed');*/ break;
                case 'new': /*console.log('peer new');*/ break;
            }
        }

        rtcPeer.peerConnection.onsignalingstatechange = (event) => {

            switch(rtcPeer.peerConnection.signalingState) {

                case 'closed': /*console.log('signaling state closed');*/ break;
                case 'have-local-offer': /*console.log('signaling state have-local-offer');*/ break;
                case 'have-local-pranswer': /*console.log('signaling state have-local-pranswer');*/ break;
                case 'have-remote-offer': /*console.log('signaling state have-remote-offer');*/ break;
                case 'have-remote-pranswer': /*console.log('signaling state have-remote-pranswer');*/ break;
                case 'stable': /*console.log('signaling state stable');*/ break;
            }
        }

        await this.createIceCandidate(rtcPeer);

        // Store the peer information
        this.peer.push(rtcPeer);
    }

    /**
     * Process all incoming messages from the server
     */
    private incomingMessages(): void {

        this.websocket.onmessage = (event) => {

            // Get the information from the server
            let message = JSON.parse(event.data);

            if(message.action === 'host-successfully-added') {

                //console.log('Successfully added HOST Socket information');
            }

            // The guest is request access to the lobby
            else if(message.action === 'guest-request-access-to-lobby') {

                let playerName: string = message.guestName;
                let playerID: string = message.guestID;

                // Display guest information on the HOST screen
                let object = {
                    playerName: playerName,
                    playerID: playerID
                }

                this.dispatchEvent(new PeerEvent('display-guest-information', JSON.stringify(object)));
            }

            else if(message.type === 'offer') {

                this.setRemoteDescription(new RTCSessionDescription(message.offer), message.guestID);

                this.createAnswer(message.guestID);
            }

            else if(message.type === 'new-ice-candidate') {

                let candidate = new RTCIceCandidate({

                    sdpMLineIndex: message.label,
                    sdpMid: message.id,
                    candidate: message.candidate
                });

                this.addIceCandidate(candidate, message.guestID);
            }
        }
    }

    /*****************************************************************
     * 
     * Beginning of DataChannel methods
     * 
     *****************************************************************/

    private dataChannelOpen(event: Event): void {

        //console.log('Peer data channel is open');           // The can start exchanging messages

        this.dispatchEvent(new PeerEvent('datachannel-open', ''));
    }

    private dataChannelClose(peer: Peer, event: Event): void {

        //console.log('Peer data channel is close');

        if(peer.dataChannel != null && peer.dataChannel != undefined) {

            peer.dataChannel.close();
        }

        if(peer.peerConnection != null && peer.peerConnection != undefined) {

            peer.peerConnection.close();
            peer.peerConnection = null;
        }

        // Close all connections to the remote server
        //this.close();

        console.log('Close connection to ' + peer.otherPeerName);
    }

    private dataChannelMessage(event: MessageEvent): void {

        //console.log('Incoming message');

        // event.data returns the message from the remote peer
        this.dispatchEvent(new PeerEvent('datachannel-message', event.data));
    }

    private dataChannelError(peer: Peer, event: Event): void {

        //console.log('Peer data channel error');

        // Close all connections to the remote server
        //this.close();

        if(peer.dataChannel != null && peer.dataChannel != undefined) {

            peer.dataChannel.close();
        }

        if(peer.peerConnection != null && peer.peerConnection != undefined) {

            peer.peerConnection.close();
            peer.peerConnection = null;
        }

        console.log('Close connection to ' + peer.otherPeerName);
    }

    /****************** End of DataChannel methods *****************/

    private createIceCandidate(rtcPeer: Peer): void {

        rtcPeer.peerConnection.oniceconnectionstatechange = (event) => {

            switch(rtcPeer.peerConnection.iceConnectionState) {

                case 'new': /*console.log('new ice candidate');*/ break;
                case 'checking': /*console.log('checking ice candidate');*/ break;
                case 'connected': /*console.log('connected ice candidate');*/ break;
                case 'completed': /*console.log('completed ice candidate');*/ break;
                case 'failed': /*console.log('failed ice candidate');*/ break;
                case 'disconnected': /*console.log('disconnected ice candidate');*/ break;
                case 'closed': /*console.log('closed ice candidate');*/ break;
            }
        }

        rtcPeer.peerConnection.onicegatheringstatechange = (event) => {

            switch(rtcPeer.peerConnection.iceGatheringState) {

                case 'complete': /*console.log('complete ice gathering');*/ break;
                case 'gathering': /*console.log('gathering ice');*/ break;
                case 'new': /*console.log('new ice gathering');*/ break;
            }
        }

        //this.peerConnection.addEventListener('icecandidate', (event) => {
        rtcPeer.peerConnection.onicecandidate = (event) => {

            /*
            //console.log('icecandidate event: ' + event);
            console.log('Ice Candidate Info');
            console.log(event.candidate);
            console.log(" ");
            */

            if(event.candidate) {

                let object = {

                    application: 'how-many',
                    type: 'new-ice-candidate',
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate,
                    otherPeerID: rtcPeer.otherPeerID,
                    lobbyName: this.lobbyName
                }

                /*
                console.log('Sending ice candidate:');
                console.log(event.candidate);
                console.log(' ');
                */

                this.sendMessageToServer(JSON.stringify(object));
            }

            else {

                //console.log('All ICE candidates have been sent');
            }
        };
    }

    private async addIceCandidate(candidate: RTCIceCandidate, guestID: string) {

        try {

            /*
            console.log('Adding Ice Candidate:');
            console.log(candidate);
            console.log(' ');
            */

            for(let i = 0; i < this.peer.length; i++) {

                if(guestID === this.peer[i].otherPeerID) {

                    await this.peer[i].peerConnection.addIceCandidate(candidate);

                    // Exit the loop
                    break;
                }
            }
            
        }

        catch (e) {

            //console.error('Error adding received ice candidate');
        }
    }

    private async setRemoteDescription(message: any, guestID: string) {

        for(let i = 0; i < this.peer.length; i++) {

            if(guestID === this.peer[i].otherPeerID) {

                let sessionDescription = new RTCSessionDescription(message);
                await this.peer[i].peerConnection.setRemoteDescription(sessionDescription);

                // Exit loop
                break;
            }
        } 
    }

    private async createAnswer(guestID: string) {


        for(let i = 0; i < this.peer.length; i++) {

            // The peer is in the list
            if(guestID === this.peer[i].otherPeerID) {

                let answer = await this.peer[i].peerConnection.createAnswer();
                await this.peer[i].peerConnection.setLocalDescription(answer);

                // Send the answer to the guest peer
                let object = {

                    application: 'how-many',
                    type: 'answer',
                    answer: answer,
                    otherPeerID: this.peer[i].otherPeerID,
                    lobbyName: this.lobbyName
                }

                /*
                console.log('Sending answer:');
                console.log(answer);
                console.log(' ');
                */

                this.sendMessageToServer(JSON.stringify(object));

                // Exit loop
                break;
            }
        }
    }
}
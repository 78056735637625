import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WordBlitzHomeComponent } from './word-blitz-home/word-blitz-home.component';
import { WordBlitzChallengeComponent } from './word-blitz-challenge/word-blitz-challenge.component';
import { WordBlitzLevelsComponent } from './word-blitz-levels/word-blitz-levels.component';
import { WordBlitzPlayComponent } from './word-blitz-play/word-blitz-play.component';
import { WordBlitzRankingsComponent } from './word-blitz-rankings/word-blitz-rankings.component';
import { WordBlitzRulesComponent } from './word-blitz-rules/word-blitz-rules.component';
import { WordBlitzPlayStatsComponent } from './word-blitz-play-stats/word-blitz-play-stats.component';
import { WordBlitzLevelsStatsComponent } from './word-blitz-levels-stats/word-blitz-levels-stats.component';
import { WordBlitzCreateLobbyComponent } from './word-blitz-challenge/word-blitz-create-lobby/word-blitz-create-lobby.component';
import { WordBlitzJoinLobbyComponent } from './word-blitz-challenge/word-blitz-join-lobby/word-blitz-join-lobby.component';
import { WordBlitzLobbyComponent } from './word-blitz-challenge/word-blitz-lobby/word-blitz-lobby.component';
import { WordBlitzChallengeStatsComponent } from './word-blitz-challenge-stats/word-blitz-challenge-stats.component';
import { WordBlitzTournamentComponent } from './word-blitz-tournament/word-blitz-tournament.component';
import { WordBlitzTournamentEntranceComponent } from './word-blitz-tournament/word-blitz-tournament-entrance/word-blitz-tournament-entrance.component';
import { WordBlitzTournamentRegistrationComponent } from './word-blitz-tournament/word-blitz-tournament-registration/word-blitz-tournament-registration.component';

const routes: Routes = [
  {path: 'word-blitz-home', component: WordBlitzHomeComponent},
  {path: 'word-blitz-play', component: WordBlitzPlayComponent},
  {path: 'word-blitz-levels', component: WordBlitzLevelsComponent},
  {path: 'word-blitz-challenge', component: WordBlitzChallengeComponent},
  {path: 'word-blitz-rankings', component: WordBlitzRankingsComponent},
  {path: 'word-blitz-rules', component: WordBlitzRulesComponent},
  {path: 'word-blitz-play-stats', component: WordBlitzPlayStatsComponent},
  {path: 'word-blitz-levels-stats', component: WordBlitzLevelsStatsComponent},
  {path: 'word-blitz-create-lobby', component: WordBlitzCreateLobbyComponent},
  {path: 'word-blitz-join-lobby', component: WordBlitzJoinLobbyComponent},
  {path: 'word-blitz-lobby/:lobbyID', component: WordBlitzLobbyComponent},
  {path: 'word-blitz-challenge-stats', component: WordBlitzChallengeStatsComponent},
  {path: 'word-blitz-tournament', component: WordBlitzTournamentComponent},
  {path: 'word-blitz-tournament-registration', component: WordBlitzTournamentRegistrationComponent},
  {path: 'word-blitz-tournament-entrance', component: WordBlitzTournamentEntranceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

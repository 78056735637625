import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HowManyHomeComponent } from './how-many-home/how-many-home.component';
import { HowManyCreateLobbyComponent } from './how-many-create-lobby/how-many-create-lobby.component';
import { HowManyJoinLobbyComponent } from './how-many-join-lobby/how-many-join-lobby.component';
import { HowManyLobbyComponent } from './how-many-lobby/how-many-lobby.component';
import { HowManyRulesComponent } from './how-many-rules/how-many-rules.component';
import { HowManyGuestLobbyComponent } from './how-many-guest-lobby/how-many-guest-lobby.component';

const providers = [];

@NgModule({})
export class HowManySharedModule {

  static forRoot(): ModuleWithProviders<AppModule> {

    return {

      ngModule: AppModule,
      providers: providers
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HowManyHomeComponent,
    HowManyCreateLobbyComponent, 
    HowManyJoinLobbyComponent, 
    HowManyLobbyComponent,
    HowManyRulesComponent,
    HowManyGuestLobbyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AmesmiJeopardyHomeComponent } from './amesmi-jeopardy-home/amesmi-jeopardy-home.component';
import { AmesmiJeopardyCreateLobbyComponent } from './amesmi-jeopardy-create-lobby/amesmi-jeopardy-create-lobby.component';
import { AmesmiJeopardyJoinLobbyComponent } from './amesmi-jeopardy-join-lobby/amesmi-jeopardy-join-lobby.component';
import { AmesmiJeopardyHostLobbyComponent } from './amesmi-jeopardy-host-lobby/amesmi-jeopardy-host-lobby.component';
import { AmesmiJeopardyGuestLobbyComponent } from './amesmi-jeopardy-guest-lobby/amesmi-jeopardy-guest-lobby.component';
import { AmesmiJeopardyRulesComponent } from './amesmi-jeopardy-rules/amesmi-jeopardy-rules.component';

const routes: Routes = [
  
  {path: 'amesmi-jeopardy-home', component: AmesmiJeopardyHomeComponent},
  {path: 'amesmi-jeopardy-create-lobby', component: AmesmiJeopardyCreateLobbyComponent},
  {path: 'amesmi-jeopardy-join-lobby', component: AmesmiJeopardyJoinLobbyComponent},
  {path: 'amesmi-jeopardy-host-lobby/:lobbyID', component: AmesmiJeopardyHostLobbyComponent},
  {path: 'amesmi-jeopardy-guest-lobby/:lobbyID', component: AmesmiJeopardyGuestLobbyComponent},
  {path: 'amesmi-jeopardy-rules', component: AmesmiJeopardyRulesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import * as Phaser from 'phaser';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'LetterSelectionScene'
};

export class LetterSelectionScene extends Phaser.Scene {

    private board: Phaser.GameObjects.Sprite;
    private letters: Phaser.GameObjects.Sprite[] = [];

    constructor() {

        super(sceneConfig);
    }

    /**
     * Initialize game variables
     */
    public init(): void {
        
    }

    /**
     * This will load all the images, text files, etc for the game
     */
    public preload(): void {

        // Specify the image folder
        this.load.setBaseURL('../assets/images/projects/games2D/abc-fight');

        // load images and spritesheet
        this.load.image('board', 'letter_selection/board.png');
        this.load.spritesheet('letters', 'letter_selection/letters.png', {frameWidth: 70, frameHeight: 70});
    }

    /**
     * 
     */
    public create(): void {

        let self = this;

        this.board = this.add.sprite(0, 0, 'board').setOrigin(0, 0);

        let maxRow = 4;
        let maxCol = 7;
        let beginX = 41;
        let beginY = 107;

        let col = 0;
        let row = 0;

        // Create selectable letters for the letter selection screen
        // There are 26 letters in the alphabet
        for(let i = 0; i < 26; i++) {

            // Make the button active
            this.letters[i] = this.add.sprite(beginX, beginY, 'letters', i).setOrigin(0, 0);
            this.letters[i].setVisible(true);
            this.letters[i].setInteractive();
            
            // Inform the player the mouse is over a letter
            this.letters[i].on('pointerover', () => {

                this.letters[i].setFrame(27);
            });

            // Switch the letter back to its regular display
            this.letters[i].on('pointerout', () => {

                this.letters[i].setFrame(i);
            });

            // Select this letter
            this.letters[i].on('pointerdown', () => {

                this.delete();

                // Pass the selected value to the game
                self.scene.start('GameScene', {mySelection: i});
            });
            
            // Loop to the next column
            col++;

            // Calculate the next space
            if(col < maxCol) {

                beginX += 110;
            }

            else {
                // Go back to the beginning of the row
                beginX = 41;

                // Move down 1 row
                beginY += 100;

                // Last row of letters
                if(beginY === 407) {

                    // Move beginX over to the next space
                    beginX = 151;
                }

                // Reset col to 0
                col = 0;
            }
        }
    }

    public update(): void {

    }

    /**
     * Remove all references from the game
     */
    public delete(): void {

        this.board.destroy();

        for(let i = 0; i < this.letters.length; i++) {

            this.letters[i].destroy();
        }
    }
}
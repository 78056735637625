import * as Phaser from 'phaser';

import { SubmitButton } from '../buttons/submit-button';
import { ClearButton } from '../buttons/clear-button';
import { WordsButton } from '../buttons/words-button';
import { RemoveLetterButtons } from '../buttons/remove-letter-buttons';
import { GameGrid } from '../object/game-grid';
import { GameLetters } from '../object/game-letters';
import { GamePlayerControls } from '../player/game-player-controls';
import { Player } from '../player/player';
import { Router } from '@angular/router';
import { PlayService } from '../../play.service';

// Relevant
const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {

    active: false,
    visible: false,
    key: 'GameScreen'
}

export class GameScreen extends Phaser.Scene {

    private gameboard: Phaser.GameObjects.Sprite;
    private gameboardGrid: Phaser.GameObjects.Sprite;
    private gameoverDisplay: Phaser.GameObjects.Sprite;

    private statButton: Phaser.GameObjects.Sprite;
    private submitButton: SubmitButton;
    private clearButton: ClearButton;
    private wordsButton: WordsButton;
    private removeLetterButtons: RemoveLetterButtons;
    private gameGrid: GameGrid;
    private gameLetters: GameLetters;
    private gamePlayerControls: GamePlayerControls;
    private player: Player;

    private isGameOver: boolean = false;

    private fallingLetters = [];

    constructor(private route: Router, private service: PlayService) {

        super(sceneConfig);

        this.submitButton = new SubmitButton();
        this.clearButton = new ClearButton();
        this.wordsButton = new WordsButton();
        this.removeLetterButtons = new RemoveLetterButtons();
        this.gameGrid = new GameGrid();
        this.gameLetters = new GameLetters(this.fallingLetters, this.gameGrid);
        this.gamePlayerControls = new GamePlayerControls(this.fallingLetters, this.gameGrid);
        this.player = new Player(this.fallingLetters, this.gameGrid);

        this.eventListeners();
    }

    public init(data: any): void {

    }

    public preload(): void {

        this.load.setBaseURL('assets/images/projects/games2D/word-blitz');

        // Load the Word List
        this.load.text('dictionary', 'files/words_alpha.txt');

        // Load all images
        this.load.image('gameboard', 'single-player-game/gameboard.png');
        this.load.image('grid', 'single-player-game/grid.png');
        this.load.image('word_list', 'single-player-game/display_word_list.png');
        this.load.image('game_over', 'single-player-game/game_over.png');

        // Load all spritesheets
        this.load.spritesheet('letters', 'single-player-game/letters.png', {frameWidth: 80, frameHeight: 80});
        this.load.spritesheet('buttons', 'single-player-game/gameboardButtons.png', {frameWidth: 162, frameHeight: 59});
        this.load.spritesheet('remove_buttons', 'single-player-game/remove_letters_buttons_update.png', {frameWidth: 60, frameHeight: 60});
    }

    public create(): void {

        // Load the game board
        this.gameboard = this.add.sprite(0, 0, 'gameboard');
        this.gameboard.setOrigin(0, 0);

        this.gameboardGrid = this.add.sprite(0, 80, 'grid');
        this.gameboardGrid.setOrigin(0, 0);
        this.gameboardGrid.setDepth(1);

        // Create a new grid for the game
        this.gameGrid.create();

        // Add the initial letters to the gameboard
        this.gameLetters.addInitialLetters(this.add);

        // Create the controls that allows the player to select a letter
        // and drag across the screen
        this.gamePlayerControls.create(this.input);

        // Create the gameboard buttons
        this.submitButton.create(this.add);
        this.clearButton.create(this.add);
        this.wordsButton.create(this.add);
        this.removeLetterButtons.create(this.add);

        // Create the player for the game
        this.player.create(this.add, this.cache);
    }

    public update(): void {

        // Allow the player to play the game
        if(this.isGameOver == false) {

            // Make sure everything is consistent with time
            // across the game
            let currentTime = new Date().getTime();

            this.gameLetters.update(currentTime);
        }
    }

    public delete(): void {

        this.gameboard.destroy();
        this.gameboardGrid.destroy();
        this.gameoverDisplay.destroy();

        this.submitButton.destroy();
        this.clearButton.destroy();
        this.wordsButton.destroy();
        this.removeLetterButtons.destroy();
        this.statButton.destroy();

        this.gameGrid.delete();

        this.player.delete();
    }

    /**
     * Since the game is over. All buttons are inactive
     * that are related to the game
     */
     private deactivatePlayerControls(): void {

        // Do NOT allow the player to click a letter
        // and drag a letter
        //this.input.enabled = false;
        this.input.off('pointerdown');
        this.input.off('pointerup');
        this.input.off('pointerout');
        this.input.off('pointermove');

        // Make all buttons inactive
        this.clearButton.disable();
        this.removeLetterButtons.disable();
        this.submitButton.disable();
        this.wordsButton.disable();

        // If the word list is on then turn it off
        this.player.turnOffWordList();
    }

    /**
     * Display gameover information
     */
     private displayGameoverInformation(): void {

        this.gameoverDisplay = this.add.sprite(0, 80, 'game_over');
        this.gameoverDisplay.setOrigin(0, 0);
        this.gameoverDisplay.setDepth(1);

        this.statButton = this.add.sprite(279, 1206, 'buttons', 7);
        this.statButton.setOrigin(0, 0);
        this.statButton.setDepth(1);
        this.statButton.setInteractive();

        // Get the player information (stats) and store
        // the information for later use
        this.service.setPlayerScore(this.player.getScore());
        this.service.setPlayerWords(this.player.getWords());
        this.service.setTotalWords(this.player.getTotalWords());

        this.statButton.on('pointerdown', () => {

            // Change the button color
            this.statButton.setFrame(15);
        });

        this.statButton.on('pointerup', () => {

            // Change the button back to its original color
            this.statButton.setFrame(7);
            
            // Send the player to the single player stats page
            this.route.navigateByUrl('/word-blitz-play-stats');
        });
    }

    /**
     * All the event listner for the current game
     */
    private eventListeners(): void {
        
        // Submit the spelled word to the dictionary
        this.submitButton.addEventListener('submit-button-pressed', (event) => {

            this.player.submitWord();
        });

        // Remove the spelled word letters from the gameboard
        // and place them back on the player gameboard
        this.clearButton.addEventListener('clear-button-pressed', (event) => {

            this.player.clearWord();
        });

        // Display all the words the player has spelled correctly
        this.wordsButton.addEventListener('words-button-pressed', (event) => {

            this.player.wordsDisplay();
        });

        // Remove the percentage amount of letters from the game
        this.removeLetterButtons.addEventListener('remove-letters', (event) => {

            // Percentage amount of letters to be remove from the gameboard
            let percentage: number = Number.parseFloat(event.message);

            // Get the total number of letters on the board
            let count: number = this.fallingLetters.length;

            // Calculate the total amount of letters to remove
            let total: number = Math.round(count * percentage);

            // Remove the letters from the gameboard
            for(let i = total - 1; i >= 0; i--) {

                // Inform the grid that the space is available
                let row = this.fallingLetters[i].row;
                let col = this.fallingLetters[i].col;

                // Remove the letter from the list
                this.fallingLetters[i].sprite.setVisible(false);
                this.fallingLetters[i].sprite.destroy();
                this.fallingLetters.splice(i, 1);

                this.gameGrid.grid[row][col].occupied = 0;
            }
        });

        // A column is full so the game is over
        this.gameGrid.addEventListener('is-game-over', (event) => {

            // The game is over
            if(event.message === 'true') {

                this.isGameOver = true;

                this.deactivatePlayerControls();
                this.displayGameoverInformation();
            }
        });

        // The player has selected a letter in order to spell a word
        this.gamePlayerControls.addEventListener('add-letter-to-word', (event) => {

            let data = JSON.parse(event.message);

            // Add the letter to the player word
            this.player.addLetter(data.letter);
            this.player.addPotentialPoints(data.pointValue);
            this.player.storeLetterPosition(data.letterPosition);
        });
    }
}
import { AnimationFrame } from './animationFrame';

export class Animation {

    private frames: AnimationFrame[] = [];
    private currentFrameIndex: number;
    private animationTime: number;
    private totalDuration: number;

    /**
     * Create a new animation
     */
    constructor() {

        this.totalDuration = 0;

        this.start();
    }

    /**
     * Add an image to the animation with the specfied
     * duration (time to display the image)
     * 
     * @param image 
     * @param duration 
     */
    public addFrame(image: Phaser.GameObjects.Image, duration: number): void {

        this.totalDuration += duration;
        this.frames.push(new AnimationFrame(image, this.totalDuration));
    }

    /**
     * Start this animation over from the beginning
     */
    public start(): void {

        this.animationTime = 0;
        this.currentFrameIndex = 0;
    }

    /**
     * Update this animation's current image (frame), if
     * necessary
     */
    public update(elapsedTime: number): void {

        if(this.frames.length > 1) {

            this.animationTime += elapsedTime;

            if(this.animationTime >= this.totalDuration) {

                // Turn the current image off
                this.getFrame(this.currentFrameIndex).image.setVisible(false);

                this.animationTime = this.animationTime % this.totalDuration;
                this.currentFrameIndex = 0;
            }

            while(this.animationTime > this.getFrame(this.currentFrameIndex).endTime) {

                // Turn the current image off
                this.getFrame(this.currentFrameIndex).image.setVisible(false);

                // Loop to the next index in the list
                this.currentFrameIndex++;
            }
        }
    }

    /**
     * Gets this animation's current image. Returns null if this
     * animation has no images
     */
    public getImage(): Phaser.GameObjects.Image {

        if(this.frames.length == 0) {

            return null;
        }

        else {

            return this.getFrame(this.currentFrameIndex).image.setVisible(true);
        }
    }

    private getFrame(i: number): AnimationFrame {

        return this.frames[i];
    }
}